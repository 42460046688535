body 
  color white
.mainSection
  position relative
  z-index 2
  &.bigPartner
    .mainSection-swiper__pagination
      opacity 0
    .mainSection-swiper__buttons
      opacity 0
  .mainSection-swiper
    overflow visible 
  .mainSection-swiper 
    overflow visible
  
  &-content 
    position relative
    z-index 2
  &-video 
    position absolute 
    top 0
    left 0
    width 100%
    height 100%
    &:before 
      content ""
      position absolute 
      top -2px
      left 0
      width 100%
      height 100%
      background: linear-gradient(180deg, #091C4E 0%, rgba(9, 40, 120, 0.0001) 100%);
      pointer-events: none
      z-index 2
    video
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
  &-swiper 
    height calc(100vh + 10px)
    &__pagination 
      position absolute 
      top 50%
      left 50%
      transform: translate(-50%, -50%)
      max-width: 1200px
      width 100%
      height 100%
      pointer-events: none
      z-index 10
      transition: all .25s ease-in
      .swiper-pagination-current
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color $white
        position absolute
        top: 23px;
        left: 37px;
      .swiper-pagination-total
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color $yellow
        position absolute
        bottom: 26px;
        right: 34px;
      .pagination 
        position absolute 
        width 100% 
        height 100%
        top 0% 
        left 0% 
        font-size 0
        &-wrapper 
          position absolute 
          top: 199px;
          left: 3px;
          width: 70px
          height 70px
          width: 93px;
          height: 93px;
          border-radius: 50%
          .circle-bg
            position absolute 
            top 0
            left 0
            display flex
            justify-content center
            align-items center
          .circle-main 
            position absolute
            top 0
            left 0
            transform: rotate(-90deg)
            display flex
            justify-content center
            align-items center
            circle 
              transition all .3s ease-in
  &-swiper__buttons 
    position: absolute 
    max-width: 1300px
    width 100%
    height 100%
    top 0
    left 50%
    transform: translateX(-50%)
    pointer-events: none 
    z-index 10
    transition: all .25s ease-in
  &-button 
    &-prev,
    &-next 
      display flex
      justify-content center
      align-items center
      position absolute 
      width 40px
      height 40px 
      border-radius: 50%
      top 50% 
      transform: translateY(-50%)
      cursor pointer
      z-index 10
      pointer-events: all 
      &.swiper-button-disabled
        pointer-events: none
        img
          opacity .5
    &-prev 
      left 10px
    &-next 
      right 10px
  &-img 
    position relative
    .play
      position absolute 
      top: 50% 
      left 50% 
      transform: translate(-50%, -50%)
      cursor pointer
      display flex
      justify-content center
      align-items center
      path 
        fill $white 
        transition all .3s ease-in
      &:hover 
        path 
          fill $yellow 
  &-slide 
    width 100%
    overflow hidden
    position relative
    &:after 
      content ""
      position absolute 
      bottom -2px
      left 0
      width 100%
      height 30%
      background: linear-gradient(0deg, #050b29 0%, rgba(9, 40, 120, 0.0001) 100%);
      pointer-events: none
    &__wrapper 
      max-width: 1290px;
      width: 100%;
      padding: 0 60px;
      margin  0 auto
      min-height: 100%
      padding-top 150px
      position relative
      // overflow hidden
      z-index 2
    &__content 
      position relative
      padding-left 64px
      min-height: 100%
    &__1
      .mainSection
        &-slide__content  
          display flex
          justify-content space-between
        &-title 
          max-width: 520px
          width 100%
          padding-top 55px
        &-img 
          max-width: 360px
          width 100%  
          margin 32px auto 0
        &-items 
          display flex
          justify-content space-between
          align-items flex-start 
          margin-top: -2px;
          padding-left 64px
          max-width: 744px
        &-item 
          width calc(50% - 30px)
          max-width: 280px
          &__title 
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #fff;
            margin-bottom: 15px
            color: $white
          &__text 
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color $yellow
    &__2 
      &:after 
        z-index 3
      .mainSection
        &-slide 
          &__content 
            padding-top 55px 
        &-title 
          max-width: 670px
          width: 47vw
        &-button 
          max-width: 249px
          width 100%
          margin-top 85px
          a 
            width 100%
        &-hand 
          position: absolute
          bottom: -160px;
          right: -280px;
          right: -34vh;
          bottom: -20vh;
          transform: rotate(70deg);
          animation-duration: 2s;
          animation-fill-mode forwards
          animation-delay: .5s
          max-width: 540px
          width: 39vw;
          .hand 
            max-width: 540px 
            width 100%
          .card 
            animation-duration: 3s;
            animation-fill-mode forwards
            animation-iteration-count: infinite
            animation-direction: alternate
            animation-timing-function: linear
            animation-delay: 2s
            transform: translate(0, 20px);
            position relative
            .icon
              position absolute 
              bottom 9% 
              right 7%
              max-width: 46px
              width 13%
              img 
                position absolute 
                width 100%
                bottom 0
                right 0
                opacity 0
                &.active 
                  opacity 1
            &-block
              max-width: 424px
              max-width: 70%;
              width: 100%;
              position: absolute;
              bottom: 106%;
              right: 25%;
              transform: rotate(30deg);
              animation-duration: 2s;
              animation-delay: .5s
              animation-fill-mode forwards
              animation-timing-function: linear
             
      &.showEl
        .mainSection-hand
          animation-name: handAnimate;
          .card-block 
            animation-name: cardAnimate;
          .card
            animation-name: cardAnimate2;

    &__3
      .mainSection
        &-slide 
          &__content 
            padding-top: 56px;
            display flex
            justify-content flex-start
            margin-bottom 20px
            // transition: all .3s ease-in
          &__wrapper 
            display flex
            flex-direction: column
        &-title 
          max-width: 565px
          letter-spacing: -1.4px;
          margin-right 50px
        &-button 
          max-width: 246px
          width 100%
        &-img 
          max-width: 440px
          width 100%
          margin 60px auto 0
          height max-content


h1.mainSection-title 
  font-size: 64px;
  line-height: 74px;
  letter-spacing: -2px;
  margin-right: 32px    
h2.mainSection-title 
  font-weight: 400;
  font-size: 64px;
  line-height: 74px;
  letter-spacing: -2px



.contactUs 
  border: 1px solid rgba(#F9D4A1, .6) ;
  border-radius: 63px;
  width 126px
  height 126px
  position fixed
  right: 5%;
  bottom: 8.7%;
  right 4vw
  bottom 4vw
  z-index 10
  pointer-events: none
  opacity 0
  transition: all .3s ease-in
  animation-duration: 1s;
  animation-fill-mode forwards
  animation-timing-function: linear
  animation-delay: 2s
  animation-name: showBlock
  &-block 
    display flex
    flex-direction: column
    align-items center
    justify-content center
    width 100% 
    height 100% 
    border-radius: 50%
    z-index 2
    background rgba(#04103A, .3)
    transition all .25s ease-in
    padding-bottom 10px
    pointer-events: all
    &:hover 
      background #04103A
      .contactUs-title 
        color $yellow
  &-icon 
    margin-bottom 10px
    display flex
    justify-content center
    align-items center
  &-title 
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    text-align center
    color $white
    transition all .25s ease-in
  &-line 
    position absolute
    border: 0.5px solid #F9D4A0;
    border-radius: 60px;
    width 100% 
    height 100%
    top: 0
    left 0
    animation-duration: 1.6s;
    animation-name: centerBlockLine3;
    animation-iteration-count infinite
    z-index -1
    &2
      animation-delay: .4s;
    &3
      animation-delay: .8s;
  &.getSandboxAccess
    bottom calc(4vw + 192px)


.videoModal
  display none 
  width calc(100% - 30px)
  height calc(100% - 30px)
  padding 0
  background transparent
  video 
    position: absolute
    top 50% 
    left 50%
    transform: translate(-50%, -50%)
    max-width 100%
    max-height 100%
    pointer-events: none



@keyframes centerBlockLine3 {
  0% {
    transform: scale(1);
    opacity .6
  }
  100% {
    transform: scale(1.7)
    opacity 0
  }
}

@keyframes handAnimate {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
    opacity: 0
  }
  50% {
    opacity 1
    }
  100% {
    bottom: -7vh;
    right: -5vh;
    transform: rotate(0)
  }
}

@keyframes cardAnimate {
  0% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(-15deg)
  }
}
@keyframes cardAnimate2 {
  0% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, -10px)
  }
}



@media (max-height : 840px) and (min-width:1101px)  
  .header 
    height: 16.7vh;
    &-logo 
      max-width: 11.2vw;
  .mainSection
    &-slide 
      &__wrapper
        padding-top 18vh
      &__content 
        padding-left: 7.7vh;
      &__1
        .mainSection
          &-title 
            max-width: 36.15vw;
            margin-right: 2vw;
            padding-top: 6.6vh;
            font-size: 7.65vh;
            line-height: 8.7vh;
          &-img 
            max-width: 43vh;
            max-height: 43vh;
            margin-top: 2.2vw;
          &-items 
            padding-left: 7.7vh; 
          &-item 
            &__title 
              font-size: 2.39vh;
              line-height: 2.9vh;
              margin-bottom: 1.6vh;
            &__text 
              font-size: 1.67vh;
              line-height: 2.7vh;
          &-hand 
            max-width: 44vh
      &__2 
        .mainSection
          &-button 
            margin-top: 10.2vh;
          &-slide 
            &__content 
              padding-top: 6.4vh;
      &__3 
        .mainSection
          &-slide__content 
            padding-top: 6.6vh;
            margin-bottom 2vh
          &-title 
            // width: 40vw;
            margin-right: 3.6vw;
            max-width: 39.3vw
          &-img 
            max-width: 35vw;
            width: 80vh;
            margin-top 8vh
            display flex
            justify-content center
            align-items center
       

  h2.mainSection-title
    font-size: 7.65vh;
    line-height: 8.8vh;

 
  

@media (max-width : 1200px) 
  .mainSection  
    &-slide
      &__wrapper
        padding-top 18vh
      &__content 
        padding-left: 7.7vh;
      &__1
        .mainSection
          &-title 
            max-width: 36.15vw;
            margin-right: 2vw;
            padding-top: 6.6vh;
            
          &-img 
            max-width: 43vh;
            max-height: 43vh;
            margin-top: 2.2vw;
          &-items 
            padding-left: 7.7vh; 
            margin-top 2vw
         
        .mainSection
          &-button 
            margin-top: 10.2vh;
          &-slide 
            &__content 
              padding-top: 6.4vh;

      &__3 
        .mainSection
          &-slide__content 
            padding-top: 6.6vh;
            margin-bottom 2vh
            align-items center
          &-title 
            // width: 40vw;
            margin-right: 3.6vw;
            max-width: 39.3vw
          &-img 
            max-width: 30.6vw;
            max-height: 28vh;
            margin-top 0
            display flex
            justify-content center
            align-items center
    
  h2.mainSection-title
    font-size: 4.65vw;
    line-height: 5.8vw;
 


@media (max-width : 1200px) and (max-height: 700px) 

  h2.mainSection-title
    font-size: 7.65vh;
    line-height: 8.8vh;
  

  .mainSection-slide__1 
    .mainSection
      &-slide__content 
        padding-top 0 
      &-title
        font-size: 7.65vh;
        line-height: 8.7vh;
      &-items  
        max-width: none
      &-item 
        max-width: none
        &__title 
          font-size: 2.39vh;
          line-height: 2.9vh;
          margin-bottom: 1.6vh;
        &__text 
          font-size: 1.67vh;
          line-height: 2.7vh;
      

  

@media (min-height : 840px) and (max-width: 1200px) and (min-width: 999px)
  .mainSection-swiper__pagination 
    .pagination-wrapper
      max-width: 8vw
      max-height: 8vw
    .swiper-pagination-current
      font-size: 1.2vw;
      line-height: 2vw;
      top: 2vw;
      left: 2.8vw;
    .swiper-pagination-total
      font-size: 1.2vw;
      line-height: 2vw;
      bottom: 2.2vw;
      right: 2.8vw;
    


@media (max-height : 750px)
  .mainSection-slide__2 .mainSection-hand
    max-width: 60vh

@media (max-width : 998px)

  .header 
    height 110px

  .mainSection-swiper
    &__buttons
      display none

  .mainSection-slide
    &__wrapper
      padding 0 20px
      padding-top 110px

  
  .mainSection-slide__1 
    .mainSection
      &-slide
        &__content
          padding-left 0
          align-items center
      &-items 
        display flex
        justify-content space-between
        width 100%
        padding-left 0
        margin-top 40px
      &-img 
        width: 36vw;
        height: 36vw;
        margin-top 1vw
     

  .mainSection-slide__2 
    .mainSection
      &-slide__content 
        padding-top 60px
        padding-left 0
        max-width: 50vw
      &-button 
        margin-top 5vh

  .mainSection-slide__3
    .mainSection
      &-title 
        max-width: 60vw
        width 100%
      &-slide__content 
        padding-top 60px
        padding-left 0
        max-width: 100%

  .mainSection-swiper__pagination 
    .pagination-wrapper
      top 115px
      width: 80px;
      height: 80px;
    .swiper-pagination-current
      font-size: 14px;
      line-height: 16px;
      top: 23px;
      left: 26px
    .swiper-pagination-total
      font-size: 14px;
      line-height: 16px;
      bottom 23px 
      right 26px


@media (max-width : 768px)
  .mainSection
    &-swiper 
      &__pagination 
        display none
        .pagination-wrapper
          top 96px
    &-slide
      &.swiper-slide
        transition opacity .5s ease-in !important
        .mainSection-slide__wrapper
          transition: transform .7s ease-in
      &__wrapper 
        padding-top 100px
        padding-bottom 50px
      &__1 
        .mainSection
          &-slide__content
            flex-direction: column
            padding-top 0
          &-title 
            padding-top: 20px
            max-width: none;
            padding-left 0
            font-size 30px 
            line-height 40px
          &-img 
            width: 100%;
            max-width: 30vh;
            max-height: 30vh
            height 100%
            margin 30px auto 0
          &-items 
            flex-direction: column 
          &-item 
            width 100%
            max-width: 100%
            margin-bottom 20px
      &__2 
        .mainSection-slide__content 
          padding-top 20px
          max-width: none
        .mainSection-hand 
          max-width: 35vh;
          width: 80vw;
        .mainSection-title 
          max-width: none
          width 100%
        &.showEl 
          .mainSection-hand
            animation-name: handAnimateMobile
      &__3 
        .mainSection-img 
          max-width: 35vh
      
   
          

  h2.mainSection-title
    font-size: 26px;
    line-height: 40px
 
  
  .contactUs
    width 90px
    height 90px
    &-title 
      font-size 10px
      line-height 14px
    &-icon 
      max-width: 18px
      margin-top 5px
    &.getSandboxAccess
      bottom calc(4vw + 130px)

@media(max-width: 768px) and ( min-height: 500px) 
  .mainSection
    &-slide__3 
      .mainSection
        &-slide__content 
          padding-top 20px
          flex-direction: column
        &-title 
          max-width: none
          margin-bottom 30px
          width 100% 
    

@media(max-width: 480px) 
  .mainSection-slide__1 .mainSection-title
    padding-top 0
  .mainSection-slide__2 .mainSection-slide__content
    padding-top 0
  .mainSection-slide__3 .mainSection-slide__content
    padding-top 0

@media(max-height: 480px) 
  .mainSection-swiper__pagination 
    display none
  .mainSection-slide__2 .mainSection-slide__content
    padding-top 0
  .mainSection-slide__3 .mainSection-slide__content
    padding-top 0
  .mainSection-slide__1 
    .mainSection-title
      padding-top 0
    .mainSection-img 
      max-width: 37vh;
      max-height: 37vh;
      margin 0 auto
    .mainSection-item__title 
      font-size 14px
      line-height 20px
    .mainSection-item__text 
      font-size: 10px;
      line-height: 16px;
    .mainSection-items
      flex-direction row
 

  
@keyframes handAnimateMobile {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
  }
  100% {
    bottom: 0vh;
    right: 6vw;
    transform: rotate(0)
  }
}
  
@keyframes showBlock {
  0% {
    opacity 0
  }
  100% {
    opacity 1
  }
}

.footer
  padding 47px 0 50px
  position relative
  // &:after 
  //   content ""
  //   position absolute 
  //   width 100% 
  //   height 178% 
  //   bottom 00 
  //   left 0
  //   background-image: url('../img/main/footer-bg.png')
  //   background-size: cover
  //   pointer-events: none
  .wrapper 
    max-width: 1270px
    z-index 2
  &-top 
    display flex
    justify-content space-between
    align-items center
    .footer-item 
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #FFFFFF;
      margin-right 60px 
      transition all .3s ease-in
      &:last-child 
        margin-right 0
      &:hover 
        color: #F9D4A1;
  &-bottom 
    display flex
    justify-content space-between
    align-items center
    margin-top 36px
    .footer-item 
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #F9D4A1;
      margin-right 42px 
      &:last-child 
        margin-right 0
  &-items 
    display flex
    justify-content flex-start
    align-items center
  &-socials 
    display flex
    justify-content flex-end
    align-items center
  &-social 
    margin-left 40px
    display flex
    justify-content center
    align-items center
    path,
    rect,
    circle 
      stroke #F9D4A1
      transition all .25s ease-in
    &:hover 
      path,
      rect,
      circle  
        stroke #ffffff
  &-logos 
    display flex
    justify-content flex-end
    align-items center
  &-logo 
    margin-left 50px
  &-date 
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: rgba(249, 212, 161, 0.7);
    margin-top 40px
  &-adress 
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #F9D4A1;
    margin-right auto
    .description 
      color: rgba(249, 212, 161, 0.7);
      margin-bottom 5px
      font-size: 12px;
      line-height: 20px
    &__bottom 
      display flex
      justify-content flex-start
  .adress 
    font-size: 12px;
    line-height: 20px; 
    color #F9D4A1
  .cities 
    display flex
    justify-content flex-start
  .city 
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-left 40px
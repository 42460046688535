.faq
  padding 63px 0
  position relative
  // &:after 
  //   content ""
  //   position absolute 
  //   width: 100vw;
  //   height: 88vw;
  //   top -36% 
  //   left 0
  //   background-image: url('../img/main/faq-bg.png')
  //   background-size: cover
  //   pointer-events: none
  //   max-height: 1264px
  .title 
    font-weight: 400;
    font-size: 55px;
    line-height: 65px;
    text-align: center;
    color: #FFFFFF;
  .wrapper 
    max-width: 880px
    z-index 2
  &-items 
    margin-top 84px
  &-item 
    margin-bottom 40px
    &__bottom 
      margin-top 10px
      padding-right 60px
      max-height 0
      transition: all .1s ease-in
      opacity 0
      p 
        padding-top 10px
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        transition: all .2s ease-in
        color: #F9D4A0;
    &.active 
      .faq-item__button 
        opacity 1 
        transform: rotate(-180deg)
      .faq-item__bottom 
        opacity 1
    &__top 
      display flex
      justify-content space-between
      align-items flex-start
      cursor pointer
      position relative
      &:hover 
        .faq-item__title 
          color #F9D4A0
    &__title 
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #FFFFFF;
      transition all .3s ease-in
      padding-right 40px
    &__button 
      opacity .5
      transform: rotate(0)
      transition all .3s ease-in
      margin-left 20px
      min-width: 18px
      margin-top 5px
      position absolute
      right 0
      top -2px

@media(max-width: 768px)
  .faq 
    padding 40px 0
    .title 
      font-size: 35px;
      line-height: 45px;
    &-items 
      margin-top 45px
    &-item 
      margin-bottom 30px
.cryptoPage 
    overflow hidden
    position relative
    background #050b29
    &:after 
        content ""
        position absolute
        bottom 0
        top 0
        width 100%
        background-image url(../img/products/back.png)
        background-color: #050B29
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-size: cover
        background-position-y: top
        transition all .3s ease-in
    
    main 
        position relative
        z-index 2

    .title 
        font-size: 55px;
        text-align center
        line-height: 65px;

    .crypto
        padding 200px 0 68px
        .wrapper 
            max-width: 1150px

        .subtitle 
            font-weight: 400;
            font-size: 26px;
            line-height: 34px;
            color: #F9D4A1;
            margin-top 35px
            max-width: 570px
            width 100%

        .content 
            display flex
            justify-content space-between
        
        .info 
            max-width: 655px
            width 69%

        
        .title 
            color: $white
            text-align left

            
        .image 
            width 49%
            position relative
            &-block 
                position absolute
                width: 147.1%;
                left: -14%;
                top: -5%;
                img 
                    width 100%
    
    .securelyBuy
        padding 91px 0 60px
        .title 
            max-width 696px
            width 100% 
            margin: 0 auto
        .subtitle 
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            margin-top 41px
        .text 
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #F9D4A1;
            text-align center
            max-width: 800px
            width 100% 
            margin 40px auto 0
        
        .list 
            column-count: 3;
            column-gap: 5%;
            max-width: 954px
            width 100% 
            margin 60px auto 0

        .item 
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.5px;
            color: #FFFFFF
            margin-bottom 25px 
            padding-left 30px 
            position relative
            &:after 
                content ""
                position absolute 
                left 0
                top 2px
                width 18px
                height 18px
                background-image url(../img/integrate/check.svg)
    
    .platfroms
        padding: 88px 0;
        .blocks
            position relative
            display flex
            justify-content space-between
            max-width: 1160px
            margin 0 auto
            padding-top 18.3%
        .block 
            width 100%
            &-1 
                width 50%
                img 
                    box-shadow: 57px 77px 88px rgba(32, 47, 86, 0.250281);
                    border-radius: 25px;
                    width 112%
                    max-width: 650px
            &-2 
                margin-top 99px
                width 50%
                display: flex;
                justify-content: flex-end;
                img 
                    box-shadow: 57px 77px 88px rgba(32, 47, 86, 0.250281);
                    border-radius: 25px;
                    width 112%
                    max-width: 650px
            &-3
                position absolute
                max-width: 325px
                width 29%
                z-index 3
                left: 20.5%;
                top: 35%;
                img 
                    box-shadow: 33px 45px 51px rgba(5, 5, 13, 0.370758);
                    border-radius: 14.3805px;

            &-4
                position absolute
                width 50%
                z-index 2
                right: 21.5%;
                top 0
                img 
                    box-shadow: 57px 77px 88px rgba(32, 47, 86, 0.250281);
                    border-radius: 25px;
                    width 112%
                    max-width: 650px
    
    .why 
        padding 72px 0
        .wrapper 
            max-width: 1070px
        .title 
            text-align center
        .subtitle 
            margin-top 40px
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            color: #F9D4A1;
        .blocks 
            display flex
            justify-content center
            flex-wrap wrap
            margin 81px -20px 0
        .block 
            width calc(33.333% - 40px)
            margin 0 20px 60px
            &-icon 
                display flex
                justify-content center
                align-items center 
                margin-bottom 25px
            &-title 
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
                margin-bottom 20px
            &-text 
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #F9D4A0;


    .useCases 
        padding 25px 0 113px
        min-height: 0
        .el 
            position absolute 
            &1
                right: 5.5%;
                top: 200px;
            &2
                right: 9%;
                top: 434px;
            &3
                right: 19.6%;
                bottom: 15px;
            &4
                left: 40%;
                bottom: 63px;
            &5
                right: 34.3%;
                top: 400px;
        .content 
            display flex
            justify-content space-between
            margin-top 80px
            padding-right 140px
        .subtitle 
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            color: #F9D4A1;
            max-width: 595px
            width 100%
            margin 21px auto 0
        .items 
            max-width: 300px
            width 100% 
            margin-right 60px
            &-subtitle 
                display none
        .item 
            border: 1px solid rgba(#F9D4A0, .4);
            border-radius: 20px;
            display flex
            justify-content flex-start
            align-items center
            padding 24px 25px
            margin-bottom 10px 
            cursor pointer
            transition: all .3s ease-in
            &:hover 
                border-color #F9D4A0
            &.active 
                background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
                border: 1px solid #F9D4A0;
                pointer-events: none
            &:last-child 
                margin-bottom 0
            &-icon 
                margin-right 20px
                height 40px 
                display flex
                justify-content center
                align-items center
            &-title 
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #FFFFFF;
        .blocks 
            margin-top -6px
        .block 
            display none
            &.active 
                display block
            &-text 
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: -0.5px;
                color: #ffffff;
                max-width: 440px
                width 100%
            &-list 
                margin-top 40px 
            &-item 
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                letter-spacing: -0.5px;
                color: #FFFFFF
                margin-bottom 25px 
                padding-left 30px 
                position relative
                &:after 
                    content ""
                    position absolute 
                    left 0
                    top 0
                    width 18px
                    height 18px
                    background-image url(../img/integrate/check.svg)
    
    @media (max-width 998px)
        .crypto
            padding-bottom 0
            .content 
                flex-direction: column
            .info 
                max-width: none
                width 100%
            .title 
                width 100%
            .image
                width 100% 
                max-width: 700px
                margin 40px auto 0
                &-block 
                    position relative
                    width 100%
                    margin-bottom -10%
                    left 0
                    top: 0
    
        .why 
           .block 
                width calc(50% - 40px)
                &-title 
                    br 
                        display none
        
        .useCases  
            .el 
                transform: scale(.7)
                &1 
                    top 60px
                    right 5%
                &4,
                &1,
                &5 
                    display none
            .content 
                padding-right 0
            .items 
                margin-right 30px

    @media (max-width 768px)
        .title 
            font-size: 30px;
            line-height: 40px;
        .crypto 
            padding-top 100px
            .info 
                max-width: 550px
            .image 
                max-width: 500px
        
        .securelyBuy 
            .list
                column-count: 2;
        
        .why 
            padding-bottom 0
            .blocks 
                flex-direction: column
                margin 60px 0 0
            .block 
                width 100%
                margin 0 auto 60px
                max-width: 320px
        
        .useCases 
            min-height 0
            padding 40px 0 80px
            .content 
                flex-direction: column
                margin-top 60px
            .items 
                display flex
                justify-content space-between
                align-items center
                max-width: 450px
                margin 0 auto 40px
                &-subtitle 
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                    text-align: center;
                    color: #FFFFFF;
                    display block
                    font-weight 500
                    margin-bottom 30px
            .item 
                width calc(20% - 2.5px)
                max-width: 65px
                height 65px
                border-radius: 15px
                display flex
                justify-content center
                align-items center
                margin-bottom 0
                padding 10px
                &-title 
                    display none
                &-icon 
                    margin-right 0

            .block 
                &-buttons 
                    flex-direction: column
                    align-items flex-start
                &-button 
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom 30px 
                    &:last-child 
                        margin-bottom 0
            .el 
                display none
        
        .platfroms
            padding 30px 0px
            .blocks 
                flex-direction column
                max-width: 400px
                width 100%
                padding-top 0
            .block
                img 
                    max-width 100%
                    border-radius: 13px
                &-1 
                    width 100% 
                    order 3
                &-2
                    width 100% 
                    margin-top 0
                    margin-bottom 10px
                    order 1
                &-3
                    width 100% 
                    max-width: none
                    left 0
                    top 26%
                    img 
                        width 48%
                        margin 0 auto
                &-4 
                    width 100% 
                    position: static
                    margin-bottom 10px
                    order 2
                    

    @media (max-width: 480px)

        .securelyBuy 
            .list
                column-count: 1;
                column-gap: 0%;
        
        .useCases
            .items 
                max-width: 345px
            .item 
                padding 10px 5px
.header 
  position fixed
  top 0 
  left 0
  width 100%
  z-index 11
  transition: all .3s ease-in
  display flex
  align-items center
  height: 140px
  transition all .3s ease-in
  &.fixed 
    background: rgba(8, 24, 66, 0.85);
    backdrop-filter: blur(25px);
    height 90px
  .wrapper 
    max-width: 1310px
  &-content 
    display flex
    justify-content space-between
    align-items center
    transition: all .3s ease-in
  &-logo 
    max-width: 161px
    width 100% 
    display flex 
    justify-content center
    align-items center
  &-navigation 
    padding-right 83px 
    flex-grow 1
  &-list 
    display flex
    justify-content center
    align-items center
    padding 0
    &__item 
      margin 0 20px
      a 
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #F9D4A0;
        transition: all .25s ease-in
        &:hover 
          color $white
  &-language 
    position relative
    &__main
      display flex
      justify-content flex-end
      align-items center
      cursor pointer
      .icon 
        margin-right 10px
        display flex
        justify-content center
        align-items center
      .language 
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #F9D4A0;
        min-width: 30px
        text-align left
      .arrow 
        margin-left 3px
        transition: all .25s ease-in
        display flex
        justify-content center
        align-items center
        path 
          fill rgba($white, .3)
          transition: all .25s ease-in
      &:hover 
        .arrow 
          path 
            fill $white
    &__dropdown 
      pointer-events none;
      opacity 0 
      height 0
      padding 10px 21px 10px 28px
      position absolute
      top 20px
      right 0
    &__item 
      margin-bottom 10px 
      cursor pointer
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #F9D4A0;
      min-width: 30px
      text-align left
      transition: all .25s ease-in
      &:last-child 
        margin-bottom 0
      &:hover 
        color $white
    &.open 
      .header-language__main
        .arrow 
          transform: rotate(-180deg)
      .header-language__dropdown 
        pointer-events all 
        opacity 1
        height 100%
  &-menu
    width 100% 
    &__block 
      display flex
      justify-content space-between
      align-items center 
    
  &-switch 
    display flex
    justify-content flex-end
    align-items center
    margin-left 30px
    &__button 
      width 30px
      height 30px
      border-radius: 50%
      display flex
      justify-content center
      align-items center
      border: 1px solid rgba(#F0CC9B, .5)
      margin-left 10px
      transition: all .3s ease-in
      cursor pointer
      &:hover 
        border-color: #F0CC9B
      &[data-mode='black']
        background: linear-gradient(2.49deg, #56424D 23.49%, #4E6CC3 149.61%);
        border none
        pointer-events: none
      path 
        transition: all .3s ease-in
      


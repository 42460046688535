.how 
  padding 91px 0
  position relative
  // &:after 
  //   content ""
  //   position absolute 
  //   width 100% 
  //   height 109% 
  //   top 17% 
  //   left 0
  //   background-image: url('../img/main/how/background2.png')
  //   background-size: cover
  //   pointer-events: none
  //   max-height: 1422px
  .title 
    text-align center
    font-weight: 400;
    font-size: 55px;
    line-height: 65px;
  .wrapper 
    max-width: 1070px
    z-index 2
  &-subtitle 
    text-align center
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color $white 
    margin-top 60px
  &-text 
    text-align center
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #F9D4A0;
    margin-top 15px
  &-items 
    margin-top 80px 
  &-item 
    display flex
    justify-content flex-start
    align-items flex-start
    width max-content
    &__num 
      background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
      width 120px
      height 120px 
      border-radius: 50% 
      display flex
      justify-content center
      align-items center
      font-weight: 400;
      font-size: 55px;
      line-height: 65px;
      color: #D39F56;
      position relative
      .line 
        position absolute 
        width max-content
        z-index -1
        .tablet 
          display none
        .mobile 
          display none
    &__content 
      max-width: 238px
      width 100%
      margin-left 60px
    &__title 
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #FFFFFF;
      margin-top 15px
    &__text 
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #F9D4A0;
      margin-top 15px
    &__1 
      margin 0 auto 
      padding-left: 298px;
      .how-item
        &__num 
          margin-top 45px
          .line 
            top: 57%;
            right: 44%;
    &__2 
      margin-top 112px
      .how-item
        &__num
          margin-top -10px 
          
        &__content 
          margin-left 49px
    &__3 
      margin-left auto
      margin-top -70px
      .how-item
        &__num
          margin-top -24px
          .line
            bottom: 46%;
            right: 50%;
  &-button 
    margin-top: 42px;
    display flex
    justify-content center
    align-items center

@media(max-width: 998px)
  
  .how 
    &-subtitle 
      margin-top: 43px;
    &-items 
      margin-top 18px 
      max-width: 576px
      width 100%
      margin 0 auto
    &-item 
      flex-direction: column
      &__num 
        margin-bottom 40px
        .line 
          .desktop 
            display none
          .tablet 
            display block
      &__content 
        margin-left 0
      &__1 
        margin-right 0
        padding-left 0
        .how-item__num 
          margin-top 60px
          margin-left -10px
          .line 
            top: 43%;
            right: 99%;
      &__2 
        margin-top -100px
        .how-item__content 
          margin-left 10px
      &__3 
        margin-top -138px 
        .how-item__num 
          margin-left -10px
          .line 
            bottom: 84%;
            right: 84%;
    &-button 
      max-width: 560px 
      margin 58px auto 0

@media(max-width: 768px)

  .how 
    &-subtitle 
      margin-top 22px
    .title 
      font-size: 35px;
      line-height: 45px;
    &-item 
      margin 0 auto
      align-items center
      &__num 
        .line 
          .tablet 
            display none
          .mobile 
            display block
      &__content 
        text-align center
        max-width: 255px
      &__icon 
        display flex
        justify-content center
      &__1 
        .how-item__num 
          margin-top 40px
          margin-left 0
          margin-bottom 20px
          .line 
            top: 49%;
      &__2 
        margin-top 62px
        .how-item__num 
          margin-top 0
          margin-bottom 20px
        .how-item__content 
          margin-left 0
      &__3 
        margin-top 85px
        .how-item__num 
          margin-left 0
          margin-bottom 19px
          .line 
            bottom: 47%;
            right: -75%;

    &-button 
      display flex
      justify-content center
      margin-top 60px

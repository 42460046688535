//main styles
.main-wrapper
	padding 0 0 0 0
	min-width $min-width
	width 100%
	position relative
	// overflow hidden
	min-height 100%

.wrapper
	min-width $min-width
	width 100%
	max-width $max-width
	padding 0 15px 0 15px
	margin 0 auto
	position relative

/* titles */
p
	font($base-font-size, $base-line-height, $text-color, $custom-font-family, 400)
	padding 0

h1
	font-family $base-font-family
	font-weight: 400;
	font-size: 75px;
	line-height: 85px;
	color: $white;
	margin 0
	transition: all .3s ease-in

h2
	font-family $base-font-family
	font-weight: 400;
	font-size: 45px;
	line-height: 45px;
	color: $white;
	padding 0
	margin 0
	transition: all .3s ease-in

h3
	font($font-size-h3, $line-height-h3, $title-color, $custom-font-family, 700)
	padding 0
	margin 0
	transition: all .3s ease-in
h4 
	margin 0
	transition: all .3s ease-in

p 
	margin 0
	transition: all .3s ease-in

li 
	transition: all .3s ease-in


.page 
	overflow hidden
	position relative
	background #050b29
	transition: all .3s ease-in
	&:after 
		content ""
		position absolute
		bottom 0
		left 0
		width 100%
		min-height: 5000px
		background-image url(../img/main/background.png)
		background-color: #050B29
		background-repeat: no-repeat;
		// background-attachment: fixed;
		background-size: cover
		background-position-y: bottom
		transition all .3s ease-in

.clipPath
	position absolute
	width 0
	height 0

span.gold 
	color: #F9D4A0;

.opacity 
	opacity 0

.svg 
	position absolute
	width 0
	height 0
	

.default-button 
	background: linear-gradient(180deg, #D3A96E 0%, #7C5F46 100%);
	border none
	width 100%
	border-radius: 15px;
	font-weight: 400;
	font-size: 16px;
	line-height: 14px;
	color $white
	display flex
	justify-content center
	align-items center
	// width 100%
	width max-content
	padding 23px 30px
	&:hover 
		box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
	

// body.stop 
// 	overflow hidden
// 	height 100vh
// 	// position: fixed;
// 	// top: 0;
// 	// left: 0;
// 	// width: 100%;

.wow 
	visibility hidden


.animateElement 
	opacity 0
	transition: all .3s ease-in


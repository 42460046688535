.intagrate
  padding 106px 0 67px
  position relative
  // &:after 
  //   content ""
  //   position absolute 
  //   width 100% 
  //   height 170% 
  //   top 61% 
  //   left 0
  //   transform translateY(-50%)
  //   background-image: url('../img/main/intagrate/background.png')
  //   background-size: cover
  //   pointer-events: none
  //   max-height: 988px
  .wrapper 
    max-width: 1270px
    z-index 2
  .title 
    text-align center
    margin-bottom 63px
  &-content 
    display flex
    justify-content space-between
  &-items 
    max-width: 300px
    width 100% 
    margin-right 30px
    &__subtitle 
      display none
  &-item 
    border: 1px solid rgba(#F9D4A0, .4);
    border-radius: 20px;
    display flex
    justify-content flex-start
    align-items center
    padding 24px 25px
    margin-bottom 10px 
    cursor pointer
    transition: all .3s ease-in
    &:hover 
      border-color #F9D4A0
    &.active 
      background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
      border: 1px solid #F9D4A0;
      pointer-events: none
    &:last-child 
      margin-bottom 0
    &__icon 
      margin-right 20px
      height 40px 
      display flex
      justify-content center
      align-items center
    &__title 
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #FFFFFF;
  &-blocks 
    max-width: 880px
    width 100%
  &-block 
    display none
    background: #FFFFFF;
    border-radius: 20px;
    padding 45px 60px 65px
    &.active 
      display block
    &__title 
      font-weight: 400;
      font-size: 55px;
      line-height: 65px;
      color #050925
      .gold 
        color #D39F56;
    &__text 
      margin-top 30px 
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: rgba(#050925, .7);
    &__buttons
      display flex
      justify-content flex-start
      align-items center
      margin-top 68px
    &__button 
      display flex
      justify-content flex-start  
      align-items center
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: #050925;
      .icon 
        margin-right 10px
      &:first-child 
        margin-right 42px

@media (max-width: 1100px)
  .intagrate
    padding-top 90px

@media (max-width: 998px)

  .intagrate
    &-block
      padding 25px 40px 45px
      &__title 
        font-size: 35px;
        line-height: 45px;
      &__text 
        margin-top 20px
      &__buttons 
        margin-top 30px
      &__button 
        font-size 13px
        line-height 16px
    &-items 
      max-width: 235px;
    &-item 
      padding 20px 30px
      &__icon 
        max-width: 30px 
        max-height: 30px
      &__title 
        font-size 15px
  
  @media (max-width: 768px)
    
    .intagrate
      padding 90px 0 40px
      .title 
        font-size: 35px;
        line-height: 45px;
      &-content 
        flex-direction: column
      &-items 
        display flex
        flex-wrap wrap 
        justify-content space-between
        max-width: none
        margin-bottom 30px
      &-item 
        width calc(50% - 5px)
        &:last-child 
          margin-bottom 10px

  @media (max-width: 480px)
    .intagrate 
      &-items 
        margin-bottom 30px
        &__subtitle 
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          color: #FFFFFF;
          display block
          font-weight 500
          margin-bottom 30px
      &-item 
        width calc(25% - 2.5px)
        display flex
        justify-content center
        align-items center
        padding 24px 20px
        &__title 
          display none
        &__icon 
          margin-right 0
      &-block 
        &__buttons 
          flex-direction: column
          align-items flex-start
        &__button 
          font-size: 16px;
          line-height: 18px;
          margin-bottom 30px 
          &:last-child 
            margin-bottom 0




  
.partners 
  padding 120px 0 37px
  position relative
  z-index 2
  min-height: 600px
  &:after 
    content ""
    position absolute 
    top 0
    left 0
    width 100%
    height 10vw
    background: linear-gradient(180deg, #050b29 0%, rgba(9, 40, 120, 0.0001) 100%);
    z-index -1
  &-title 
    text-align center
    font-size: 55px;
    line-height: 65px;
  &-content 
    max-width: 970px
    width 100% 
    margin 85px auto 0
    display flex
    justify-content center
    height 140px
  &-block 
    width 100%
    margin 0 auto
    background: #FFFFFF;
    border-radius: 45px;
    padding 45px 40px
    display flex
    justify-content center
    align-items center
    transition: all .5s ease-in
    height 100%
    .swiper-wrapper 
      align-items center
    .swiper-slide 
      display flex
      justify-content center
      align-items center
      img 
        width 80%
    &.big-1 
      height 120%
      width 110%
    &.big-2 
      height 140%
      width 120%
    &.big-3 
      height 160%
      width 130%
  &-button 
    &-prev 
      transform: rotate(-180deg)    
      min-width: 24px 
      cursor pointer  
      &.swiper-button-disabled
        opacity .2   
        pointer-events: none
    &-next 
      min-width: 24px
      cursor pointer  
      &.swiper-button-disabled
        opacity .2
        pointer-events: none
  &-bottom 
    margin-top 30px



@media (max-width : 998px) 
  .partners
    min-height: 400px
    &-content 
      max-height: 100px
    &-block
      padding 30px 20px
      border-radius: 30px
      .swiper-slide
        padding 0 10px
      &.big-1 
        height 110%
        width 100%
      &.big-2 
        height 120%
        width 100%
      &.big-3 
        height 130%
        width 100%
    &-title
      font-size: 45px;
      line-height: 45px;

@media (max-width : 768px) 
  .partners 
    &-title 
      font-size: 35px;
      line-height: 45px;
body {
	position: relative;
	height: 100%;
  background-color: $base-bg;
	font-size: $base-font-size;
	line-height: $base-line-height;
	color: $text-color;
  font-weight: normal;
	font-family: $base-font-family;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
}

img {
  display: block;
  max-width: 100%; 
}

input[type="text"], input[type="email"], input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

table {
	border-collapse: collapse;
	border-width: 0px;
	padding: 0px;
	margin: 0px;
}

html {
	height: 100%;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
}

input,
textarea {
	color: $text-color;
	font-family: $base-font-family;
	border-radius: 0;
}

input[type="button"],
input[type="submit"],
button {
	cursor: pointer
	font-family $base-font-family
	font-size $base-font-size
	line-height $base-line-height
}

td {
	margin: 0px;
	padding: 0px;
}

ul {
	list-style: none;
}
form {
	padding: 0px;
	margin: 0px;
}

a {
	color: $link-color;
	transition: $default-transition;
	text-decoration: none;
	outline: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a,
span,
div,
button {
	outline: none!important
}

input[type=submit],
input[type=button],
button {
	-webkit-appearance: none;
	outline: none;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.clearfix:after,
.wrapper:after,
.row:after{
	display: none;
}


input, textarea {
	&::placeholder {
		transition: opacity .2s ease-out;
	}
	&:focus {
		&::placeholder {
			opacity: 0;
		}	
	}
}
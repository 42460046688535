
.whiteVersion
  display none

.isWhiteVersion
  .blackVersion
    display none
  .whiteVersion
    display flex
  .page 
    background #D0D7E2

  h1, 
  h2 
    color: #050925;
  
  .gold 
    color: #D39F56;
  
  .page 
    &:after 
      background-image url(../img/main/background-white.png)
      background-color #D3DAE5
  

  .header-list__item a 
    color #050925
    &:hover 
      color #D39F56
  
  .header-language
    &__main
      .icon 
        path  
          stroke #050925
      .arrow 
        path 
          stroke rgba(#050925, .3)
      .language
        color #050925
    &__item 
      color #050925
    
  .header-switch__button 
    &[data-mode='white']
      background #ffffff
      border-color #ffffff
      pointer-events: none
      .path-stroke
        stroke #292D32
      .path-fill
        fill #292D32
    &[data-mode='black']
      background: transparent
      border: 1px solid rgba(#ffffff, .5)
      pointer-events: all
      .path-stroke
        stroke #FFFFFF
      .path-fill
        fill #FFFFFF
      &:hover 
        .path-stroke
          stroke #292D32
        .path-fill
          fill #292D32
  
  .header.fixed
    background: rgba(192, 205, 218, 0.8);
    backdrop-filter: blur(10.8731px);
  

  .header-language__main
    &:hover 
      .arrow 
        path 
          stroke #050925
  
  .header-menu__button .line
    background #D39F56

  .contactUs
    border-color rgba(#050925, .4)
    &-block
      background transparent
    &-line 
      border: 0.5px solid #050925
    &-icon 
      path 
        stroke #050925
    &-title 
      color #050925
    &:hover 
      background rgba(#050925, .1)
      .contactUs-title
        color #050925

        
  
  
  .mainSection
   
    &-video
      &:before 
        display none
    &-slide__1 
      .mainSection-item__title
        color: #050925;
      .mainSection-item__text
        color: rgba(#050925, .6);
    &-slide 
      &:after 
        background linear-gradient(0deg, #D3DAE5 0%, rgba(9,40,120,0) 100%)
  
  .partners 
    &:after 
      background linear-gradient(180deg, #D3DAE5 0%, rgba(9,40,120,0) 100%)
  


  .intagrate-item
    background #ffffff
    border-color: transparent;
    &.active 
      background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
      .intagrate-item__title
        color #ffffff
    &__title 
      color: #050925;
      transition all .3s ease-in
    &__icon 
      path 
        fill #D39F56
    &:hover 
      .intagrate-item__title 
        color #d39f56

  
  .how
    &-subtitle
      color #050925
    &-text 
      color: rgba(#050925, .6);
    &-item 
      &__title 
        color: #050925;
      &__text 
        color: rgba(#050925, .6);
      &__icon 
        path 
          fill #D39F56
      &__num 
        background #ffffff
        color #050925
      .line 
        path 
          stroke #050925

  .faq 
    .title 
      color #050925
    &-item 
      &__title 
        color #050925
      &__bottom
        p
          color #050925
      &__button 
        path
          fill #050925
      &__top 
        &:hover 
          .faq-item__title 
            color #D39F56

  

  .footer-top 
    .footer-item
      color #050925
      &:hover 
        color #D39F56
  .footer
    &-adress 
      color: #050925

      .description 
        color: rgba(#050925, .5);
      .adress
        color: #050925
    &-date 
      color:  rgba(#050925, .5);
    &-social 
      path,
      rect,
      circle 
        stroke #050925
      &:hover 
        path,
        rect,
        circle 
          stroke #D39F56
  
  .fintechSection
    .right-el
      path
        stroke #050925
    .blocks.right 
      .right-lines 
        path 
          stroke #7D8899
    
    .center-item 
      &.active 
        .center-item__content
          border-color rgba(#A27D73, .3);
      &__title 
        color: #050925;
    
      &__block
        &:after
          background-image: url(../img/main/fintech/center/link_gor-whiteVersion.svg);
    .block-lines-1
      path 
        stroke: url(#linear-whiteVersion);
    .block-lines-2
      path 
        stroke: url(#linear-whiteVersion);
    .block-line__opacity-1
      g 
        path 
          stroke #050925
    

    .center-block__line
      border-color rgba(#9C776F, .3)
    .block-round__content  .center-block__line
      border-color transparent 

    .block-round__content 
      &.active
        .center-block__line
          border-color rgba(#9C776F, .3)
    

    .block-round__lines
      path 
        stroke #7D8899;

   

  #center-line1 
    stroke rgba(#050925, .5)
  #center-el-1,
  #center-el-2
    path 
      stroke #050925
  #lines-el-6, 
  #lines-el-8 
    path 
      stroke #050925
  #lines-el-9
    polygon  
      fill #050925
    path 
      fill #050925
      &:last-child 
        fill #FAA61A

  #centerRight_link
    stroke #7D8899

  #centerLeft_link
    fill #7D8899

  #round-el-1 path
    stroke #050925
  
  #centerLeft_element path
    stroke #050925
  #centerRight_element path
    stroke #050925
  

  @media(max-width: 600px)
    .header-menu__block
      background #D0D7E2
  


  .integratePage
    &:after
      background-image: url(../img/integrate/back-whiteVersion.png);
      background-color #D3DAE5
    .integrate
      .title 
        color #050925
    .digital 
      .text 
        color #050925
      .item 
        color #050925
        &:after 
          background-image: url(../img/integrate/check-whiteVersion.svg);
    .features 
      .block-title,
      .block-text
        color #050925
  
    .baas 
      .subtitle 
        color #050925
      .block
        &-title 
          color #050925
        &-item
          color #050925
          &:after
            background-image: url(../img/integrate/check-whiteVersion.svg);
      .item 
        background #ffffff
        border none
        padding 25px
        &.active 
          background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
          .item-title
            color #ffffff
        &-title 
          color: #050925;
          transition all .3s ease-in
          font-weight 500
        &-icon 
          path 
            fill #D39F56
        &:hover 
          .item-title 
            color #d39f56
  
  .productsPage
    &:after
      background-image: url(../img/products/back-whiteVersion.png);
      background-color #D3DAE5
    .whiteLabel 
      .block-text,
      .text,
      .block-title
        color: #050925;
      .block-icon 
        path,
        circle 
          stroke #D39F56
    .products
      .title 
        color #050925
      .info 
        p
          color: #050925;
      
    .rocketSection 
      .rocket 
          .rocket-blackVersion
              filter url(#filterRocket-whiteVersion)
      .block-title,
      .block-text 
        color #050925
    
    .useCases
      .subtitle 
        color #050925
      .block
        &-title 
          color #050925
        &-item
          color #050925
          &:after
            background-image: url(../img/integrate/check-whiteVersion.svg);
      .item 
        background #ffffff
        border none
        padding 25px
        &.active 
          background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
          .item-title
            color #ffffff
        &-title 
          color: #050925;
          transition all .3s ease-in
          font-weight 500
        &-icon 
          path 
            fill #D39F56
        &:hover 
          .item-title 
            color #d39f56
    
  .cryptoPage
    &:after
      background-image: url(../img/products/back-whiteVersion.png);
      background-color #D3DAE5
    .crypto 
      .title,
      .subtitle 
        color: #050925
    
    .securelyBuy
      .text,
      .subtitle
        color #050925
      .item 
        color #050925
        &:after 
          background-image: url(../img/integrate/check-whiteVersion.svg);
      
    .why 
      .subtitle 
        color: #050925;
      .block-title,
      .block-text
        color #050925
    
    .useCases 
      .subtitle 
        color #050925
      .item 
        background #ffffff
        border none
        padding 25px
        &.active 
          background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
          .item-title
            color #ffffff
        &-title 
          color: #050925;
          transition all .3s ease-in
          font-weight 500
        &-icon 
          path 
            fill #D39F56
        &:hover 
          .item-title 
            color #d39f56
      .block
        &-text 
          color #050925
            
  
  .useCasePage
    background #D3DAE5
    &:after
      background-image: url(../img/integrate/back-whiteVersion.png);
      background-color #D3DAE5
    .main-title 
      color #050925
    .main-subtitle 
      color #D39F56
    .useCase 
      .subtitle 
        color #050925
      .block-title 
        color #050925
      .variant-1 
        .block-text
          color #050925
        .block-item 
          color #050925
          &:after
            background-image: url(../img/integrate/check-whiteVersion.svg);




  @media (max-width: 768px)
    .integratePage .baas .items-subtitle 
      color #050925
  @media (max-width: 768px)
    .productsPage .useCases .items-subtitle
      color #050925
    .cryptoPage .useCases .items-subtitle
      color #050925

  @media (max-width: 998px) and (max-width: 480px)
    .intagrate-items__subtitle 
      color #050925
  
  .cardServicePage
    background #D3DAE5
    &:after
      background-image: url(../img/integrate/back-whiteVersion.png);
      background-color #D3DAE5
    .main-title 
      color #050925
    .main-subtitle 
      color #D39F56
    
    .cardService 
      .item
        &-title
          color #050925 
        &-text 
          color #050925 
    
    .useCase 
      .subtitle 
        color #050925
      .block
        &-title 
          color #050925
        &-item
          color #050925
          &:after
            background-image: url(../img/integrate/check-whiteVersion.svg);
        &-text 
          color #050925
      .item 
        background #ffffff
        border none
        padding 25px
        &.active 
          background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
          .item-title
            color #ffffff
          .blackVersion
            display flex
          .whiteVersion
            display none
        &-title 
          color: #050925;
          transition all .3s ease-in
          font-weight 500
        &-icon 
          path 
            fill #D39F56
        &:hover 
          .item-title 
            color #d39f56
       
    @media(max-width: 768px)
      .useCase 
        .item
          padding 10px
    
    @media (max-width: 768px)
      .useCase .items-subtitle 
        color #050925
/*==========  Desktop First  ==========*/

// /* Large Devices, Wide Screens */
@media (max-width : 1200px)
  .footer 
    &-logo 
      margin-left 20px
    &-date 
      margin-left 20px
// /* Medium Devices, Desktops */
@media (max-width : 1100px)
  .footer-top .footer-item
    margin-right 30px

@media (max-width : 998px)

  .footer 
    padding 30px 0 50px
    &-top 
      flex-direction: column
      margin 0 -5px 30px
      .footer-item 
        margin 0 10px
        &:last-child
          margin-right 10px
    &-bottom 
      flex-direction: column
      margin 0 -10px
      .footer-item 
        margin-right 10px
        &:last-child
          margin-right 10px
    &-items 
      margin-bottom 20px
    &-item 
      margin 0 10px
    &-logo 
      margin 0 10px
    &-date 
      margin 20px 0px 0
      order 2
      text-align center
    &-social 
      margin 0 10px
    &-adress 
      margin 0 auto 20px
      order -1
      text-align center
      &__bottom 
        flex-direction: column
    .city 
      margin 0 10px
    .adress 
      margin-bottom 10px
  .header-navigation 
    padding-right 0px

  .header-switch
    margin-left 0
// /* Small Devices, Tablets */
@media (max-width : 768px)
  .header
    &-navigation 
      padding-right 0
    &-logo 
      max-width: 120px
      margin-right 10px
    &-navigation 
      padding-right 0 
    &-list 
      &__item 
        margin 0 10px 0 0
        a
          font-size 12px
          line-height 15px
  
  .footer 
    &-items 
      flex-wrap: wrap
      margin-bottom 10px
    &-item 
      width calc(50% - 20px)
      text-align center
    &-top 
      .footer-item 
        margin-bottom 15px
    
    &-logos 
      margin-top 20px

      
          
        
  

  

// /* Small Devices, Tablets */
// @media (max-width : 568px)
// /* Small Devices, Tablets */
@media (max-width : 600px)
  .header
    height 87px
    &-menu 
      position fixed
      width 100%
      height 100vh
      top 0
      left 0
      background rgba(#050B29, .6)
      z-index 20
      opacity 0
      pointer-events: none 
      transition opacity .25s ease-in
      &__block 
        position absolute
        right 0
        top: 0
        background #050B29
        max-width: 300px
        width 100% 
        transform: translateX(100%)
        height 100%
        flex-direction column
        justify-content flex-start
        padding 100px 20px 40px
        transition transform .3s ease-in
      &__button 
        width 30px
        height 30px
        position relative 
        z-index 21
        .line 
          width 28px
          height 1px
          background #F9D4A0
          position absolute
          left calc(50% - 14px)
          transition all .3s ease-in
          &-1
            top 7px
          &-2
            top 15px
          &-3
            top 23px

    &-navigation 
      flex-grow 0 
    &-list 
      flex-direction: column
      margin-bottom 30px
      &__item 
        margin-right 0
        margin-bottom 20px
        a
          font-size 14px
          line-height 20px
    &-language
      margin-top 20px
      order 4
    &-switch__button
      margin 0 5px
  .openMenu 
    overflow hidden
    .header
      &-menu 
        opacity 1 
        pointer-events all 
        &__block 
          transform: translateX(0)
        &__button 
          .line
            &-1
              transform rotate(45deg)
              top 50% 
            &-2 
              opacity 0
            &-3 
              transform rotate(-45deg)
              top 50%
  
// /* Custom, iPhone Retina */
// @media (max-width : 380px)
/**/

body {
  position: relative;
  height: 100%;
  background-color: #fff;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: normal;
  font-family: 'GothamPro', sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
input[type="text"],
input[type="email"],
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
table {
  border-collapse: collapse;
  border-width: 0px;
  padding: 0px;
  margin: 0px;
}
html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
input,
textarea {
  color: #000;
  font-family: 'GothamPro', sans-serif;
  border-radius: 0;
}
input[type="button"],
input[type="submit"],
button {
  cursor: pointer;
  font-family: 'GothamPro', sans-serif;
  font-size: 14px;
  line-height: 18px;
}
td {
  margin: 0px;
  padding: 0px;
}
ul {
  list-style: none;
}
form {
  padding: 0px;
  margin: 0px;
}
a {
  color: #100da9;
  transition: all 0.25s ease-out;
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
a,
span,
div,
button {
  outline: none !important;
}
input[type=submit],
input[type=button],
button {
  -webkit-appearance: none;
  outline: none;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix:after,
.wrapper:after,
.row:after {
  display: none;
}
input::placeholder,
textarea::placeholder {
  transition: opacity 0.2s ease-out;
}
input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0;
}
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro/GothamPro.eot");
  src: local("GothamPro Regular"), url("../fonts/GothamPro/GothamPro.eot?#iefix") format('embedded-opentype'), url("../fonts/GothamPro/GothamPro.woff2") format('woff2'), url("../fonts/GothamPro/GothamPro.woff") format('woff'), url("../fonts/GothamPro/GothamPro.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro/GothamPro-Medium.eot");
  src: local("GothamPro Medium"), url("../fonts/GothamPro/GothamPro-Medium.eot?#iefix") format('embedded-opentype'), url("../fonts/GothamPro/GothamPro-Medium.woff2") format('woff2'), url("../fonts/GothamPro/GothamPro-Medium.woff") format('woff'), url("../fonts/GothamPro/GothamPro-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro/GothamPro-Bold.eot");
  src: local("GothamPro Bold"), url("../fonts/GothamPro/GothamPro-Bold.eot?#iefix") format('embedded-opentype'), url("../fonts/GothamPro/GothamPro-Bold.woff2") format('woff2'), url("../fonts/GothamPro/GothamPro-Bold.woff") format('woff'), url("../fonts/GothamPro/GothamPro-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
.footer {
  padding: 47px 0 50px;
  position: relative;
}
.footer .wrapper {
  max-width: 1270px;
  z-index: 2;
}
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-top .footer-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  margin-right: 60px;
  transition: all 0.3s ease-in;
}
.footer-top .footer-item:last-child {
  margin-right: 0;
}
.footer-top .footer-item:hover {
  color: #f9d4a1;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;
}
.footer-bottom .footer-item {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #f9d4a1;
  margin-right: 42px;
}
.footer-bottom .footer-item:last-child {
  margin-right: 0;
}
.footer-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer-socials {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.footer-social {
  margin-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-social path,
.footer-social rect,
.footer-social circle {
  stroke: #f9d4a1;
  transition: all 0.25s ease-in;
}
.footer-social:hover path,
.footer-social:hover rect,
.footer-social:hover circle {
  stroke: #fff;
}
.footer-logos {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.footer-logo {
  margin-left: 50px;
}
.footer-date {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  color: rgba(249,212,161,0.7);
  margin-top: 40px;
}
.footer-adress {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #f9d4a1;
  margin-right: auto;
}
.footer-adress .description {
  color: rgba(249,212,161,0.7);
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 20px;
}
.footer-adress__bottom {
  display: flex;
  justify-content: flex-start;
}
.footer .adress {
  font-size: 12px;
  line-height: 20px;
  color: #f9d4a1;
}
.footer .cities {
  display: flex;
  justify-content: flex-start;
}
.footer .city {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin-left: 40px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  transition: all 0.3s ease-in;
  display: flex;
  align-items: center;
  height: 140px;
  transition: all 0.3s ease-in;
}
.header.fixed {
  background: rgba(8,24,66,0.85);
  backdrop-filter: blur(25px);
  height: 90px;
}
.header .wrapper {
  max-width: 1310px;
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in;
}
.header-logo {
  max-width: 161px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-navigation {
  padding-right: 83px;
  flex-grow: 1;
}
.header-list {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.header-list__item {
  margin: 0 20px;
}
.header-list__item a {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #f9d4a0;
  transition: all 0.25s ease-in;
}
.header-list__item a:hover {
  color: #fff;
}
.header-language {
  position: relative;
}
.header-language__main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.header-language__main .icon {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-language__main .language {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #f9d4a0;
  min-width: 30px;
  text-align: left;
}
.header-language__main .arrow {
  margin-left: 3px;
  transition: all 0.25s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-language__main .arrow path {
  fill: rgba(255,255,255,0.3);
  transition: all 0.25s ease-in;
}
.header-language__main:hover .arrow path {
  fill: #fff;
}
.header-language__dropdown {
  pointer-events: none;
  opacity: 0;
  height: 0;
  padding: 10px 21px 10px 28px;
  position: absolute;
  top: 20px;
  right: 0;
}
.header-language__item {
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #f9d4a0;
  min-width: 30px;
  text-align: left;
  transition: all 0.25s ease-in;
}
.header-language__item:last-child {
  margin-bottom: 0;
}
.header-language__item:hover {
  color: #fff;
}
.header-language.open .header-language__main .arrow {
  transform: rotate(-180deg);
}
.header-language.open .header-language__dropdown {
  pointer-events: all;
  opacity: 1;
  height: 100%;
}
.header-menu {
  width: 100%;
}
.header-menu__block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 30px;
}
.header-switch__button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(240,204,155,0.5);
  margin-left: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.header-switch__button:hover {
  border-color: #f0cc9b;
}
.header-switch__button[data-mode='black'] {
  background: linear-gradient(2.49deg, #56424d 23.49%, #4e6cc3 149.61%);
  border: none;
  pointer-events: none;
}
.header-switch__button path {
  transition: all 0.3s ease-in;
}
.main-wrapper {
  padding: 0 0 0 0;
  min-width: 320px;
  width: 100%;
  position: relative;
  min-height: 100%;
}
.wrapper {
  min-width: 320px;
  width: 100%;
  max-width: 1270px;
  padding: 0 15px 0 15px;
  margin: 0 auto;
  position: relative;
}
p {
  font-size: 14px;
  line-height: 18px;
  font-family: 'GothamPro', sans-serif;
  color: #000;
  font-weight: 400;
  padding: 0;
}
h1 {
  font-family: 'GothamPro', sans-serif;
  font-weight: 400;
  font-size: 75px;
  line-height: 85px;
  color: #fff;
  margin: 0;
  transition: all 0.3s ease-in;
}
h2 {
  font-family: 'GothamPro', sans-serif;
  font-weight: 400;
  font-size: 45px;
  line-height: 45px;
  color: #fff;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease-in;
}
h3 {
  font-size: 28px;
  line-height: 32px;
  font-family: 'GothamPro', sans-serif;
  color: #008000;
  font-weight: 700;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease-in;
}
h4 {
  margin: 0;
  transition: all 0.3s ease-in;
}
p {
  margin: 0;
  transition: all 0.3s ease-in;
}
li {
  transition: all 0.3s ease-in;
}
.page {
  overflow: hidden;
  position: relative;
  background: #050b29;
  transition: all 0.3s ease-in;
}
.page:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 5000px;
  background-image: url("../img/main/background.png");
  background-color: #050b29;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  transition: all 0.3s ease-in;
}
.clipPath {
  position: absolute;
  width: 0;
  height: 0;
}
span.gold {
  color: #f9d4a0;
}
.opacity {
  opacity: 0;
}
.svg {
  position: absolute;
  width: 0;
  height: 0;
}
.default-button {
  background: linear-gradient(180deg, #d3a96e 0%, #7c5f46 100%);
  border: none;
  width: 100%;
  border-radius: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 23px 30px;
}
.default-button:hover {
  box-shadow: rgba(50,50,93,0.25) 0px 30px 60px -12px inset, rgba(0,0,0,0.3) 0px 18px 36px -18px inset;
}
.wow {
  visibility: hidden;
}
.animateElement {
  opacity: 0;
  transition: all 0.3s ease-in;
}
@media (max-width: 1200px) {
  .footer-logo {
    margin-left: 20px;
  }
  .footer-date {
    margin-left: 20px;
  }
}
@media (max-width: 1100px) {
  .footer-top .footer-item {
    margin-right: 30px;
  }
}
@media (max-width: 998px) {
  .footer {
    padding: 30px 0 50px;
  }
  .footer-top {
    flex-direction: column;
    margin: 0 -5px 30px;
  }
  .footer-top .footer-item {
    margin: 0 10px;
  }
  .footer-top .footer-item:last-child {
    margin-right: 10px;
  }
  .footer-bottom {
    flex-direction: column;
    margin: 0 -10px;
  }
  .footer-bottom .footer-item {
    margin-right: 10px;
  }
  .footer-bottom .footer-item:last-child {
    margin-right: 10px;
  }
  .footer-items {
    margin-bottom: 20px;
  }
  .footer-item {
    margin: 0 10px;
  }
  .footer-logo {
    margin: 0 10px;
  }
  .footer-date {
    margin: 20px 0px 0;
    order: 2;
    text-align: center;
  }
  .footer-social {
    margin: 0 10px;
  }
  .footer-adress {
    margin: 0 auto 20px;
    order: -1;
    text-align: center;
  }
  .footer-adress__bottom {
    flex-direction: column;
  }
  .footer .city {
    margin: 0 10px;
  }
  .footer .adress {
    margin-bottom: 10px;
  }
  .header-navigation {
    padding-right: 0px;
  }
  .header-switch {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .header-navigation {
    padding-right: 0;
  }
  .header-logo {
    max-width: 120px;
    margin-right: 10px;
  }
  .header-navigation {
    padding-right: 0;
  }
  .header-list__item {
    margin: 0 10px 0 0;
  }
  .header-list__item a {
    font-size: 12px;
    line-height: 15px;
  }
  .footer-items {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .footer-item {
    width: calc(50% - 20px);
    text-align: center;
  }
  .footer-top .footer-item {
    margin-bottom: 15px;
  }
  .footer-logos {
    margin-top: 20px;
  }
}
@media (max-width: 600px) {
  .header {
    height: 87px;
  }
  .header-menu {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(5,11,41,0.6);
    z-index: 20;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in;
  }
  .header-menu__block {
    position: absolute;
    right: 0;
    top: 0;
    background: #050b29;
    max-width: 300px;
    width: 100%;
    transform: translateX(100%);
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 100px 20px 40px;
    transition: transform 0.3s ease-in;
  }
  .header-menu__button {
    width: 30px;
    height: 30px;
    position: relative;
    z-index: 21;
  }
  .header-menu__button .line {
    width: 28px;
    height: 1px;
    background: #f9d4a0;
    position: absolute;
    left: calc(50% - 14px);
    transition: all 0.3s ease-in;
  }
  .header-menu__button .line-1 {
    top: 7px;
  }
  .header-menu__button .line-2 {
    top: 15px;
  }
  .header-menu__button .line-3 {
    top: 23px;
  }
  .header-navigation {
    flex-grow: 0;
  }
  .header-list {
    flex-direction: column;
    margin-bottom: 30px;
  }
  .header-list__item {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .header-list__item a {
    font-size: 14px;
    line-height: 20px;
  }
  .header-language {
    margin-top: 20px;
    order: 4;
  }
  .header-switch__button {
    margin: 0 5px;
  }
  .openMenu {
    overflow: hidden;
  }
  .openMenu .header-menu {
    opacity: 1;
    pointer-events: all;
  }
  .openMenu .header-menu__block {
    transform: translateX(0);
  }
  .openMenu .header-menu__button .line-1 {
    transform: rotate(45deg);
    top: 50%;
  }
  .openMenu .header-menu__button .line-2 {
    opacity: 0;
  }
  .openMenu .header-menu__button .line-3 {
    transform: rotate(-45deg);
    top: 50%;
  }
}
.fintechSection {
  padding: 90px 0 50px;
  position: relative;
}
.fintechSection .wrapper {
  z-index: 2;
}
.fintechSection .title {
  text-align: center;
  position: relative;
  z-index: 10;
}
.fintechSection .title br {
  display: none;
}
.fintechSection .content {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 19px;
}
.fintechSection .blocks {
  width: 100%;
}
.fintechSection .blocks.left {
  position: relative;
  max-width: 215px;
}
.fintechSection .blocks.left .block-wrapper {
  -webkit-clip-path: url("#border-1");
  clip-path: url("#border-1");
  overflow: hidden;
}
.fintechSection .blocks.left .block-content {
  justify-content: flex-start;
  -webkit-clip-path: url("#border-1");
  clip-path: url("#border-1");
  overflow: hidden;
}
.fintechSection .blocks.left .block-icon {
  margin-right: 14px;
}
.fintechSection .blocks.left .block-title {
  text-align: left;
}
.fintechSection .blocks.right {
  max-width: 214px;
  position: relative;
}
.fintechSection .blocks.right .right-lines {
  position: relative;
  position: absolute;
  top: 32px;
  right: calc(100% - 41px);
  pointer-events: none;
}
.fintechSection .blocks.right .right-circles {
  position: absolute;
  top: 32px;
  right: calc(100% - 45px);
  z-index: 3;
  pointer-events: none;
}
.fintechSection .blocks.right .block-wrapper {
  -webkit-clip-path: url("#border-2");
  clip-path: url("#border-2");
  background-size: cover;
  overflow: hidden;
  border-radius: 0 20px 20px 0;
}
.fintechSection .blocks.right .block-content {
  justify-content: flex-end;
  background-size: cover;
  -webkit-clip-path: url("#border-2");
  clip-path: url("#border-2");
  overflow: hidden;
  border-radius: 0 20px 20px 0;
  padding: 12px 20px 12px 14px;
}
.fintechSection .blocks.right .block-icon {
  margin-left: 15px;
}
.fintechSection .blocks.right .block-title {
  text-align: right;
}
.fintechSection .block {
  position: relative;
  z-index: 2;
}
.fintechSection .block-wrapper {
  background: #f9d4a0;
  padding: 1px;
  margin-bottom: 10px;
}
.fintechSection .block-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 10px 12px 20px;
  min-height: 64px;
  height: 64px;
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
  display: flex;
}
.fintechSection .block-icon {
  min-width: 40px;
}
.fintechSection .block-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #fff;
}
.fintechSection .block-lines {
  position: absolute;
}
.fintechSection .block-lines-1 {
  top: 28px;
  left: calc(100% - 39px);
  width: 148px;
}
.fintechSection .block-lines-2 {
  top: 306px;
  left: calc(100% - 45px);
  width: 148px;
}
.fintechSection .block-line {
  position: absolute;
}
.fintechSection .block-line__opacity {
  position: absolute;
  z-index: 2;
}
.fintechSection .block-line__opacity-1 {
  top: 3%;
  left: calc(100% - 53px);
}
.fintechSection .block-line__opacity-2 {
  top: 47.5%;
  left: calc(100% - 50px);
}
.fintechSection .block-line__opacity-2 g {
  opacity: 0;
}
.fintechSection .block-line__opacity-2 g.show {
  opacity: 1;
}
.fintechSection .block-round {
  z-index: 2;
  position: absolute;
  left: calc(100% + 90px);
  top: 23.2%;
}
.fintechSection .block-round__content {
  width: 126px;
  height: 126px;
  border-radius: 50%;
  background-image: url("../img/main/fintech/rounds.svg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.fintechSection .block-round__content.active .center-block__line {
  animation-name: centerBlockLine;
}
.fintechSection .block-round__content img {
  position: relative;
  z-index: 2;
}
.fintechSection .block-round__title {
  position: relative;
  min-height: 18px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}
.fintechSection .block-round__title span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.5s ease-in;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.3125px;
  color: rgba(249,212,160,0.3);
}
.fintechSection .block-round__title span.title-1 {
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.5625px;
  color: #fff;
  top: 0;
  opacity: 1;
}
.fintechSection .block-round__title span.title-2 {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.4375px;
  color: rgba(249,212,160,0.5);
  top: 18px;
  opacity: 1;
}
.fintechSection .block-round__title span.title-3 {
  top: 36px;
  opacity: 1;
}
.fintechSection .block-round__title span.title-4 {
  top: 54px;
}
.fintechSection .block-round__title span.title-5 {
  top: 54px;
}
.fintechSection .block-round__title span.title-6 {
  top: -18px;
}
.fintechSection .block-round__lines {
  position: absolute;
  right: calc(100% - 9px);
  top: 52px;
}
.fintechSection .block-round__lines #round-el-1 {
  opacity: 0;
  transition: all 0.3s ease-in;
  transform: translate(-7px, -8px);
}
.fintechSection .block-round__lines.active #round-el-1 {
  animation-duration: 2s;
  animation-name: roundElement;
}
.fintechSection .center {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.fintechSection .center-block {
  max-width: 252px;
  width: 100%;
  margin: 148px auto 0;
  position: relative;
}
.fintechSection .center-block.active .center-block__line {
  animation-name: centerBlockLine;
}
.fintechSection .center-block__content {
  background: linear-gradient(180deg, #f9d4a0 0%, #99746e 100%);
  backdrop-filter: blur(73.3936px);
  border-radius: 60px;
  box-shadow: 0px 42px 70px rgba(3,18,54,0.534);
  padding: 35px 20px 49px;
  width: 100%;
  z-index: 2;
}
.fintechSection .center-block__logo {
  max-width: 74px;
  width: 100%;
  margin: 0 auto 19px;
}
.fintechSection .center-block__title {
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #fff;
}
.fintechSection .center-block__line {
  position: absolute;
  border: 0.5px solid rgba(249,212,160,0.3);
  border-radius: 60px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation-duration: 1s;
}
.fintechSection .center-block__line2 {
  animation-delay: 0.2s;
}
.fintechSection .center-block__line3 {
  animation-delay: 0.4s;
}
.fintechSection .center-block__line4 {
  animation-delay: 0.6s;
}
.fintechSection .center-block__line5 {
  animation-delay: 1.2s;
}
.fintechSection .center-block__lines {
  position: absolute;
  bottom: calc(100% - 14px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.fintechSection .center-block__lines #center-el-1 {
  opacity: 0;
  transition: all 0.3s ease-in;
  transform: translate(-8.5px, -7px);
}
.fintechSection .center-block__lines.active #center-el-1 {
  animation-duration: 1.2s;
  animation-name: roundElement;
}
.fintechSection .center-block__lines #center-el-2 {
  opacity: 0;
  transition: all 0.3s ease-in;
  transform: translate(8.5px, 7px) rotate(180deg);
}
.fintechSection .center-block__lines.active2 #center-el-2 {
  animation-duration: 1.2s;
  animation-name: roundElement;
}
.fintechSection .center-block__round {
  position: absolute;
  right: -6px;
  top: calc(50% - 8px);
  width: 15px;
  height: 15px;
  background: #ec440f;
  border: 4px solid #f9d4a0;
  border-radius: 50%;
}
.fintechSection .center-block__round:after {
  content: "";
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 0.5px solid transparent;
  transition: all 0.25s ease-in;
}
.fintechSection .center-block__round.active:after {
  border-color: #f9d4a0;
}
.fintechSection .center-block__right {
  position: absolute;
  left: 100%;
  top: -121px;
}
.fintechSection .center-block__right .right-el {
  transform: translate(-6px, -8px);
}
.fintechSection .center-block__right g {
  opacity: 0;
}
.fintechSection .center-block__right g.active {
  animation-duration: 2s;
  animation-name: rightEl;
}
.fintechSection .center-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.fintechSection .center-items__block {
  padding: 34px 15px 0;
}
.fintechSection .center-item {
  margin: 0 3.5px -1px;
  max-width: 86px;
  width: 100%;
  position: relative;
}
.fintechSection .center-item:first-child .center-item__block:after {
  display: none;
}
.fintechSection .center-item.active .center-item__content {
  border-color: rgba(249,212,160,0.4);
}
.fintechSection .center-item__content {
  width: 86px;
  height: 86px;
  padding: 6px;
  border-radius: 50%;
  border: 1px solid transparent;
  transition: all 0.3s ease-in;
  position: relative;
}
.fintechSection .center-item__block {
  background: linear-gradient(174.38deg, #2e44aa 4.48%, #020e47 116.06%);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.fintechSection .center-item__block:after {
  content: "";
  position: absolute;
  top: calc(50% - 3.5px);
  right: 95%;
  width: 27px;
  height: 7px;
  background-image: url("../img/main/fintech/center/link_gor.svg");
}
.fintechSection .center-item__block--leftLine {
  position: absolute;
  right: 94%;
  top: 44px;
  z-index: 2;
}
.fintechSection .center-item__block--leftLine.show #centerLeft_element {
  opacity: 1;
}
.fintechSection .center-item__block--rightLine {
  position: absolute;
  left: 83%;
  top: 48px;
  z-index: 2;
}
.fintechSection .center-item__block--rightLine .opacity {
  transform: translateX(10px);
}
.fintechSection .center-item__block--rightLine.show #centerRight_element {
  opacity: 1;
}
.fintechSection .center-item__title {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #f9d4a0;
  margin-top: 3px;
}
.rightItems-line {
  position: absolute;
  bottom: -34px;
  left: 30px;
}
.rightItems-line .right-el {
  transform: translate(-10px, -10px);
}
.rightItems-line g {
  opacity: 0;
}
.rightItems-line g.active {
  animation-duration: 2s;
  animation-name: rightEl;
}
#lines-el-6 {
  transform: translate(-9px, -14px);
}
#lines-el-7 {
  transform: translate(-19px, -6px);
  position: relative;
  z-index: 2;
}
#lines-el-9 {
  transform: translate(-19px, -6px);
  position: relative;
  z-index: 2;
}
#lines-el-10 {
  transform: translate(-19px, -13px);
  position: relative;
  z-index: 2;
}
#lines-el-8 {
  transform: translate(-12px, -9px);
}
#centerLeft_element {
  opacity: 0;
  transform: translate(-9px, -12px);
  transition: all 0.3s ease-in;
}
#centerRight_element {
  opacity: 0;
  transform: translate(9px, 9px) rotate(-180deg);
  transition: all 0.3s ease-in;
}
.center-adaptive__left {
  display: none;
}
.center-adaptive__right {
  display: none;
}
.center-adaptive__center {
  display: none;
}
.preview {
  max-width: 332px;
  width: 100%;
  position: relative;
  border-radius: 34px;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer;
}
.preview-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.preview-btn:hover .preview-icon {
  transform: scare(0.7s);
}
.preview-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  transition: all 0.3s ease-in;
}
.preview-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #f9d4a0;
}
@media (max-width: 1270px) {
  .fintechSection .content {
    top: 0;
    max-width: 1070px;
    margin: 0 auto;
  }
  .fintechSection .blocks.left {
    max-width: 170px;
  }
  .fintechSection .blocks.left .block-content {
    padding: 5px 14px;
  }
  .fintechSection .blocks.right {
    max-width: 169px;
  }
  .fintechSection .blocks.right .block-content {
    padding: 5px 14px 5px 14px;
  }
  .fintechSection .blocks.right .right-lines {
    top: 25px;
    right: calc(100% - 50px);
  }
  .fintechSection .blocks.right .right-circles {
    right: calc(100% - 41px);
    top: 28px;
  }
  .fintechSection .blocks.right .right-circles svg {
    height: 536px;
  }
  .fintechSection .block-title {
    font-size: 14px;
    line-height: 18px;
  }
  .fintechSection .block-icon {
    min-width: 25px;
    max-width: 25px;
  }
  .fintechSection .block-lines-1 {
    left: calc(100% - 65px);
  }
  .fintechSection .block-lines-2 {
    left: calc(100% - 70px);
    top: 291px;
  }
  .fintechSection .block-line__opacity-1 {
    left: calc(100% - 76px);
  }
  .fintechSection .block-line__opacity-2 {
    top: 46.5%;
    left: calc(100% - 82px);
  }
  .fintechSection .block-round {
    left: calc(100% + 77px);
  }
  .fintechSection .center-block {
    max-width: 215px;
  }
  .fintechSection .center-block__content {
    padding: 25px 20px 35px;
    border-radius: 30px;
  }
  .fintechSection .center-block__round {
    top: calc(50% + 4px);
  }
  .fintechSection .center-block__right {
    top: -125px;
  }
  .fintechSection .rightItems-line {
    width: 150px;
    overflow: hidden;
  }
}
.fintechSection #right-el7 {
  transform: translate(-10px, 15px);
}
@media (max-width: 1100px) {
  .fintechSection {
    padding: 83px 0 0;
  }
  .fintechSection .content {
    margin-top: -78px;
  }
  .fintechSection .blocks {
    display: none;
  }
  .fintechSection .block-round__lines {
    display: none;
  }
  .fintechSection .center-items__block {
    display: none;
  }
  .fintechSection .center-block__lines {
    display: none;
  }
  .fintechSection .center-block__round {
    display: none;
  }
  .fintechSection .center {
    width: 100%;
    max-width: none;
  }
  .fintechSection .center-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .fintechSection .center-block {
    margin: 0 auto;
    max-width: 332px;
    width: 100%;
    flex-grow: 1;
  }
  .fintechSection .center-block__content {
    border-radius: 60px;
    padding: 76px 30px 82px;
  }
  .fintechSection .center-block__logo {
    margin-bottom: 25px;
  }
  .fintechSection .center-block .center-block__line {
    animation-name: centerBlockLine2;
    animation-iteration-count: infinite;
  }
  .fintechSection .wrapper {
    padding: 0;
  }
  .fintechSection .center-adaptive__left {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
  }
  .fintechSection .center-adaptive__left img {
    width: 100%;
    min-width: 333px;
  }
  .fintechSection .center-adaptive__right {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
  }
  .fintechSection .center-adaptive__right img {
    width: 100%;
    min-width: 333px;
  }
  .fintechSection .center-adaptive__center {
    display: block;
    margin-top: -93px;
  }
  .fintechSection .title {
    padding: 0 30px;
  }
}
@media (max-width: 768px) {
  .fintechSection {
    padding: 46px 0;
  }
  .fintechSection .content {
    margin-top: -75px;
  }
  .fintechSection .title {
    font-size: 35px;
    line-height: 35px;
  }
  .fintechSection .title br {
    display: block;
  }
  .fintechSection .center-block {
    max-width: 252px;
    min-width: 252px;
  }
  .fintechSection .preview {
    max-width: 252px;
    border-radius: 18px;
  }
  .fintechSection .center-adaptive__left img {
    position: relative;
    top: -51px;
    right: -22px;
  }
  .fintechSection .center-adaptive__center {
    margin-top: -83px;
  }
  .fintechSection .center-adaptive__right img {
    position: relative;
    top: -51px;
    left: -22px;
  }
}
@-moz-keyframes linesEl {
  0% {
    transform: scale(1) translate(-1px, -6px);
  }
  80% {
    transform: scale(1) translate(-1px, -6px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -6px);
  }
}
@-webkit-keyframes linesEl {
  0% {
    transform: scale(1) translate(-1px, -6px);
  }
  80% {
    transform: scale(1) translate(-1px, -6px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -6px);
  }
}
@-o-keyframes linesEl {
  0% {
    transform: scale(1) translate(-1px, -6px);
  }
  80% {
    transform: scale(1) translate(-1px, -6px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -6px);
  }
}
@keyframes linesEl {
  0% {
    transform: scale(1) translate(-1px, -6px);
  }
  80% {
    transform: scale(1) translate(-1px, -6px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -6px);
  }
}
@-moz-keyframes linesEl2 {
  0% {
    transform: scale(1) translate(-1px, -18px);
  }
  80% {
    transform: scale(1) translate(-1px, -18px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -18px);
  }
}
@-webkit-keyframes linesEl2 {
  0% {
    transform: scale(1) translate(-1px, -18px);
  }
  80% {
    transform: scale(1) translate(-1px, -18px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -18px);
  }
}
@-o-keyframes linesEl2 {
  0% {
    transform: scale(1) translate(-1px, -18px);
  }
  80% {
    transform: scale(1) translate(-1px, -18px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -18px);
  }
}
@keyframes linesEl2 {
  0% {
    transform: scale(1) translate(-1px, -18px);
  }
  80% {
    transform: scale(1) translate(-1px, -18px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -18px);
  }
}
@-moz-keyframes linesEl3 {
  0% {
    transform: scale(1) translate(-1px, -30px);
  }
  80% {
    transform: scale(1) translate(-1px, -30px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -30px);
  }
}
@-webkit-keyframes linesEl3 {
  0% {
    transform: scale(1) translate(-1px, -30px);
  }
  80% {
    transform: scale(1) translate(-1px, -30px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -30px);
  }
}
@-o-keyframes linesEl3 {
  0% {
    transform: scale(1) translate(-1px, -30px);
  }
  80% {
    transform: scale(1) translate(-1px, -30px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -30px);
  }
}
@keyframes linesEl3 {
  0% {
    transform: scale(1) translate(-1px, -30px);
  }
  80% {
    transform: scale(1) translate(-1px, -30px);
  }
  100% {
    transform: scale(0.6) translate(-1px, -30px);
  }
}
@-moz-keyframes centerBlockLine {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@-webkit-keyframes centerBlockLine {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@-o-keyframes centerBlockLine {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes centerBlockLine {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
@-moz-keyframes roundBlockTitleNoActive {
  0% {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 200%);
  }
}
@-webkit-keyframes roundBlockTitleNoActive {
  0% {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 200%);
  }
}
@-o-keyframes roundBlockTitleNoActive {
  0% {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 200%);
  }
}
@keyframes roundBlockTitleNoActive {
  0% {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 200%);
  }
}
@-moz-keyframes roundElement {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes roundElement {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes roundElement {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes roundElement {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes rightEl {
  0% {
    opacity: 0;
    transform: translate(-10px, -10px) scale(0.4);
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: translate(-10px, -10px) scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes rightEl {
  0% {
    opacity: 0;
    transform: translate(-10px, -10px) scale(0.4);
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: translate(-10px, -10px) scale(1);
    opacity: 1;
  }
}
@-o-keyframes rightEl {
  0% {
    opacity: 0;
    transform: translate(-10px, -10px) scale(0.4);
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: translate(-10px, -10px) scale(1);
    opacity: 1;
  }
}
@keyframes rightEl {
  0% {
    opacity: 0;
    transform: translate(-10px, -10px) scale(0.4);
  }
  30% {
    opacity: 1;
  }
  100% {
    transform: translate(-10px, -10px) scale(1);
    opacity: 1;
  }
}
@-moz-keyframes centerBlockLine2 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
@-webkit-keyframes centerBlockLine2 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
@-o-keyframes centerBlockLine2 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
@keyframes centerBlockLine2 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
.modal {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0,0,0,0.5);
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.modal.active {
  display: flex;
}
.modal-block {
  max-width: 600px;
  width: 100%;
  margin: auto;
  position: relative;
}
.modal-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -34px;
}
@media (max-width: 768px) {
  .modal .modal-close {
    right: 0;
    top: -34px;
  }
}
.modalvideo.active {
  display: none;
}
.modalvideo .video {
  border-radius: 40px;
  overflow: hidden;
}
.modalvideo .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1100px) {
  .modalvideo.active {
    display: flex;
  }
}
@media (max-width: 768px) {
  .modalvideo .video {
    border-radius: 25px;
  }
}
body {
  color: #fff;
}
.mainSection {
  position: relative;
  z-index: 2;
}
.mainSection.bigPartner .mainSection-swiper__pagination {
  opacity: 0;
}
.mainSection.bigPartner .mainSection-swiper__buttons {
  opacity: 0;
}
.mainSection .mainSection-swiper {
  overflow: visible;
}
.mainSection .mainSection-swiper {
  overflow: visible;
}
.mainSection-content {
  position: relative;
  z-index: 2;
}
.mainSection-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mainSection-video:before {
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #091c4e 0%, rgba(9,40,120,0) 100%);
  pointer-events: none;
  z-index: 2;
}
.mainSection-video video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
.mainSection-swiper {
  height: calc(100vh + 10px);
}
.mainSection-swiper__pagination {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  transition: all 0.25s ease-in;
}
.mainSection-swiper__pagination .swiper-pagination-current {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  position: absolute;
  top: 23px;
  left: 37px;
}
.mainSection-swiper__pagination .swiper-pagination-total {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #f9d4a1;
  position: absolute;
  bottom: 26px;
  right: 34px;
}
.mainSection-swiper__pagination .pagination {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  font-size: 0;
}
.mainSection-swiper__pagination .pagination-wrapper {
  position: absolute;
  top: 199px;
  left: 3px;
  width: 70px;
  height: 70px;
  width: 93px;
  height: 93px;
  border-radius: 50%;
}
.mainSection-swiper__pagination .pagination-wrapper .circle-bg {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainSection-swiper__pagination .pagination-wrapper .circle-main {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainSection-swiper__pagination .pagination-wrapper .circle-main circle {
  transition: all 0.3s ease-in;
}
.mainSection-swiper__buttons {
  position: absolute;
  max-width: 1300px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 10;
  transition: all 0.25s ease-in;
}
.mainSection-button-prev,
.mainSection-button-next {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
  pointer-events: all;
}
.mainSection-button-prev.swiper-button-disabled,
.mainSection-button-next.swiper-button-disabled {
  pointer-events: none;
}
.mainSection-button-prev.swiper-button-disabled img,
.mainSection-button-next.swiper-button-disabled img {
  opacity: 0.5;
}
.mainSection-button-prev {
  left: 10px;
}
.mainSection-button-next {
  right: 10px;
}
.mainSection-img {
  position: relative;
}
.mainSection-img .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainSection-img .play path {
  fill: #fff;
  transition: all 0.3s ease-in;
}
.mainSection-img .play:hover path {
  fill: #f9d4a1;
}
.mainSection-slide {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.mainSection-slide:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(0deg, #050b29 0%, rgba(9,40,120,0) 100%);
  pointer-events: none;
}
.mainSection-slide__wrapper {
  max-width: 1290px;
  width: 100%;
  padding: 0 60px;
  margin: 0 auto;
  min-height: 100%;
  padding-top: 150px;
  position: relative;
  z-index: 2;
}
.mainSection-slide__content {
  position: relative;
  padding-left: 64px;
  min-height: 100%;
}
.mainSection-slide__1 .mainSection-slide__content {
  display: flex;
  justify-content: space-between;
}
.mainSection-slide__1 .mainSection-title {
  max-width: 520px;
  width: 100%;
  padding-top: 55px;
}
.mainSection-slide__1 .mainSection-img {
  max-width: 360px;
  width: 100%;
  margin: 32px auto 0;
}
.mainSection-slide__1 .mainSection-items {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -2px;
  padding-left: 64px;
  max-width: 744px;
}
.mainSection-slide__1 .mainSection-item {
  width: calc(50% - 30px);
  max-width: 280px;
}
.mainSection-slide__1 .mainSection-item__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 15px;
  color: #fff;
}
.mainSection-slide__1 .mainSection-item__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f9d4a1;
}
.mainSection-slide__2:after {
  z-index: 3;
}
.mainSection-slide__2 .mainSection-slide__content {
  padding-top: 55px;
}
.mainSection-slide__2 .mainSection-title {
  max-width: 670px;
  width: 47vw;
}
.mainSection-slide__2 .mainSection-button {
  max-width: 249px;
  width: 100%;
  margin-top: 85px;
}
.mainSection-slide__2 .mainSection-button a {
  width: 100%;
}
.mainSection-slide__2 .mainSection-hand {
  position: absolute;
  bottom: -160px;
  right: -280px;
  right: -34vh;
  bottom: -20vh;
  transform: rotate(70deg);
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  max-width: 540px;
  width: 39vw;
}
.mainSection-slide__2 .mainSection-hand .hand {
  max-width: 540px;
  width: 100%;
}
.mainSection-slide__2 .mainSection-hand .card {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-delay: 2s;
  transform: translate(0, 20px);
  position: relative;
}
.mainSection-slide__2 .mainSection-hand .card .icon {
  position: absolute;
  bottom: 9%;
  right: 7%;
  max-width: 46px;
  width: 13%;
}
.mainSection-slide__2 .mainSection-hand .card .icon img {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.mainSection-slide__2 .mainSection-hand .card .icon img.active {
  opacity: 1;
}
.mainSection-slide__2 .mainSection-hand .card-block {
  max-width: 424px;
  max-width: 70%;
  width: 100%;
  position: absolute;
  bottom: 106%;
  right: 25%;
  transform: rotate(30deg);
  animation-duration: 2s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
.mainSection-slide__2.showEl .mainSection-hand {
  animation-name: handAnimate;
}
.mainSection-slide__2.showEl .mainSection-hand .card-block {
  animation-name: cardAnimate;
}
.mainSection-slide__2.showEl .mainSection-hand .card {
  animation-name: cardAnimate2;
}
.mainSection-slide__3 .mainSection-slide__content {
  padding-top: 56px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.mainSection-slide__3 .mainSection-slide__wrapper {
  display: flex;
  flex-direction: column;
}
.mainSection-slide__3 .mainSection-title {
  max-width: 565px;
  letter-spacing: -1.4px;
  margin-right: 50px;
}
.mainSection-slide__3 .mainSection-button {
  max-width: 246px;
  width: 100%;
}
.mainSection-slide__3 .mainSection-img {
  max-width: 440px;
  width: 100%;
  margin: 60px auto 0;
  height: max-content;
}
h1.mainSection-title {
  font-size: 64px;
  line-height: 74px;
  letter-spacing: -2px;
  margin-right: 32px;
}
h2.mainSection-title {
  font-weight: 400;
  font-size: 64px;
  line-height: 74px;
  letter-spacing: -2px;
}
.contactUs {
  border: 1px solid rgba(249,212,161,0.6);
  border-radius: 63px;
  width: 126px;
  height: 126px;
  position: fixed;
  right: 5%;
  bottom: 8.7%;
  right: 4vw;
  bottom: 4vw;
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-name: showBlock;
}
.contactUs-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 2;
  background: rgba(4,16,58,0.3);
  transition: all 0.25s ease-in;
  padding-bottom: 10px;
  pointer-events: all;
}
.contactUs-block:hover {
  background: #04103a;
}
.contactUs-block:hover .contactUs-title {
  color: #f9d4a1;
}
.contactUs-icon {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactUs-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  transition: all 0.25s ease-in;
}
.contactUs-line {
  position: absolute;
  border: 0.5px solid #f9d4a0;
  border-radius: 60px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  animation-duration: 1.6s;
  animation-name: centerBlockLine3;
  animation-iteration-count: infinite;
  z-index: -1;
}
.contactUs-line2 {
  animation-delay: 0.4s;
}
.contactUs-line3 {
  animation-delay: 0.8s;
}
.contactUs.getSandboxAccess {
  bottom: calc(4vw + 192px);
}
.videoModal {
  display: none;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  padding: 0;
  background: transparent;
}
.videoModal video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
}
@media (max-height: 840px) and (min-width: 1101px) {
  .header {
    height: 16.7vh;
  }
  .header-logo {
    max-width: 11.2vw;
  }
  .mainSection-slide__wrapper {
    padding-top: 18vh;
  }
  .mainSection-slide__content {
    padding-left: 7.7vh;
  }
  .mainSection-slide__1 .mainSection-title {
    max-width: 36.15vw;
    margin-right: 2vw;
    padding-top: 6.6vh;
    font-size: 7.65vh;
    line-height: 8.7vh;
  }
  .mainSection-slide__1 .mainSection-img {
    max-width: 43vh;
    max-height: 43vh;
    margin-top: 2.2vw;
  }
  .mainSection-slide__1 .mainSection-items {
    padding-left: 7.7vh;
  }
  .mainSection-slide__1 .mainSection-item__title {
    font-size: 2.39vh;
    line-height: 2.9vh;
    margin-bottom: 1.6vh;
  }
  .mainSection-slide__1 .mainSection-item__text {
    font-size: 1.67vh;
    line-height: 2.7vh;
  }
  .mainSection-slide__1 .mainSection-hand {
    max-width: 44vh;
  }
  .mainSection-slide__2 .mainSection-button {
    margin-top: 10.2vh;
  }
  .mainSection-slide__2 .mainSection-slide__content {
    padding-top: 6.4vh;
  }
  .mainSection-slide__3 .mainSection-slide__content {
    padding-top: 6.6vh;
    margin-bottom: 2vh;
  }
  .mainSection-slide__3 .mainSection-title {
    margin-right: 3.6vw;
    max-width: 39.3vw;
  }
  .mainSection-slide__3 .mainSection-img {
    max-width: 35vw;
    width: 80vh;
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2.mainSection-title {
    font-size: 7.65vh;
    line-height: 8.8vh;
  }
}
@media (max-width: 1200px) {
  .mainSection-slide__wrapper {
    padding-top: 18vh;
  }
  .mainSection-slide__content {
    padding-left: 7.7vh;
  }
  .mainSection-slide__1 .mainSection-title {
    max-width: 36.15vw;
    margin-right: 2vw;
    padding-top: 6.6vh;
  }
  .mainSection-slide__1 .mainSection-img {
    max-width: 43vh;
    max-height: 43vh;
    margin-top: 2.2vw;
  }
  .mainSection-slide__1 .mainSection-items {
    padding-left: 7.7vh;
    margin-top: 2vw;
  }
  .mainSection-slide__1 .mainSection-button {
    margin-top: 10.2vh;
  }
  .mainSection-slide__1 .mainSection-slide__content {
    padding-top: 6.4vh;
  }
  .mainSection-slide__3 .mainSection-slide__content {
    padding-top: 6.6vh;
    margin-bottom: 2vh;
    align-items: center;
  }
  .mainSection-slide__3 .mainSection-title {
    margin-right: 3.6vw;
    max-width: 39.3vw;
  }
  .mainSection-slide__3 .mainSection-img {
    max-width: 30.6vw;
    max-height: 28vh;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h2.mainSection-title {
    font-size: 4.65vw;
    line-height: 5.8vw;
  }
}
@media (max-width: 1200px) and (max-height: 700px) {
  h2.mainSection-title {
    font-size: 7.65vh;
    line-height: 8.8vh;
  }
  .mainSection-slide__1 .mainSection-slide__content {
    padding-top: 0;
  }
  .mainSection-slide__1 .mainSection-title {
    font-size: 7.65vh;
    line-height: 8.7vh;
  }
  .mainSection-slide__1 .mainSection-items {
    max-width: none;
  }
  .mainSection-slide__1 .mainSection-item {
    max-width: none;
  }
  .mainSection-slide__1 .mainSection-item__title {
    font-size: 2.39vh;
    line-height: 2.9vh;
    margin-bottom: 1.6vh;
  }
  .mainSection-slide__1 .mainSection-item__text {
    font-size: 1.67vh;
    line-height: 2.7vh;
  }
}
@media (min-height: 840px) and (max-width: 1200px) and (min-width: 999px) {
  .mainSection-swiper__pagination .pagination-wrapper {
    max-width: 8vw;
    max-height: 8vw;
  }
  .mainSection-swiper__pagination .swiper-pagination-current {
    font-size: 1.2vw;
    line-height: 2vw;
    top: 2vw;
    left: 2.8vw;
  }
  .mainSection-swiper__pagination .swiper-pagination-total {
    font-size: 1.2vw;
    line-height: 2vw;
    bottom: 2.2vw;
    right: 2.8vw;
  }
}
@media (max-height: 750px) {
  .mainSection-slide__2 .mainSection-hand {
    max-width: 60vh;
  }
}
@media (max-width: 998px) {
  .header {
    height: 110px;
  }
  .mainSection-swiper__buttons {
    display: none;
  }
  .mainSection-slide__wrapper {
    padding: 0 20px;
    padding-top: 110px;
  }
  .mainSection-slide__1 .mainSection-slide__content {
    padding-left: 0;
    align-items: center;
  }
  .mainSection-slide__1 .mainSection-items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }
  .mainSection-slide__1 .mainSection-img {
    width: 36vw;
    height: 36vw;
    margin-top: 1vw;
  }
  .mainSection-slide__2 .mainSection-slide__content {
    padding-top: 60px;
    padding-left: 0;
    max-width: 50vw;
  }
  .mainSection-slide__2 .mainSection-button {
    margin-top: 5vh;
  }
  .mainSection-slide__3 .mainSection-title {
    max-width: 60vw;
    width: 100%;
  }
  .mainSection-slide__3 .mainSection-slide__content {
    padding-top: 60px;
    padding-left: 0;
    max-width: 100%;
  }
  .mainSection-swiper__pagination .pagination-wrapper {
    top: 115px;
    width: 80px;
    height: 80px;
  }
  .mainSection-swiper__pagination .swiper-pagination-current {
    font-size: 14px;
    line-height: 16px;
    top: 23px;
    left: 26px;
  }
  .mainSection-swiper__pagination .swiper-pagination-total {
    font-size: 14px;
    line-height: 16px;
    bottom: 23px;
    right: 26px;
  }
}
@media (max-width: 768px) {
  .mainSection-swiper__pagination {
    display: none;
  }
  .mainSection-swiper__pagination .pagination-wrapper {
    top: 96px;
  }
  .mainSection-slide.swiper-slide {
    transition: opacity 0.5s ease-in !important;
  }
  .mainSection-slide.swiper-slide .mainSection-slide__wrapper {
    transition: transform 0.7s ease-in;
  }
  .mainSection-slide__wrapper {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .mainSection-slide__1 .mainSection-slide__content {
    flex-direction: column;
    padding-top: 0;
  }
  .mainSection-slide__1 .mainSection-title {
    padding-top: 20px;
    max-width: none;
    padding-left: 0;
    font-size: 30px;
    line-height: 40px;
  }
  .mainSection-slide__1 .mainSection-img {
    width: 100%;
    max-width: 30vh;
    max-height: 30vh;
    height: 100%;
    margin: 30px auto 0;
  }
  .mainSection-slide__1 .mainSection-items {
    flex-direction: column;
  }
  .mainSection-slide__1 .mainSection-item {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .mainSection-slide__2 .mainSection-slide__content {
    padding-top: 20px;
    max-width: none;
  }
  .mainSection-slide__2 .mainSection-hand {
    max-width: 35vh;
    width: 80vw;
  }
  .mainSection-slide__2 .mainSection-title {
    max-width: none;
    width: 100%;
  }
  .mainSection-slide__2.showEl .mainSection-hand {
    animation-name: handAnimateMobile;
  }
  .mainSection-slide__3 .mainSection-img {
    max-width: 35vh;
  }
  h2.mainSection-title {
    font-size: 26px;
    line-height: 40px;
  }
  .contactUs {
    width: 90px;
    height: 90px;
  }
  .contactUs-title {
    font-size: 10px;
    line-height: 14px;
  }
  .contactUs-icon {
    max-width: 18px;
    margin-top: 5px;
  }
  .contactUs.getSandboxAccess {
    bottom: calc(4vw + 130px);
  }
}
@media (max-width: 768px) and (min-height: 500px) {
  .mainSection-slide__3 .mainSection-slide__content {
    padding-top: 20px;
    flex-direction: column;
  }
  .mainSection-slide__3 .mainSection-title {
    max-width: none;
    margin-bottom: 30px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .mainSection-slide__1 .mainSection-title {
    padding-top: 0;
  }
  .mainSection-slide__2 .mainSection-slide__content {
    padding-top: 0;
  }
  .mainSection-slide__3 .mainSection-slide__content {
    padding-top: 0;
  }
}
@media (max-height: 480px) {
  .mainSection-swiper__pagination {
    display: none;
  }
  .mainSection-slide__2 .mainSection-slide__content {
    padding-top: 0;
  }
  .mainSection-slide__3 .mainSection-slide__content {
    padding-top: 0;
  }
  .mainSection-slide__1 .mainSection-title {
    padding-top: 0;
  }
  .mainSection-slide__1 .mainSection-img {
    max-width: 37vh;
    max-height: 37vh;
    margin: 0 auto;
  }
  .mainSection-slide__1 .mainSection-item__title {
    font-size: 14px;
    line-height: 20px;
  }
  .mainSection-slide__1 .mainSection-item__text {
    font-size: 10px;
    line-height: 16px;
  }
  .mainSection-slide__1 .mainSection-items {
    flex-direction: row;
  }
}
@-moz-keyframes centerBlockLine3 {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
@-webkit-keyframes centerBlockLine3 {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
@-o-keyframes centerBlockLine3 {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
@keyframes centerBlockLine3 {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
@-moz-keyframes handAnimate {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: -7vh;
    right: -5vh;
    transform: rotate(0);
  }
}
@-webkit-keyframes handAnimate {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: -7vh;
    right: -5vh;
    transform: rotate(0);
  }
}
@-o-keyframes handAnimate {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: -7vh;
    right: -5vh;
    transform: rotate(0);
  }
}
@keyframes handAnimate {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: -7vh;
    right: -5vh;
    transform: rotate(0);
  }
}
@-moz-keyframes cardAnimate {
  0% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
@-webkit-keyframes cardAnimate {
  0% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
@-o-keyframes cardAnimate {
  0% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
@keyframes cardAnimate {
  0% {
    transform: rotate(40deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
@-moz-keyframes cardAnimate2 {
  0% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, -10px);
  }
}
@-webkit-keyframes cardAnimate2 {
  0% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, -10px);
  }
}
@-o-keyframes cardAnimate2 {
  0% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, -10px);
  }
}
@keyframes cardAnimate2 {
  0% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, -10px);
  }
}
@-moz-keyframes handAnimateMobile {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
  }
  100% {
    bottom: 0vh;
    right: 6vw;
    transform: rotate(0);
  }
}
@-webkit-keyframes handAnimateMobile {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
  }
  100% {
    bottom: 0vh;
    right: 6vw;
    transform: rotate(0);
  }
}
@-o-keyframes handAnimateMobile {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
  }
  100% {
    bottom: 0vh;
    right: 6vw;
    transform: rotate(0);
  }
}
@keyframes handAnimateMobile {
  0% {
    right: -34vh;
    bottom: -20vh;
    transform: rotate(70deg);
  }
  100% {
    bottom: 0vh;
    right: 6vw;
    transform: rotate(0);
  }
}
@-moz-keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.partners {
  padding: 120px 0 37px;
  position: relative;
  z-index: 2;
  min-height: 600px;
}
.partners:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10vw;
  background: linear-gradient(180deg, #050b29 0%, rgba(9,40,120,0) 100%);
  z-index: -1;
}
.partners-title {
  text-align: center;
  font-size: 55px;
  line-height: 65px;
}
.partners-content {
  max-width: 970px;
  width: 100%;
  margin: 85px auto 0;
  display: flex;
  justify-content: center;
  height: 140px;
}
.partners-block {
  width: 100%;
  margin: 0 auto;
  background: #fff;
  border-radius: 45px;
  padding: 45px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in;
  height: 100%;
}
.partners-block .swiper-wrapper {
  align-items: center;
}
.partners-block .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.partners-block .swiper-slide img {
  width: 80%;
}
.partners-block.big-1 {
  height: 120%;
  width: 110%;
}
.partners-block.big-2 {
  height: 140%;
  width: 120%;
}
.partners-block.big-3 {
  height: 160%;
  width: 130%;
}
.partners-button-prev {
  transform: rotate(-180deg);
  min-width: 24px;
  cursor: pointer;
}
.partners-button-prev.swiper-button-disabled {
  opacity: 0.2;
  pointer-events: none;
}
.partners-button-next {
  min-width: 24px;
  cursor: pointer;
}
.partners-button-next.swiper-button-disabled {
  opacity: 0.2;
  pointer-events: none;
}
.partners-bottom {
  margin-top: 30px;
}
@media (max-width: 998px) {
  .partners {
    min-height: 400px;
  }
  .partners-content {
    max-height: 100px;
  }
  .partners-block {
    padding: 30px 20px;
    border-radius: 30px;
  }
  .partners-block .swiper-slide {
    padding: 0 10px;
  }
  .partners-block.big-1 {
    height: 110%;
    width: 100%;
  }
  .partners-block.big-2 {
    height: 120%;
    width: 100%;
  }
  .partners-block.big-3 {
    height: 130%;
    width: 100%;
  }
  .partners-title {
    font-size: 45px;
    line-height: 45px;
  }
}
@media (max-width: 768px) {
  .partners-title {
    font-size: 35px;
    line-height: 45px;
  }
}
.intagrate {
  padding: 106px 0 67px;
  position: relative;
}
.intagrate .wrapper {
  max-width: 1270px;
  z-index: 2;
}
.intagrate .title {
  text-align: center;
  margin-bottom: 63px;
}
.intagrate-content {
  display: flex;
  justify-content: space-between;
}
.intagrate-items {
  max-width: 300px;
  width: 100%;
  margin-right: 30px;
}
.intagrate-items__subtitle {
  display: none;
}
.intagrate-item {
  border: 1px solid rgba(249,212,160,0.4);
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.intagrate-item:hover {
  border-color: #f9d4a0;
}
.intagrate-item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
  border: 1px solid #f9d4a0;
  pointer-events: none;
}
.intagrate-item:last-child {
  margin-bottom: 0;
}
.intagrate-item__icon {
  margin-right: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.intagrate-item__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
}
.intagrate-blocks {
  max-width: 880px;
  width: 100%;
}
.intagrate-block {
  display: none;
  background: #fff;
  border-radius: 20px;
  padding: 45px 60px 65px;
}
.intagrate-block.active {
  display: block;
}
.intagrate-block__title {
  font-weight: 400;
  font-size: 55px;
  line-height: 65px;
  color: #050925;
}
.intagrate-block__title .gold {
  color: #d39f56;
}
.intagrate-block__text {
  margin-top: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: rgba(5,9,37,0.7);
}
.intagrate-block__buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 68px;
}
.intagrate-block__button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #050925;
}
.intagrate-block__button .icon {
  margin-right: 10px;
}
.intagrate-block__button:first-child {
  margin-right: 42px;
}
@media (max-width: 1100px) {
  .intagrate {
    padding-top: 90px;
  }
}
@media (max-width: 998px) {
  .intagrate-block {
    padding: 25px 40px 45px;
  }
  .intagrate-block__title {
    font-size: 35px;
    line-height: 45px;
  }
  .intagrate-block__text {
    margin-top: 20px;
  }
  .intagrate-block__buttons {
    margin-top: 30px;
  }
  .intagrate-block__button {
    font-size: 13px;
    line-height: 16px;
  }
  .intagrate-items {
    max-width: 235px;
  }
  .intagrate-item {
    padding: 20px 30px;
  }
  .intagrate-item__icon {
    max-width: 30px;
    max-height: 30px;
  }
  .intagrate-item__title {
    font-size: 15px;
  }
}
@media (max-width: 998px) and (max-width: 768px) {
  .intagrate {
    padding: 90px 0 40px;
  }
  .intagrate .title {
    font-size: 35px;
    line-height: 45px;
  }
  .intagrate-content {
    flex-direction: column;
  }
  .intagrate-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: none;
    margin-bottom: 30px;
  }
  .intagrate-item {
    width: calc(50% - 5px);
  }
  .intagrate-item:last-child {
    margin-bottom: 10px;
  }
}
@media (max-width: 998px) and (max-width: 480px) {
  .intagrate-items {
    margin-bottom: 30px;
  }
  .intagrate-items__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    display: block;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .intagrate-item {
    width: calc(25% - 2.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 20px;
  }
  .intagrate-item__title {
    display: none;
  }
  .intagrate-item__icon {
    margin-right: 0;
  }
  .intagrate-block__buttons {
    flex-direction: column;
    align-items: flex-start;
  }
  .intagrate-block__button {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 30px;
  }
  .intagrate-block__button:last-child {
    margin-bottom: 0;
  }
}
.faq {
  padding: 63px 0;
  position: relative;
}
.faq .title {
  font-weight: 400;
  font-size: 55px;
  line-height: 65px;
  text-align: center;
  color: #fff;
}
.faq .wrapper {
  max-width: 880px;
  z-index: 2;
}
.faq-items {
  margin-top: 84px;
}
.faq-item {
  margin-bottom: 40px;
}
.faq-item__bottom {
  margin-top: 10px;
  padding-right: 60px;
  max-height: 0;
  transition: all 0.1s ease-in;
  opacity: 0;
}
.faq-item__bottom p {
  padding-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  transition: all 0.2s ease-in;
  color: #f9d4a0;
}
.faq-item.active .faq-item__button {
  opacity: 1;
  transform: rotate(-180deg);
}
.faq-item.active .faq-item__bottom {
  opacity: 1;
}
.faq-item__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  position: relative;
}
.faq-item__top:hover .faq-item__title {
  color: #f9d4a0;
}
.faq-item__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  transition: all 0.3s ease-in;
  padding-right: 40px;
}
.faq-item__button {
  opacity: 0.5;
  transform: rotate(0);
  transition: all 0.3s ease-in;
  margin-left: 20px;
  min-width: 18px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  top: -2px;
}
@media (max-width: 768px) {
  .faq {
    padding: 40px 0;
  }
  .faq .title {
    font-size: 35px;
    line-height: 45px;
  }
  .faq-items {
    margin-top: 45px;
  }
  .faq-item {
    margin-bottom: 30px;
  }
}
.how {
  padding: 91px 0;
  position: relative;
}
.how .title {
  text-align: center;
  font-weight: 400;
  font-size: 55px;
  line-height: 65px;
}
.how .wrapper {
  max-width: 1070px;
  z-index: 2;
}
.how-subtitle {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  margin-top: 60px;
}
.how-text {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f9d4a0;
  margin-top: 15px;
}
.how-items {
  margin-top: 80px;
}
.how-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: max-content;
}
.how-item__num {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 55px;
  line-height: 65px;
  color: #d39f56;
  position: relative;
}
.how-item__num .line {
  position: absolute;
  width: max-content;
  z-index: -1;
}
.how-item__num .line .tablet {
  display: none;
}
.how-item__num .line .mobile {
  display: none;
}
.how-item__content {
  max-width: 238px;
  width: 100%;
  margin-left: 60px;
}
.how-item__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  margin-top: 15px;
}
.how-item__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f9d4a0;
  margin-top: 15px;
}
.how-item__1 {
  margin: 0 auto;
  padding-left: 298px;
}
.how-item__1 .how-item__num {
  margin-top: 45px;
}
.how-item__1 .how-item__num .line {
  top: 57%;
  right: 44%;
}
.how-item__2 {
  margin-top: 112px;
}
.how-item__2 .how-item__num {
  margin-top: -10px;
}
.how-item__2 .how-item__content {
  margin-left: 49px;
}
.how-item__3 {
  margin-left: auto;
  margin-top: -70px;
}
.how-item__3 .how-item__num {
  margin-top: -24px;
}
.how-item__3 .how-item__num .line {
  bottom: 46%;
  right: 50%;
}
.how-button {
  margin-top: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 998px) {
  .how-subtitle {
    margin-top: 43px;
  }
  .how-items {
    margin-top: 18px;
    max-width: 576px;
    width: 100%;
    margin: 0 auto;
  }
  .how-item {
    flex-direction: column;
  }
  .how-item__num {
    margin-bottom: 40px;
  }
  .how-item__num .line .desktop {
    display: none;
  }
  .how-item__num .line .tablet {
    display: block;
  }
  .how-item__content {
    margin-left: 0;
  }
  .how-item__1 {
    margin-right: 0;
    padding-left: 0;
  }
  .how-item__1 .how-item__num {
    margin-top: 60px;
    margin-left: -10px;
  }
  .how-item__1 .how-item__num .line {
    top: 43%;
    right: 99%;
  }
  .how-item__2 {
    margin-top: -100px;
  }
  .how-item__2 .how-item__content {
    margin-left: 10px;
  }
  .how-item__3 {
    margin-top: -138px;
  }
  .how-item__3 .how-item__num {
    margin-left: -10px;
  }
  .how-item__3 .how-item__num .line {
    bottom: 84%;
    right: 84%;
  }
  .how-button {
    max-width: 560px;
    margin: 58px auto 0;
  }
}
@media (max-width: 768px) {
  .how-subtitle {
    margin-top: 22px;
  }
  .how .title {
    font-size: 35px;
    line-height: 45px;
  }
  .how-item {
    margin: 0 auto;
    align-items: center;
  }
  .how-item__num .line .tablet {
    display: none;
  }
  .how-item__num .line .mobile {
    display: block;
  }
  .how-item__content {
    text-align: center;
    max-width: 255px;
  }
  .how-item__icon {
    display: flex;
    justify-content: center;
  }
  .how-item__1 .how-item__num {
    margin-top: 40px;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .how-item__1 .how-item__num .line {
    top: 49%;
  }
  .how-item__2 {
    margin-top: 62px;
  }
  .how-item__2 .how-item__num {
    margin-top: 0;
    margin-bottom: 20px;
  }
  .how-item__2 .how-item__content {
    margin-left: 0;
  }
  .how-item__3 {
    margin-top: 85px;
  }
  .how-item__3 .how-item__num {
    margin-left: 0;
    margin-bottom: 19px;
  }
  .how-item__3 .how-item__num .line {
    bottom: 47%;
    right: -75%;
  }
  .how-button {
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }
}
.integratePage {
  overflow: hidden;
  position: relative;
  background: #050b29;
}
.integratePage:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  min-height: 5000px;
  background-image: url("../img/integrate/back.png");
  background-color: #050b29;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  transition: all 0.3s ease-in;
}
.integratePage main {
  position: relative;
  z-index: 2;
}
.integratePage .title {
  font-size: 55px;
  text-align: center;
  line-height: 65px;
}
.integratePage .integrate {
  padding: 200px 0 68px;
}
.integratePage .integrate .wrapper {
  max-width: 1150px;
}
.integratePage .integrate .content {
  display: flex;
  justify-content: space-between;
}
.integratePage .integrate .title {
  max-width: 567px;
  width: 59%;
  color: #fff;
  text-align: left;
}
.integratePage .integrate .image {
  width: 49%;
  position: relative;
}
.integratePage .integrate .image-block {
  position: absolute;
  width: 116.7%;
  left: 5%;
  top: -1%;
}
.integratePage .integrate .image-block img {
  width: 100%;
}
.integratePage .digital {
  padding: 60px 0;
}
.integratePage .digital .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #f9d4a1;
  text-align: center;
  max-width: 800px;
  width: 100%;
  margin: 43px auto 0;
}
.integratePage .digital .list {
  column-count: 3;
  column-gap: 5%;
  max-width: 954px;
  width: 100%;
  margin: 60px auto 0;
}
.integratePage .digital .item {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #fff;
  margin-bottom: 25px;
  padding-left: 30px;
  position: relative;
}
.integratePage .digital .item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  background-image: url("../img/integrate/check.svg");
}
.integratePage .dashboard {
  padding: 127px 0;
}
.integratePage .dashboard .blocks {
  display: flex;
  justify-content: space-between;
  padding-left: 6%;
}
.integratePage .dashboard .block {
  position: relative;
}
.integratePage .dashboard .block-text {
  position: absolute;
  background: linear-gradient(2.49deg, #56424d 23.49%, #4e6cc3 149.61%);
  border-radius: 25px;
  padding: 30px;
  width: max-content;
}
.integratePage .dashboard .block-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #f9d4a1;
}
.integratePage .dashboard .block-text.mobile {
  display: none;
}
.integratePage .dashboard .block-1 {
  width: 20%;
  margin-top: 10%;
}
.integratePage .dashboard .block-1 .block-image {
  max-width: 260px;
  width: 112%;
  overflow: hidden;
}
.integratePage .dashboard .block-1 .block-text {
  left: -24%;
  top: -31%;
}
.integratePage .dashboard .block-2 {
  width: 40%;
  left: -2.8%;
  z-index: 2;
}
.integratePage .dashboard .block-2 .block-image {
  max-width: 565px;
  width: 122%;
  box-shadow: 57px 77px 88px rgba(5,5,13,0.371);
  border-radius: 25px;
}
.integratePage .dashboard .block-2 .block-text {
  bottom: -7%;
  left: -2%;
}
.integratePage .dashboard .block-3 {
  width: 40%;
  left: -10.2%;
  margin-top: 10.1%;
}
.integratePage .dashboard .block-3 .block-image {
  max-width: 565px;
  width: 122%;
}
.integratePage .dashboard .block-3 .block-text {
  top: -32%;
  right: 15%;
}
.integratePage .features {
  padding: 72px 0;
}
.integratePage .features .wrapper {
  max-width: 1070px;
}
.integratePage .features .title {
  max-width: 716px;
  width: 100%;
  margin: 0 auto;
}
.integratePage .features .blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 81px -20px 0;
}
.integratePage .features .block {
  width: calc(33.333% - 40px);
  margin: 0 20px 60px;
}
.integratePage .features .block-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.integratePage .features .block-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}
.integratePage .features .block-text {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #f9d4a0;
}
.integratePage .baas {
  padding: 25px 0;
  min-height: 713px;
}
.integratePage .baas .el {
  position: absolute;
}
.integratePage .baas .el1 {
  right: 18.5%;
  top: 104px;
}
.integratePage .baas .el2 {
  right: 4.5%;
  top: 278px;
}
.integratePage .baas .el3 {
  right: 21.4%;
  bottom: -87px;
}
.integratePage .baas .content {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  padding-right: 140px;
}
.integratePage .baas .subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #f9d4a1;
  margin-top: 26px;
}
.integratePage .baas .items {
  max-width: 300px;
  width: 100%;
  margin-right: 60px;
}
.integratePage .baas .items-subtitle {
  display: none;
}
.integratePage .baas .item {
  border: 1px solid rgba(249,212,160,0.4);
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.integratePage .baas .item:hover {
  border-color: #f9d4a0;
}
.integratePage .baas .item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
  border: 1px solid #f9d4a0;
  pointer-events: none;
}
.integratePage .baas .item:last-child {
  margin-bottom: 0;
}
.integratePage .baas .item-icon {
  margin-right: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.integratePage .baas .item-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
}
.integratePage .baas .blocks {
  max-width: 880px;
  width: 100%;
}
.integratePage .baas .block {
  display: none;
}
.integratePage .baas .block.active {
  display: block;
}
.integratePage .baas .block-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #f9d4a1;
  max-width: 440px;
  width: 100%;
}
.integratePage .baas .block-list {
  margin-top: 40px;
}
.integratePage .baas .block-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #fff;
  margin-bottom: 25px;
  padding-left: 30px;
  position: relative;
}
.integratePage .baas .block-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  background-image: url("../img/integrate/check.svg");
}
@media (max-width: 1250px) {
  .integratePage .integrate .image-block {
    left: 0;
    width: 109.7%;
  }
}
@media (max-width: 998px) {
  .integratePage .integrate {
    padding-bottom: 0;
  }
  .integratePage .integrate .content {
    flex-direction: column;
  }
  .integratePage .integrate .title {
    width: 100%;
  }
  .integratePage .integrate .image {
    width: 100%;
    max-width: 700px;
    margin: 40px auto 0;
  }
  .integratePage .integrate .image-block {
    position: relative;
    width: 100%;
    margin-bottom: -10%;
  }
  .integratePage .features .block {
    width: calc(50% - 40px);
  }
  .integratePage .features .block-title br {
    display: none;
  }
  .integratePage .baas .el {
    transform: scale(0.7);
  }
  .integratePage .baas .el1 {
    top: 60px;
    right: 5%;
  }
  .integratePage .baas .el2 {
    display: none;
  }
  .integratePage .baas .content {
    padding-right: 0;
  }
  .integratePage .baas .items {
    margin-right: 30px;
  }
  .integratePage .dashboard .blocks {
    padding-left: 20px;
    padding-right: 25px;
    flex-wrap: wrap;
    max-width: 500px;
    margin: 0 auto;
  }
  .integratePage .dashboard .block-text.desktop {
    display: none;
  }
  .integratePage .dashboard .block-text.mobile {
    display: block;
  }
  .integratePage .dashboard .block-1 {
    order: 2;
    width: 40%;
    z-index: 2;
    margin-top: -22%;
    left: -5%;
  }
  .integratePage .dashboard .block-1 .block-image {
    width: 104.2%;
  }
  .integratePage .dashboard .block-1 .block-text {
    top: 85%;
    left: -8%;
  }
  .integratePage .dashboard .block-2 {
    width: 100%;
    left: 0;
    padding-top: 106px;
    z-index: 1;
  }
  .integratePage .dashboard .block-2 .block-image {
    width: 100%;
  }
  .integratePage .dashboard .block-2 .block-text {
    top: 0;
    bottom: auto;
    left: -25px;
  }
  .integratePage .dashboard .block-2 .block-text.mobile {
    left: auto;
    right: -10%;
    top: auto;
    bottom: -6%;
  }
  .integratePage .dashboard .block-3 {
    width: 50%;
    order: 3;
    margin-top: -5%;
  }
  .integratePage .dashboard .block-3 .block-image {
    width: 160%;
  }
}
@media (max-width: 768px) {
  .integratePage .title {
    font-size: 30px;
    line-height: 40px;
  }
  .integratePage .integrate {
    padding-top: 100px;
  }
  .integratePage .integrate .title {
    max-width: 370px;
  }
  .integratePage .integrate .image {
    max-width: 500px;
  }
  .integratePage .digital .list {
    column-count: 2;
  }
  .integratePage .features {
    padding-bottom: 0;
  }
  .integratePage .features .blocks {
    flex-direction: column;
    margin: 60px 0 0;
  }
  .integratePage .features .block {
    width: 100%;
    margin: 0 auto 60px;
    max-width: 320px;
  }
  .integratePage .baas {
    min-height: 0;
    padding: 25px 0 80px;
  }
  .integratePage .baas .content {
    flex-direction: column;
    margin-top: 60px;
  }
  .integratePage .baas .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    margin: 0 auto 40px;
  }
  .integratePage .baas .items-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    display: block;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .integratePage .baas .item {
    width: calc(33.333% - 2.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 20px;
    margin-bottom: 0;
  }
  .integratePage .baas .item-title {
    display: none;
  }
  .integratePage .baas .item-icon {
    margin-right: 0;
  }
  .integratePage .baas .block-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
  .integratePage .baas .block-button {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 30px;
  }
  .integratePage .baas .block-button:last-child {
    margin-bottom: 0;
  }
  .integratePage .baas .el {
    display: none;
  }
  .integratePage .dashboard {
    padding: 40px 0 80px;
  }
  .integratePage .dashboard .blocks {
    max-width: 345px;
  }
}
@media (max-width: 480px) {
  .integratePage .digital .list {
    column-count: 1;
    column-gap: 0%;
  }
}
.useCasePage {
  overflow: hidden;
  position: relative;
  background: #050b29;
}
.useCasePage:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/integrate/back.png");
  background-color: #050b29;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  transition: all 0.3s ease-in;
}
.useCasePage main {
  position: relative;
  z-index: 2;
}
.useCasePage .title {
  font-size: 55px;
  text-align: center;
  line-height: 65px;
}
.useCasePage .useCase {
  height: 100vh;
}
.useCasePage .useCase-swiper {
  width: 100%;
  height: 100%;
}
.useCasePage .useCase .subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #f9d4a0;
  margin-top: 20px;
}
.useCasePage .useCase .blocks {
  margin-top: 100px;
}
.useCasePage .useCase .swiper-slide {
  pointer-events: none;
}
.useCasePage .useCase .swiper-slide .wrapper {
  opacity: 0 !important;
  transition: all 1s linear;
  height: 100%;
}
.useCasePage .useCase .swiper-slide.variant-1 .block-withoutWhite {
  opacity: 0;
  transition: all 1s linear;
}
.useCasePage .useCase .swiper-slide.swiper-slide-prev .wrapper {
  transform: translateY(-100%);
  opacity: 0 !important;
}
.useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1 .wrapper {
  transform: translateY(0%);
  opacity: 1 !important;
}
.useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1 .block-info {
  max-width: 640px;
}
.useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2 .block-info {
  max-width: 640px;
}
.useCasePage .useCase .swiper-slide.swiper-slide-next .wrapper {
  transform: translateY(100%);
  opacity: 0 !important;
}
.useCasePage .useCase .swiper-slide.swiper-slide-next.variant-2 .wrapper {
  transform: translateY(0%);
}
.useCasePage .useCase .swiper-slide.swiper-slide-active {
  pointer-events: all;
}
.useCasePage .useCase .swiper-slide.swiper-slide-active .wrapper {
  transform: translateY(0%);
  opacity: 1 !important;
}
.useCasePage .useCase .swiper-slide.swiper-slide-active.variant-1 .block-withoutWhite {
  opacity: 1;
}
.useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2 .block-info {
  max-width: 640px;
}
.useCasePage .useCase .swiper-slide.go .wrapper {
  transform: translateY(-100%);
  opacity: 0 !important;
}
.useCasePage .useCase .swiper-slide.go .block-info {
  max-width: 640px;
}
.useCasePage .useCase.withTitle .block .title,
.useCasePage .useCase.withTitle .block .subtitle {
  display: block;
}
.useCasePage .useCase .block .wrapper {
  padding-top: 17vh;
  padding-top: 20vh;
  max-width: 1230px;
  display: flex;
  flex-direction: column;
}
.useCasePage .useCase .block .title,
.useCasePage .useCase .block .subtitle {
  display: none;
}
.useCasePage .useCase .block-mobile .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.useCasePage .useCase .block-title {
  font-weight: 400;
  font-size: 35px;
  line-height: 35px;
  color: #fff;
  margin-bottom: 39px;
}
.useCasePage .useCase .block-title.mobile {
  display: none;
}
.useCasePage .useCase .block-content {
  display: flex;
  justify-content: space-between;
  max-width: 1080px;
  width: 100%;
  padding-bottom: 30px;
  margin-top: 10vh;
}
.useCasePage .useCase .block-info {
  max-width: 560px;
  width: 100%;
  transition: all 0.5s ease-in;
}
.useCasePage .useCase .block-img {
  width: 300px;
  min-width: 300px;
  height: 300px;
}
.useCasePage .useCase .block-text {
  width: 100%;
}
.useCasePage .useCase .block-withoutWhite {
  max-width: 560px;
  width: 100%;
}
.useCasePage .useCase .block.right {
  margin-right: auto;
}
.useCasePage .useCase .block.right .block-info {
  margin-right: 20px;
}
.useCasePage .useCase .block.left {
  margin-left: auto;
}
.useCasePage .useCase .block.left .block-info {
  margin-left: 20px;
}
.useCasePage .useCase .block-white {
  background: #fff;
  border-radius: 20px;
  padding: 40px 40px 50px;
  display: flex;
  justify-content: flex-start;
  margin-top: 44px;
}
.useCasePage .useCase .block-white__icon {
  max-width: 84px;
  width: 100%;
  margin-right: 40px;
  min-width: 84px;
}
.useCasePage .useCase .block-white__title {
  font-size: 20px;
  line-height: 34px;
  color: #d39f56;
  margin-top: 9px;
  margin-bottom: 19px;
}
.useCasePage .useCase .block-white__subtitle {
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  color: #050925;
  margin-bottom: 21px;
}
.useCasePage .useCase .block-white__text {
  font-size: 14px;
  line-height: 28px;
  color: rgba(5,9,37,0.7);
}
.useCasePage .useCase .block-white__info {
  max-width: 396px;
  width: 100%;
}
.useCasePage .useCase .block-white__slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.useCasePage .useCase .block-white .swiper-pagination {
  position: static;
  display: flex;
  margin-top: 31px;
}
.useCasePage .useCase .block-white .swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  padding: 0;
  z-index: 1;
}
.useCasePage .useCase .block-white .swiper-wrapper {
  z-index: 1;
  display: flex;
  box-sizing: content-box;
}
.useCasePage .useCase .variant-1 .block-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #f9d4a1;
}
.useCasePage .useCase .variant-1 .block-list {
  max-width: 418px;
  width: 100%;
  column-count: 2;
  column-gap: 5%;
  margin-top: 40px;
}
.useCasePage .useCase .variant-1 .block-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #fff;
  margin-bottom: 30px;
  padding-left: 28px;
  position: relative;
  transition: all 0.3s ease-in;
}
.useCasePage .useCase .variant-1 .block-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  background-image: url("../img/integrate/check.svg");
  transition: all 0.3s ease-in;
}
.useCasePage .useCase .variant-1 .block-img {
  background: linear-gradient(0.56deg, #1b1317 -26.8%, #283f81 97.42%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.useCasePage .useCase .variant-1 .block-img:after {
  content: "";
  position: absolute;
  width: 54%;
  height: 54%;
  border-radius: 50%;
  background: linear-gradient(0deg, #1b1317 -6.06%, #071848 109.73%);
  mix-blend-mode: normal;
  opacity: 0.2;
}
.useCasePage .useCase .variant-1 .block-img img {
  position: relative;
  z-index: 2;
}
.useCasePage .useCase .variant-2 .block-title {
  opacity: 0;
}
.useCasePage .main {
  padding: 0px 0 68px;
}
.useCasePage .main .wrapper {
  max-width: 1165px;
  padding-top: 20vh;
}
.useCasePage .main-content {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}
.useCasePage .main-image {
  width: 49%;
  position: relative;
}
.useCasePage .main-image__block {
  position: absolute;
  width: 130.7%;
  right: -3%;
  top: 0%;
}
.useCasePage .main-image__block img {
  width: 100%;
}
.useCasePage .main-info {
  max-width: 550px;
  width: 59%;
}
.useCasePage .main-title {
  color: #fff;
  text-align: left;
  margin: 0;
}
.useCasePage .main-subtitle {
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  color: #f9d4a1;
  margin-top: 35px;
}
.useCasePage .swiper-pagination-bullet {
  border: 1px solid rgba(5,9,37,0.3);
  background: none;
  opacity: 1;
  transition: all 0.3s ease-in;
  width: 5px;
  height: 5px;
  margin-right: 15px !important;
  margin-left: 0 !important;
  border-radius: 50%;
}
.useCasePage .swiper-pagination-bullet:hover {
  border-color: #050925;
}
.useCasePage .swiper-pagination-bullet-active {
  background: #050925;
}
@media (max-height: 800px) {
  .useCasePage .useCase .block-content {
    margin-top: 50px;
  }
}
@media (max-height: 760px) and (min-width: 999px) {
  .useCasePage .main .wrapper {
    padding-top: 18.5vh;
    display: flex;
    align-items: center;
  }
  .useCasePage .main-content {
    width: 100%;
  }
  .useCasePage .main-image__block {
    top: 70%;
    transform: translateY(-50%);
  }
  .useCasePage .useCase .title {
    font-size: 40px;
    line-height: 45px;
  }
  .useCasePage .useCase .subtitle {
    margin-top: 10px;
  }
  .useCasePage .useCase .block-content {
    margin-top: 50px;
  }
  .useCasePage .useCase .block-withoutWhite {
    max-width: 700px;
  }
  .useCasePage .useCase .block .wrapper {
    padding-top: 18.5vh;
  }
  .useCasePage .useCase .block-title {
    font-size: 25px;
    line-height: 25px;
  }
  .useCasePage .useCase .block-img {
    width: 200px;
    min-width: 200px;
    height: 200px;
  }
  .useCasePage .useCase .block-img img {
    width: 30%;
  }
  .useCasePage .useCase .block-content {
    margin-top: 30px;
  }
  .useCasePage .useCase .block-info {
    max-width: 700px;
  }
  .useCasePage .useCase .block-title {
    margin-bottom: 20px;
  }
  .useCasePage .useCase .block-white {
    margin-top: 20px;
    padding: 15px;
  }
  .useCasePage .useCase .block-white__icon {
    margin: 20px 20px 0 5px;
    min-width: 60px;
    max-width: 60px;
  }
  .useCasePage .useCase .block-white__info {
    max-width: calc(100% - 104px);
  }
  .useCasePage .useCase .block-white__title {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .useCasePage .useCase .block-white__subtitle {
    font-size: 20px;
    line-height: 25px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2 .block-info {
    max-width: 780px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1 .block-info {
    max-width: 780px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2 .block-info {
    max-width: 780px;
  }
  .useCasePage .useCase .swiper-slide.go .block-info {
    max-width: 780px;
  }
}
@media (max-height: 800px) and (max-width: 1100px) {
  .useCasePage .main .wrapper {
    padding-top: 18.5vh;
  }
  .useCasePage .useCase .subtitle {
    margin-top: 10px;
  }
  .useCasePage .useCase .block-content {
    margin-top: 50px;
    max-width: 900px;
  }
  .useCasePage .useCase .block-img {
    width: 200px;
    min-width: 200px;
    height: 200px;
  }
  .useCasePage .useCase .block-img img {
    width: 30%;
  }
  .useCasePage .useCase .block .wrapper {
    padding-top: 18.5vh;
  }
}
@media (max-height: 700px) and (max-width: 1100px) {
  .useCasePage .useCase .title {
    font-size: 40px;
    line-height: 45px;
  }
  .useCasePage .useCase .subtitle {
    margin-top: 10px;
  }
  .useCasePage .useCase .block-img {
    transition: all 0.5s ease-in;
  }
  .useCasePage .useCase .block-white {
    margin-top: 20px;
    padding: 15px;
  }
  .useCasePage .useCase .block-white__icon {
    margin: 20px 20px 0 5px;
    min-width: 60px;
    max-width: 60px;
  }
  .useCasePage .useCase .block-white__info {
    max-width: calc(100% - 104px);
  }
  .useCasePage .useCase .block-white__title {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .useCasePage .useCase .block-white__subtitle {
    font-size: 20px;
    line-height: 25px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1 .block-info,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2 .block-info,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2 .block-info,
  .useCasePage .useCase .swiper-slide.go .block-info {
    max-width: 760px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1 .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2 .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2 .block-img,
  .useCasePage .useCase .swiper-slide.go .block-img {
    opacity: 0;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.go.right .block-img {
    margin-right: -200px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.left .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2.left .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2.left .block-img,
  .useCasePage .useCase .swiper-slide.go.left .block-img {
    margin-left: -200px;
  }
}
@media (max-width: 1100px) {
  .useCasePage .main-image {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  .useCasePage .main-image__block {
    width: 100%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 998px) {
  .useCasePage .useCase .main-content {
    flex-direction: column;
    padding-right: 0;
  }
  .useCasePage .useCase .main-image {
    display: flex;
    justify-content: center;
    margin: 30px auto 0;
    order: 2;
    max-height: 40vh;
  }
  .useCasePage .useCase .main-image .main-image__block {
    height: 100%;
    width: auto;
  }
  .useCasePage .useCase .main-image__block {
    position: static;
    transform: none;
    display: flex;
    justify-content: center;
  }
  .useCasePage .useCase .title {
    font-size: 40px;
    line-height: 45px;
  }
  .useCasePage .useCase .subtitle {
    margin-top: 10px;
  }
  .useCasePage .useCase .block-img {
    transition: all 0.5s ease-in;
    min-width: 200px;
    width: 200px;
    height: 200px;
  }
  .useCasePage .useCase .block-img img {
    width: 30%;
  }
  .useCasePage .useCase .block-content {
    max-width: 900px;
  }
  .useCasePage .useCase .block-white {
    margin-top: 20px;
    padding: 15px;
  }
  .useCasePage .useCase .block-white__icon {
    margin: 20px 20px 0 5px;
    min-width: 60px;
    max-width: 60px;
  }
  .useCasePage .useCase .block-white__info {
    max-width: calc(100% - 104px);
  }
  .useCasePage .useCase .block-white__title {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .useCasePage .useCase .block-white__subtitle {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 998px) and (min-width: 769px) {
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1 .block-info,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2 .block-info,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2 .block-info,
  .useCasePage .useCase .swiper-slide.go .block-info {
    max-width: 760px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1 .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2 .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2 .block-img,
  .useCasePage .useCase .swiper-slide.go .block-img {
    opacity: 0;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.go.right .block-img {
    margin-right: -200px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.left .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2.left .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2.left .block-img,
  .useCasePage .useCase .swiper-slide.go.left .block-img {
    margin-left: -200px;
  }
  .useCasePage .useCase .block.right.variant-1 .block-info {
    padding-right: 20px;
  }
  .useCasePage .useCase .block.left.variant-1 .block-info {
    padding-left: 20px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.left .block-info {
    padding-left: 0;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.right .block-info {
    padding-right: 0;
  }
}
@media (max-width: 768px) and (min-height: 601px) {
  .useCasePage .useCase .block .wrapper {
    padding-top: 100px;
    text-align: center;
  }
  .useCasePage .useCase .block-content {
    flex-direction: column;
    max-width: 560px;
    width: 100%;
    margin: 0px auto 0;
  }
  .useCasePage .useCase .block-info {
    max-width: none;
    order: 2;
  }
  .useCasePage .useCase .block-img {
    order: 1;
    margin: 0 auto;
  }
  .useCasePage .useCase .block-title {
    display: none;
    font-size: 25px;
    line-height: 30px;
    max-width: 560px;
    width: 100%;
    margin: 0px auto 20px;
  }
  .useCasePage .useCase .block-title.mobile {
    display: block;
  }
  .useCasePage .useCase .block-withoutWhite {
    margin: 20px auto 0;
  }
  .useCasePage .useCase .block.variant-1 .block-list {
    margin: 20px auto 0;
  }
  .useCasePage .useCase .block.variant-2 .block-img {
    display: none;
  }
  .useCasePage .useCase .block.left .block-info {
    padding-left: 0;
    margin-left: auto;
  }
  .useCasePage .useCase .block.right .block-info {
    padding-right: 0;
    margin-right: auto;
  }
  .useCasePage .useCase .block-white {
    flex-direction: column;
    padding: 25px;
    margin-top: 0;
  }
  .useCasePage .useCase .block-white__icon {
    margin: 0 auto;
  }
  .useCasePage .useCase .block-white__info {
    max-width: 100%;
  }
  .useCasePage .useCase .block-white .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1 .block-img,
  .useCasePage .useCase .swiper-slide.go .block-img {
    opacity: 0;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.go.right .block-img {
    margin-right: auto;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.left .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2.left .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2.left .block-img,
  .useCasePage .useCase .swiper-slide.go.left .block-img {
    margin-left: auto;
  }
}
@media (max-width: 768px) and (min-height: 701px) {
  .useCasePage .main {
    padding-bottom: 20px;
  }
  .useCasePage .main-info {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-height: 700px) {
  .useCasePage .main .wrapper {
    display: flex;
  }
  .useCasePage .main-info {
    width: 100%;
    max-width: 600px;
  }
  .useCasePage .main-content {
    width: 100%;
    align-items: center;
  }
  .useCasePage .main-image {
    max-height: 60vh;
  }
  .useCasePage .main-image__block {
    transform: translateY(-44%);
  }
}
@media (max-width: 998px) {
  .useCasePage .main-info {
    max-width: 700px;
  }
}
@media (max-width: 768px) {
  .useCasePage:after {
    width: 200%;
  }
  .useCasePage .block .title,
  .useCasePage .block .subtitle {
    display: none;
  }
  .useCasePage .block-content {
    transition: all 1s ease-in;
  }
  .useCasePage .block.variant-2 .block-img {
    display: none;
  }
  .useCasePage .block.variant-2.left .block-info {
    margin-left: 0;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1 .block-content {
    transform: translateX(-100%);
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-next.variant-2 .block-content {
    transform: translateX(100%);
  }
  .useCasePage .main .wrapper {
    padding-top: 100px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.go.right .block-img {
    opacity: 0;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2.right .block-img,
  .useCasePage .useCase .swiper-slide.go.right .block-img {
    margin-right: auto;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.left .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-2.left .block-img,
  .useCasePage .useCase .swiper-slide.swiper-slide-active.variant-2.left .block-img,
  .useCasePage .useCase .swiper-slide.go.left .block-img {
    margin-left: auto;
  }
}
@media (max-width: 480px) {
  .useCasePage .useCase .variant-1 .block-list {
    column-count: 1;
    column-gap: 0%;
  }
}
@media (max-width: 480px) and (min-height: 601px) {
  .useCasePage .useCase .variant-1 .block-list {
    display: flex;
    flex-direction: column;
  }
  .useCasePage .useCase .variant-1 .block-item {
    display: inline;
    margin: 0 auto 20px;
    padding-right: 20px;
  }
}
@media (max-height: 768px) and (max-width: 768px) {
  .useCasePage .block.variant-1 .title,
  .useCasePage .block.variant-1 .subtitle {
    display: none;
  }
  .useCasePage .useCase .block-img {
    min-width: 150px;
    width: 150px;
    height: 150px;
  }
  .useCasePage .useCase .variant-1 .block-list {
    margin-top: 20px;
  }
  .useCasePage .useCase .variant-1 .block-item {
    margin-bottom: 20px;
  }
  .useCasePage .useCase .title {
    font-size: 30px;
    line-height: 35px;
  }
  .useCasePage .useCase .main-subtitle {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-height: 600px) {
  .useCasePage .main-content {
    padding-right: 0;
  }
  .useCasePage .main .wrapper {
    padding-top: 100px;
  }
  .useCasePage .main-image__block {
    max-height: 66vh;
    display: flex;
    justify-content: center;
  }
  .useCasePage .useCase .title {
    font-size: 30px;
    line-height: 35px;
  }
  .useCasePage .useCase .main-subtitle {
    margin-top: 20px;
    font-size: 20px;
    line-height: 25px;
  }
  .useCasePage .useCase .block .wrapper {
    padding-top: 100px;
  }
  .useCasePage .useCase .block-white {
    margin-top: 0;
    padding: 25px;
  }
  .useCasePage .useCase .block-content {
    margin-top: 0;
  }
}
@media (max-width: 768px) and (min-height: 501px) {
  .useCasePage .useCase .block-title {
    display: none;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .useCasePage .useCase .block-title.mobile {
    display: block;
    width: 100%;
  }
}
@media (max-height: 500px) {
  .useCasePage .useCase .block-title {
    display: none;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }
  .useCasePage .useCase .block-title.mobile {
    display: block;
    width: 100%;
  }
  .useCasePage .useCase .block .title {
    display: none;
  }
  .useCasePage .useCase .block .subtitle {
    display: none;
  }
  .useCasePage .useCase .block .wrapper {
    padding-top: 100px;
  }
  .useCasePage .useCase .block-content {
    max-width: none;
    width: 100%;
    margin: 0px auto 0;
  }
  .useCasePage .useCase .block-info {
    max-width: 100%;
    width: 100%;
  }
  .useCasePage .useCase .block-withoutWhite {
    margin: 10px auto 0;
    max-width: none;
  }
  .useCasePage .useCase .block.variant-2 .block-img {
    display: none;
    opacity: 0 !important;
  }
  .useCasePage .useCase .block-white__icon {
    margin: 0 10px 0 -10px;
  }
  .useCasePage .useCase .block-white__info {
    max-width: calc(100% - 60px);
  }
  .useCasePage .useCase .block-white__title {
    margin-top: 0;
  }
  .useCasePage .useCase .block-white__text {
    font-size: 12px;
    line-height: 20px;
  }
  .useCasePage .useCase .block-white__subtitle {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 22px;
  }
  .useCasePage .useCase .block-white .swiper-pagination {
    margin-top: 15px;
  }
  .useCasePage .useCase .block.right.variant-1 .block-info {
    padding-right: 20px;
  }
  .useCasePage .useCase .block.left.variant-1 .block-info {
    padding-left: 20px;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.left .block-info {
    padding-left: 0;
  }
  .useCasePage .useCase .swiper-slide.swiper-slide-prev.variant-1.right .block-info {
    padding-right: 0;
  }
  .useCasePage .swiper-slide.swiper-slide-prev.variant-1 .block-info,
  .useCasePage .swiper-slide.swiper-slide-prev.variant-2 .block-info,
  .useCasePage .swiper-slide.swiper-slide-active.variant-2 .block-info,
  .useCasePage .swiper-slide.go .block-info {
    max-width: 100%;
    width: 100%;
  }
  .useCasePage .swiper-slide.swiper-slide-prev.variant-1 .block-img,
  .useCasePage .swiper-slide.go .block-img {
    opacity: 0;
  }
  .useCasePage .swiper-slide.swiper-slide-prev.variant-2 .block-img,
  .useCasePage .swiper-slide.swiper-slide-active.variant-2 .block-img,
  .useCasePage.right .block-img {
    margin-right: 0;
  }
  .useCasePage.left .block-img {
    margin-left: 0;
  }
}
@media (max-height: 400px) {
  .useCase .block-img {
    width: 150px;
    min-width: 150px;
    height: 150px;
  }
}
.cardServicePage {
  overflow: hidden;
  position: relative;
  background: #050b29;
}
.cardServicePage:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  min-height: 5000px;
  background-image: url("../img/integrate/back.png");
  background-color: #050b29;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  transition: all 0.3s ease-in;
}
.cardServicePage main {
  position: relative;
  z-index: 2;
}
.cardServicePage .title {
  font-size: 55px;
  text-align: center;
  line-height: 65px;
}
.cardServicePage .cardService {
  padding: 195px 0 68px;
}
.cardServicePage .cardService .wrapper {
  max-width: 1150px;
}
.cardServicePage .cardService .content {
  display: flex;
  justify-content: space-between;
}
.cardServicePage .cardService .title {
  max-width: 567px;
  width: 59%;
  text-align: left;
}
.cardServicePage .cardService .image {
  width: 49%;
  max-width: 516px;
  position: relative;
}
.cardServicePage .cardService .image-block {
  position: absolute;
  top: -3%;
  right: -8%;
}
.cardServicePage .cardService .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  margin: 186px 0px 0;
}
.cardServicePage .cardService .item {
  width: calc(50% - 80px);
  margin: 0 40px 62px;
  text-align: center;
}
.cardServicePage .cardService .item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 23px;
}
.cardServicePage .cardService .item-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 20px;
}
.cardServicePage .cardService .item-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #f9d4a0;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
}
.cardServicePage .dashboard {
  padding: 31px 0;
}
.cardServicePage .dashboard .blocks {
  max-width: 1035px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 8.2%;
  padding-bottom: 17%;
}
.cardServicePage .dashboard .block {
  overflow: hidden;
}
.cardServicePage .dashboard .block-1 {
  max-width: 565px;
  width: 55%;
  margin: 0 auto;
  box-shadow: 57px 77px 88px rgba(5,5,13,0.371);
  border-radius: 25px;
}
.cardServicePage .dashboard .block-2 {
  max-width: 208px;
  width: 21%;
  top: 11%;
  left: 12.5%;
  position: absolute;
  box-shadow: 12px 18px 40px rgba(43,8,60,0.617);
  border-radius: 20px;
}
.cardServicePage .dashboard .block-3 {
  max-width: 208px;
  width: 21%;
  top: 46.2%;
  left: 64.9%;
  position: absolute;
  box-shadow: 12px 18px 40px rgba(43,8,60,0.617);
  border-radius: 20px;
}
.cardServicePage .dashboard .block-4 {
  max-width: 234px;
  width: 23%;
  top: 2.2%;
  left: 0;
  position: absolute;
  box-shadow: 0px 18px 40px rgba(49,18,64,0.958);
  border-radius: 10.4px;
}
.cardServicePage .dashboard .block-5 {
  max-width: 234px;
  width: 23%;
  top: 3.5%;
  left: 67.3%;
  position: absolute;
  box-shadow: 20px 18px 40px rgba(49,18,64,0.682);
  border-radius: 10.4px;
}
.cardServicePage .dashboard .block-6 {
  max-width: 234px;
  width: 23%;
  position: absolute;
  top: 0;
  left: 65.7%;
  box-shadow: 20px 18px 40px rgba(49,18,64,0.958);
  border-radius: 10.4px;
}
.cardServicePage .dashboard .block-7 {
  position: absolute;
  top: 26.2%;
  left: -10%;
}
.cardServicePage .dashboard .block-8 {
  position: absolute;
  left: 42.3%;
  top: 75.6%;
}
.cardServicePage .dashboard .block-9 {
  position: absolute;
  right: 0%;
  top: 27.5%;
}
.cardServicePage .dashboard .block-text {
  background: linear-gradient(2.49deg, #56424d 23.49%, #4e6cc3 149.61%);
  border-radius: 25px;
  padding: 30px;
  max-width: 204px;
  width: 100%;
}
.cardServicePage .dashboard .block-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #f9d4a1;
}
.cardServicePage .solution {
  padding: 92px 0;
}
.cardServicePage .solution .wrapper {
  max-width: 1070px;
}
.cardServicePage .solution .title {
  max-width: 716px;
  width: 100%;
  margin: 0 auto;
}
.cardServicePage .solution .blocks {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -40px;
  margin-top: 84px;
}
.cardServicePage .solution .block {
  width: calc(33.333% - 40px);
  margin-right: 40px;
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
  border-radius: 30px;
  padding: 35px 30px 40px;
  margin-bottom: 40px;
}
.cardServicePage .solution .block-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.cardServicePage .solution .block-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
}
.cardServicePage .solution .block-title.height {
  margin-bottom: 20px;
}
.cardServicePage .solution .block-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #f9d4a0;
}
.cardServicePage .discover {
  padding: 25px 0;
}
.cardServicePage .discover .title {
  max-width: 716px;
  width: 100%;
  margin: 0 auto;
}
.cardServicePage .discover .wrapper {
  max-width: 1070px;
}
.cardServicePage .discover .blocks {
  display: flex;
  flex-wrap: wrap;
  margin-right: -40px;
  margin-top: 84px;
}
.cardServicePage .discover .block {
  width: calc(33.333% - 40px);
  margin-right: 40px;
  background: #fff;
  border-radius: 30px;
  padding: 30px 30px 40px;
  margin-bottom: 40px;
}
.cardServicePage .discover .block-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.cardServicePage .discover .block-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #d39f56;
  margin-bottom: 20px;
}
.cardServicePage .discover .block-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #050925;
}
.cardServicePage .useCase {
  padding: 14px 0;
  min-height: 722px;
}
.cardServicePage .useCase .title {
  text-align: left;
  margin-bottom: 63px;
  margin-top: -4px;
}
.cardServicePage .useCase .mobile {
  display: none;
}
.cardServicePage .useCase .el {
  position: absolute;
}
.cardServicePage .useCase .el1 {
  right: 8.5%;
  top: 85px;
}
.cardServicePage .useCase .el2 {
  right: 42.5%;
  bottom: 73px;
}
.cardServicePage .useCase .el3 {
  right: 18.4%;
  bottom: -28px;
}
.cardServicePage .useCase .content {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  padding-right: 140px;
}
.cardServicePage .useCase .subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #f9d4a1;
  margin-top: 26px;
}
.cardServicePage .useCase .items {
  max-width: 300px;
  width: 100%;
  margin-right: 60px;
}
.cardServicePage .useCase .items-subtitle {
  display: none;
}
.cardServicePage .useCase .item {
  border: 1px solid rgba(249,212,160,0.4);
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.cardServicePage .useCase .item:hover {
  border-color: #f9d4a0;
}
.cardServicePage .useCase .item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
  border: 1px solid #f9d4a0;
  pointer-events: none;
}
.cardServicePage .useCase .item:last-child {
  margin-bottom: 0;
}
.cardServicePage .useCase .item-icon {
  margin-right: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardServicePage .useCase .item-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
}
.cardServicePage .useCase .blocks {
  max-width: 630px;
  width: 100%;
  margin-right: auto;
}
.cardServicePage .useCase .block {
  display: none;
}
.cardServicePage .useCase .block.active {
  display: block;
}
.cardServicePage .useCase .block-title {
  font-weight: 400;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 25px;
}
.cardServicePage .useCase .block-list {
  margin-top: 40px;
}
.cardServicePage .useCase .block-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #fff;
  margin-bottom: 25px;
  padding-left: 30px;
  position: relative;
}
.cardServicePage .useCase .block-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  background-image: url("../img/integrate/check.svg");
}
.cardServicePage .useCase .block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: #f0cc9b;
}
@media (max-width: 1260px) {
  .cardServicePage .dashboard .block-7 {
    left: 0;
    top: 35%;
  }
}
@media (max-width: 998px) {
  .cardServicePage .dashboard .block-text {
    transform: scale(0.8);
  }
  .cardServicePage .cardService .content {
    flex-direction: column;
  }
  .cardServicePage .cardService .title {
    width: 100%;
  }
  .cardServicePage .cardService .image {
    width: 100%;
    max-width: 700px;
    margin: 40px auto 0;
  }
  .cardServicePage .cardService .image-block {
    position: relative;
    width: 100%;
    margin-bottom: -10%;
    right: 0;
    top: 0;
  }
  .cardServicePage .solution .blocks {
    margin-right: -30px;
  }
  .cardServicePage .solution .block {
    width: calc(33.333% - 30px);
    margin-right: 30px;
    margin-bottom: 30px;
    padding: 25px 20px 30px;
  }
  .cardServicePage .discover .blocks {
    margin-right: -30px;
  }
  .cardServicePage .discover .block {
    width: calc(33.333% - 30px);
    margin-right: 30px;
    margin-bottom: 30px;
    padding: 25px 20px 30px;
  }
  .cardServicePage .useCase .el {
    transform: scale(0.7);
  }
  .cardServicePage .useCase .el1 {
    top: 60px;
    right: 5%;
  }
  .cardServicePage .useCase .el2 {
    display: none;
  }
  .cardServicePage .useCase .content {
    padding-right: 0;
  }
  .cardServicePage .useCase .items {
    margin-right: 30px;
  }
}
@media (max-width: 768px) {
  .cardServicePage .title {
    font-size: 30px;
    line-height: 40px;
  }
  .cardServicePage .cardService {
    padding-top: 100px;
  }
  .cardServicePage .cardService .title {
    max-width: 370px;
  }
  .cardServicePage .cardService .image {
    max-width: 500px;
  }
  .cardServicePage .cardService .items {
    margin: 130px -10px 0;
  }
  .cardServicePage .cardService .item {
    width: calc(50% - 20px);
    margin: 0 10px 40px;
  }
  .cardServicePage .dashboard .wrapper {
    padding: 0 10px;
  }
  .cardServicePage .dashboard .blocks {
    max-width: 355px;
    padding-top: 226px;
    padding-bottom: 172px;
  }
  .cardServicePage .dashboard .block-1 {
    max-width: 300px;
    width: 87%;
    margin-right: 5px;
    border-radius: 8px;
  }
  .cardServicePage .dashboard .block-2 {
    max-width: 125px;
    width: 37%;
    top: 16.7%;
    left: 8px;
    border-radius: 12px;
  }
  .cardServicePage .dashboard .block-3 {
    max-width: 125px;
    width: 37%;
    top: auto;
    bottom: 0;
    left: 50.9%;
    border-radius: 12px;
  }
  .cardServicePage .dashboard .block-4 {
    max-width: 150px;
    width: 44%;
    top: 43.2%;
    left: -5px;
    border-radius: 6.6px;
  }
  .cardServicePage .dashboard .block-5 {
    max-width: 150px;
    width: 44%;
    top: 27%;
    left: 58.3%;
    border-radius: 6.6px;
  }
  .cardServicePage .dashboard .block-6 {
    max-width: 150px;
    width: 44%;
    top: 24.5%;
    left: 55.3%;
    border-radius: 6.6px;
  }
  .cardServicePage .dashboard .block-7 {
    top: 0;
    left: 1.3%;
  }
  .cardServicePage .dashboard .block-8 {
    left: 4.5%;
    top: 74%;
  }
  .cardServicePage .dashboard .block-9 {
    right: 1%;
    top: 7.6%;
  }
  .cardServicePage .dashboard .block-text {
    transform: scale(1);
    max-width: 149px;
    padding: 30px 17px 30px 25px;
  }
  .cardServicePage .solution {
    padding: 60px 0;
  }
  .cardServicePage .solution .block {
    width: calc(50% - 30px);
  }
  .cardServicePage .discover .block {
    width: calc(50% - 30px);
  }
  .cardServicePage .useCase {
    min-height: 0;
    padding: 40px 0 80px;
  }
  .cardServicePage .useCase .mobile {
    display: block;
  }
  .cardServicePage .useCase .desktop {
    display: none;
  }
  .cardServicePage .useCase .content {
    flex-direction: column;
    margin-top: 60px;
  }
  .cardServicePage .useCase .title {
    text-align: center;
    margin-bottom: 0;
  }
  .cardServicePage .useCase .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: none;
    margin: 0 auto 40px;
    max-width: 345px;
  }
  .cardServicePage .useCase .items-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    display: block;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .cardServicePage .useCase .item {
    width: calc(20% - 2.5px);
    max-width: 65px;
    height: 65px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding: 10px;
  }
  .cardServicePage .useCase .item-title {
    display: none;
  }
  .cardServicePage .useCase .item-icon {
    margin-right: 0;
  }
  .cardServicePage .useCase .block-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
  .cardServicePage .useCase .block-button {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 30px;
  }
  .cardServicePage .useCase .block-button:last-child {
    margin-bottom: 0;
  }
  .cardServicePage .useCase .el {
    display: none;
  }
}
@media (max-width: 480px) {
  .cardServicePage .cardService .items {
    margin: 130px 0px 0;
  }
  .cardServicePage .cardService .item {
    width: 100%;
    margin: 0 0px 40px;
  }
  .cardServicePage .solution .blocks {
    margin-top: 50px;
  }
  .cardServicePage .solution .block {
    width: calc(100% - 30px);
  }
  .cardServicePage .discover .blocks {
    margin-top: 50px;
  }
  .cardServicePage .discover .block {
    width: calc(100% - 30px);
  }
}
.productsPage {
  overflow: hidden;
  position: relative;
  background: #050b29;
}
.productsPage:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  background-image: url("../img/products/back.png");
  background-color: #050b29;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  transition: all 0.3s ease-in;
}
.productsPage main {
  position: relative;
  z-index: 2;
}
.productsPage .title {
  font-size: 55px;
  text-align: center;
  line-height: 65px;
}
.productsPage .products {
  padding: 200px 0 68px;
}
.productsPage .products .wrapper {
  max-width: 1150px;
}
.productsPage .products .content {
  display: flex;
  justify-content: space-between;
}
.productsPage .products .info {
  max-width: 480px;
  width: 59%;
}
.productsPage .products .info p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #f0cc9b;
  text-align: left;
  margin-top: 26px;
}
.productsPage .products .title {
  color: #fff;
  text-align: left;
}
.productsPage .products .image {
  width: 49%;
  position: relative;
}
.productsPage .products .image-block {
  position: absolute;
  width: 101.7%;
  left: 1%;
  top: -1%;
}
.productsPage .products .image-block img {
  width: 100%;
}
.productsPage .products .blocks {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 158px;
}
.productsPage .products .block-1 {
  position: relative;
  z-index: 2;
}
.productsPage .products .block-1 img {
  box-shadow: 66px 89px 101px rgba(5,5,13,0.371);
  border-radius: 25px;
  width: 108.5%;
  max-width: none;
}
.productsPage .products .block-2 {
  margin-top: 120px;
  display: flex;
  justify-content: flex-end;
}
.productsPage .products .block-2 img {
  box-shadow: 57px 77px 88px rgba(5,5,13,0.371);
  border-radius: 25px;
  width: 109%;
  max-width: none;
}
.productsPage .whiteLabel {
  padding: 90px 0 0;
}
.productsPage .whiteLabel .wrapper {
  max-width: 1070px;
}
.productsPage .whiteLabel .title .gold {
  display: block;
}
.productsPage .whiteLabel .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #f9d4a1;
  text-align: center;
  max-width: 750px;
  letter-spacing: -0.5px;
  width: 100%;
  margin: 21px auto 0;
}
.productsPage .whiteLabel .blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 79px 0px 0;
}
.productsPage .whiteLabel .block {
  width: calc(50% - 40px);
  margin-bottom: 60px;
}
.productsPage .whiteLabel .block-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.productsPage .whiteLabel .block-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}
.productsPage .whiteLabel .block-text {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #f9d4a0;
}
.productsPage .rocketSection {
  padding: 60px 0 75px;
}
.productsPage .rocketSection .wrapper {
  max-width: 1070px;
  overflow: hidden;
}
.productsPage .rocketSection.animeteOn {
  opacity: 1;
}
.productsPage .rocketSection .rocket {
  display: flex;
  justify-content: center;
  transition: all 1.5s ease-in;
  transition-delay: 0.2s;
  max-width: 624px;
  width: 60%;
  margin: 0 auto;
}
.productsPage .rocketSection .rocket svg {
  overflow: visible;
  width: 100%;
  transform: translateX(-114%);
}
.productsPage .rocketSection .rocket #rocket g {
  transition: all 0.3s ease-in;
}
.productsPage .rocketSection .rocket #rocket g:not(:last-child) {
  opacity: 0;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(1) {
  transition-delay: 0.6s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(2) {
  transition-delay: 0.7s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(3) {
  transition-delay: 0.8s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(4) {
  transition-delay: 0.9s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(5) {
  transition-delay: 1s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(6) {
  transition-delay: 1.1s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(7) {
  transition-delay: 1.2s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(8) {
  transition-delay: 1.3s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(9) {
  transition-delay: 1.4s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(10) {
  transition-delay: 1.5s;
}
.productsPage .rocketSection .rocket #rocket g:nth-child(11) {
  transition-delay: 1.6s;
}
.productsPage .rocketSection .rocket.animeteOn {
  opacity: 1;
}
.productsPage .rocketSection .rocket.animeteOn #rocket g {
  opacity: 1;
}
.productsPage .rocketSection .rocket .rocket-blackVersion {
  filter: url("#filterRocket-blackVersion");
}
.productsPage .rocketSection .rocket-block {
  max-width: 624px;
  width: 60%;
  margin: 0 auto;
  transition: all 0.5s linear;
  transition-delay: 2s;
}
.productsPage .rocketSection .rocket-block.animeteOn {
  opacity: 1;
}
.productsPage .rocketSection .rocket-block.animeteOn #rocket g {
  opacity: 1;
}
.productsPage .rocketSection .blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -115px 0 0;
}
.productsPage .rocketSection .block {
  width: calc(50% - 40px);
  margin-bottom: 60px;
}
.productsPage .rocketSection .block-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.productsPage .rocketSection .block-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}
.productsPage .rocketSection .block-text {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #f9d4a0;
}
.useCases {
  padding: 25px 0;
  min-height: 713px;
}
.useCases .el {
  position: absolute;
}
.useCases .el1 {
  right: 18.5%;
  top: 50px;
}
.useCases .el2 {
  right: 4.5%;
  top: 226px;
}
.useCases .el3 {
  right: 21.4%;
  bottom: -87px;
}
.useCases .content {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  padding-right: 140px;
}
.useCases .subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #f9d4a1;
  margin-top: 26px;
}
.useCases .items {
  max-width: 300px;
  width: 100%;
  margin-right: 60px;
}
.useCases .items-subtitle {
  display: none;
}
.useCases .item {
  border: 1px solid rgba(249,212,160,0.4);
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.useCases .item:hover {
  border-color: #f9d4a0;
}
.useCases .item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
  border: 1px solid #f9d4a0;
  pointer-events: none;
}
.useCases .item:last-child {
  margin-bottom: 0;
}
.useCases .item-icon {
  margin-right: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.useCases .item-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
}
.useCases .blocks {
  max-width: 880px;
  width: 100%;
}
.useCases .block {
  display: none;
}
.useCases .block.active {
  display: block;
}
.useCases .block-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #f9d4a1;
  max-width: 440px;
  width: 100%;
}
.useCases .block-list {
  margin-top: 40px;
}
.useCases .block-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #fff;
  margin-bottom: 25px;
  padding-left: 30px;
  position: relative;
}
.useCases .block-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  background-image: url("../img/integrate/check.svg");
}
@media (max-width: 998px) {
  .productsPage .products {
    padding-bottom: 0;
  }
  .productsPage .products .content {
    flex-direction: column;
  }
  .productsPage .products .info {
    width: 100%;
  }
  .productsPage .products .title {
    width: 100%;
  }
  .productsPage .products .image {
    width: 100%;
    max-width: 700px;
    margin: 40px auto 0;
  }
  .productsPage .products .image-block {
    position: relative;
    width: 100%;
    margin-bottom: -10%;
  }
  .productsPage .useCases .el {
    transform: scale(0.7);
  }
  .productsPage .useCases .el1 {
    top: 60px;
    right: 5%;
  }
  .productsPage .useCases .el2 {
    display: none;
  }
  .productsPage .useCases .content {
    padding-right: 0;
  }
  .productsPage .useCases .items {
    margin-right: 30px;
  }
}
@media (max-width: 768px) {
  .productsPage .title {
    font-size: 30px;
    line-height: 40px;
  }
  .productsPage .products {
    padding-top: 100px;
  }
  .productsPage .products .title {
    max-width: 370px;
  }
  .productsPage .products .image {
    max-width: 500px;
  }
  .productsPage .products .blocks {
    margin-top: 70px;
  }
  .productsPage .products .block img {
    border-radius: 10px;
  }
  .productsPage .whiteLabel .block {
    width: 100%;
    margin: 0 auto 60px;
    max-width: 320px;
  }
  .productsPage .rocketSection {
    padding: 0;
  }
  .productsPage .rocketSection .block {
    width: 100%;
    margin: 0 auto 60px;
    max-width: 320px;
  }
  .productsPage .rocketSection .blocks {
    margin-top: 50px;
  }
  .productsPage .rocketSection .rocket {
    width: 80%;
  }
  .productsPage .rocketSection .rocket svg {
    height: auto;
    transform: translateX(-140%);
  }
  .productsPage .useCases {
    min-height: 0;
    padding: 40px 0 80px;
  }
  .productsPage .useCases .content {
    flex-direction: column;
    margin-top: 60px;
  }
  .productsPage .useCases .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    margin: 0 auto 40px;
  }
  .productsPage .useCases .items-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    display: block;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .productsPage .useCases .item {
    width: calc(33.333% - 2.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 20px;
    margin-bottom: 0;
  }
  .productsPage .useCases .item-title {
    display: none;
  }
  .productsPage .useCases .item-icon {
    margin-right: 0;
  }
  .productsPage .useCases .block-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
  .productsPage .useCases .block-button {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 30px;
  }
  .productsPage .useCases .block-button:last-child {
    margin-bottom: 0;
  }
  .productsPage .useCases .el {
    display: none;
  }
}
@media (max-width: 480px) {
  .productsPage .products .blocks {
    flex-direction: column;
  }
  .productsPage .products .block-1 {
    width: calc(100% - 20px);
    margin-right: auto;
  }
  .productsPage .products .block-1 img {
    max-width: 100%;
  }
  .productsPage .products .block-2 {
    margin-top: -20px;
    width: calc(100% - 20px);
    margin-left: auto;
  }
  .productsPage .products .block-2 img {
    max-width: 100%;
  }
}
.cryptoPage {
  overflow: hidden;
  position: relative;
  background: #050b29;
}
.cryptoPage:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  background-image: url("../img/products/back.png");
  background-color: #050b29;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  transition: all 0.3s ease-in;
}
.cryptoPage main {
  position: relative;
  z-index: 2;
}
.cryptoPage .title {
  font-size: 55px;
  text-align: center;
  line-height: 65px;
}
.cryptoPage .crypto {
  padding: 200px 0 68px;
}
.cryptoPage .crypto .wrapper {
  max-width: 1150px;
}
.cryptoPage .crypto .subtitle {
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  color: #f9d4a1;
  margin-top: 35px;
  max-width: 570px;
  width: 100%;
}
.cryptoPage .crypto .content {
  display: flex;
  justify-content: space-between;
}
.cryptoPage .crypto .info {
  max-width: 655px;
  width: 69%;
}
.cryptoPage .crypto .title {
  color: #fff;
  text-align: left;
}
.cryptoPage .crypto .image {
  width: 49%;
  position: relative;
}
.cryptoPage .crypto .image-block {
  position: absolute;
  width: 147.1%;
  left: -14%;
  top: -5%;
}
.cryptoPage .crypto .image-block img {
  width: 100%;
}
.cryptoPage .securelyBuy {
  padding: 91px 0 60px;
}
.cryptoPage .securelyBuy .title {
  max-width: 696px;
  width: 100%;
  margin: 0 auto;
}
.cryptoPage .securelyBuy .subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-top: 41px;
}
.cryptoPage .securelyBuy .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #f9d4a1;
  text-align: center;
  max-width: 800px;
  width: 100%;
  margin: 40px auto 0;
}
.cryptoPage .securelyBuy .list {
  column-count: 3;
  column-gap: 5%;
  max-width: 954px;
  width: 100%;
  margin: 60px auto 0;
}
.cryptoPage .securelyBuy .item {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5px;
  color: #fff;
  margin-bottom: 25px;
  padding-left: 30px;
  position: relative;
}
.cryptoPage .securelyBuy .item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 18px;
  height: 18px;
  background-image: url("../img/integrate/check.svg");
}
.cryptoPage .platfroms {
  padding: 88px 0;
}
.cryptoPage .platfroms .blocks {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1160px;
  margin: 0 auto;
  padding-top: 18.3%;
}
.cryptoPage .platfroms .block {
  width: 100%;
}
.cryptoPage .platfroms .block-1 {
  width: 50%;
}
.cryptoPage .platfroms .block-1 img {
  box-shadow: 57px 77px 88px rgba(32,47,86,0.25);
  border-radius: 25px;
  width: 112%;
  max-width: 650px;
}
.cryptoPage .platfroms .block-2 {
  margin-top: 99px;
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.cryptoPage .platfroms .block-2 img {
  box-shadow: 57px 77px 88px rgba(32,47,86,0.25);
  border-radius: 25px;
  width: 112%;
  max-width: 650px;
}
.cryptoPage .platfroms .block-3 {
  position: absolute;
  max-width: 325px;
  width: 29%;
  z-index: 3;
  left: 20.5%;
  top: 35%;
}
.cryptoPage .platfroms .block-3 img {
  box-shadow: 33px 45px 51px rgba(5,5,13,0.371);
  border-radius: 14.3805px;
}
.cryptoPage .platfroms .block-4 {
  position: absolute;
  width: 50%;
  z-index: 2;
  right: 21.5%;
  top: 0;
}
.cryptoPage .platfroms .block-4 img {
  box-shadow: 57px 77px 88px rgba(32,47,86,0.25);
  border-radius: 25px;
  width: 112%;
  max-width: 650px;
}
.cryptoPage .why {
  padding: 72px 0;
}
.cryptoPage .why .wrapper {
  max-width: 1070px;
}
.cryptoPage .why .title {
  text-align: center;
}
.cryptoPage .why .subtitle {
  margin-top: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #f9d4a1;
}
.cryptoPage .why .blocks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 81px -20px 0;
}
.cryptoPage .why .block {
  width: calc(33.333% - 40px);
  margin: 0 20px 60px;
}
.cryptoPage .why .block-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}
.cryptoPage .why .block-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}
.cryptoPage .why .block-text {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #f9d4a0;
}
.cryptoPage .useCases {
  padding: 25px 0 113px;
  min-height: 0;
}
.cryptoPage .useCases .el {
  position: absolute;
}
.cryptoPage .useCases .el1 {
  right: 5.5%;
  top: 200px;
}
.cryptoPage .useCases .el2 {
  right: 9%;
  top: 434px;
}
.cryptoPage .useCases .el3 {
  right: 19.6%;
  bottom: 15px;
}
.cryptoPage .useCases .el4 {
  left: 40%;
  bottom: 63px;
}
.cryptoPage .useCases .el5 {
  right: 34.3%;
  top: 400px;
}
.cryptoPage .useCases .content {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  padding-right: 140px;
}
.cryptoPage .useCases .subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  color: #f9d4a1;
  max-width: 595px;
  width: 100%;
  margin: 21px auto 0;
}
.cryptoPage .useCases .items {
  max-width: 300px;
  width: 100%;
  margin-right: 60px;
}
.cryptoPage .useCases .items-subtitle {
  display: none;
}
.cryptoPage .useCases .item {
  border: 1px solid rgba(249,212,160,0.4);
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 25px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.cryptoPage .useCases .item:hover {
  border-color: #f9d4a0;
}
.cryptoPage .useCases .item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
  border: 1px solid #f9d4a0;
  pointer-events: none;
}
.cryptoPage .useCases .item:last-child {
  margin-bottom: 0;
}
.cryptoPage .useCases .item-icon {
  margin-right: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cryptoPage .useCases .item-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
}
.cryptoPage .useCases .blocks {
  margin-top: -6px;
}
.cryptoPage .useCases .block {
  display: none;
}
.cryptoPage .useCases .block.active {
  display: block;
}
.cryptoPage .useCases .block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.5px;
  color: #fff;
  max-width: 440px;
  width: 100%;
}
.cryptoPage .useCases .block-list {
  margin-top: 40px;
}
.cryptoPage .useCases .block-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;
  color: #fff;
  margin-bottom: 25px;
  padding-left: 30px;
  position: relative;
}
.cryptoPage .useCases .block-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  background-image: url("../img/integrate/check.svg");
}
@media (max-width: 998px) {
  .cryptoPage .crypto {
    padding-bottom: 0;
  }
  .cryptoPage .crypto .content {
    flex-direction: column;
  }
  .cryptoPage .crypto .info {
    max-width: none;
    width: 100%;
  }
  .cryptoPage .crypto .title {
    width: 100%;
  }
  .cryptoPage .crypto .image {
    width: 100%;
    max-width: 700px;
    margin: 40px auto 0;
  }
  .cryptoPage .crypto .image-block {
    position: relative;
    width: 100%;
    margin-bottom: -10%;
    left: 0;
    top: 0;
  }
  .cryptoPage .why .block {
    width: calc(50% - 40px);
  }
  .cryptoPage .why .block-title br {
    display: none;
  }
  .cryptoPage .useCases .el {
    transform: scale(0.7);
  }
  .cryptoPage .useCases .el1 {
    top: 60px;
    right: 5%;
  }
  .cryptoPage .useCases .el4,
  .cryptoPage .useCases .el1,
  .cryptoPage .useCases .el5 {
    display: none;
  }
  .cryptoPage .useCases .content {
    padding-right: 0;
  }
  .cryptoPage .useCases .items {
    margin-right: 30px;
  }
}
@media (max-width: 768px) {
  .cryptoPage .title {
    font-size: 30px;
    line-height: 40px;
  }
  .cryptoPage .crypto {
    padding-top: 100px;
  }
  .cryptoPage .crypto .info {
    max-width: 550px;
  }
  .cryptoPage .crypto .image {
    max-width: 500px;
  }
  .cryptoPage .securelyBuy .list {
    column-count: 2;
  }
  .cryptoPage .why {
    padding-bottom: 0;
  }
  .cryptoPage .why .blocks {
    flex-direction: column;
    margin: 60px 0 0;
  }
  .cryptoPage .why .block {
    width: 100%;
    margin: 0 auto 60px;
    max-width: 320px;
  }
  .cryptoPage .useCases {
    min-height: 0;
    padding: 40px 0 80px;
  }
  .cryptoPage .useCases .content {
    flex-direction: column;
    margin-top: 60px;
  }
  .cryptoPage .useCases .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 450px;
    margin: 0 auto 40px;
  }
  .cryptoPage .useCases .items-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    display: block;
    font-weight: 500;
    margin-bottom: 30px;
  }
  .cryptoPage .useCases .item {
    width: calc(20% - 2.5px);
    max-width: 65px;
    height: 65px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding: 10px;
  }
  .cryptoPage .useCases .item-title {
    display: none;
  }
  .cryptoPage .useCases .item-icon {
    margin-right: 0;
  }
  .cryptoPage .useCases .block-buttons {
    flex-direction: column;
    align-items: flex-start;
  }
  .cryptoPage .useCases .block-button {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 30px;
  }
  .cryptoPage .useCases .block-button:last-child {
    margin-bottom: 0;
  }
  .cryptoPage .useCases .el {
    display: none;
  }
  .cryptoPage .platfroms {
    padding: 30px 0px;
  }
  .cryptoPage .platfroms .blocks {
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    padding-top: 0;
  }
  .cryptoPage .platfroms .block img {
    max-width: 100%;
    border-radius: 13px;
  }
  .cryptoPage .platfroms .block-1 {
    width: 100%;
    order: 3;
  }
  .cryptoPage .platfroms .block-2 {
    width: 100%;
    margin-top: 0;
    margin-bottom: 10px;
    order: 1;
  }
  .cryptoPage .platfroms .block-3 {
    width: 100%;
    max-width: none;
    left: 0;
    top: 26%;
  }
  .cryptoPage .platfroms .block-3 img {
    width: 48%;
    margin: 0 auto;
  }
  .cryptoPage .platfroms .block-4 {
    width: 100%;
    position: static;
    margin-bottom: 10px;
    order: 2;
  }
}
@media (max-width: 480px) {
  .cryptoPage .securelyBuy .list {
    column-count: 1;
    column-gap: 0%;
  }
  .cryptoPage .useCases .items {
    max-width: 345px;
  }
  .cryptoPage .useCases .item {
    padding: 10px 5px;
  }
}
.whiteVersion {
  display: none;
}
.isWhiteVersion .blackVersion {
  display: none;
}
.isWhiteVersion .whiteVersion {
  display: flex;
}
.isWhiteVersion .page {
  background: #d0d7e2;
}
.isWhiteVersion h1,
.isWhiteVersion h2 {
  color: #050925;
}
.isWhiteVersion .gold {
  color: #d39f56;
}
.isWhiteVersion .page:after {
  background-image: url("../img/main/background-white.png");
  background-color: #d3dae5;
}
.isWhiteVersion .header-list__item a {
  color: #050925;
}
.isWhiteVersion .header-list__item a:hover {
  color: #d39f56;
}
.isWhiteVersion .header-language__main .icon path {
  stroke: #050925;
}
.isWhiteVersion .header-language__main .arrow path {
  stroke: rgba(5,9,37,0.3);
}
.isWhiteVersion .header-language__main .language {
  color: #050925;
}
.isWhiteVersion .header-language__item {
  color: #050925;
}
.isWhiteVersion .header-switch__button[data-mode='white'] {
  background: #fff;
  border-color: #fff;
  pointer-events: none;
}
.isWhiteVersion .header-switch__button[data-mode='white'] .path-stroke {
  stroke: #292d32;
}
.isWhiteVersion .header-switch__button[data-mode='white'] .path-fill {
  fill: #292d32;
}
.isWhiteVersion .header-switch__button[data-mode='black'] {
  background: transparent;
  border: 1px solid rgba(255,255,255,0.5);
  pointer-events: all;
}
.isWhiteVersion .header-switch__button[data-mode='black'] .path-stroke {
  stroke: #fff;
}
.isWhiteVersion .header-switch__button[data-mode='black'] .path-fill {
  fill: #fff;
}
.isWhiteVersion .header-switch__button[data-mode='black']:hover .path-stroke {
  stroke: #292d32;
}
.isWhiteVersion .header-switch__button[data-mode='black']:hover .path-fill {
  fill: #292d32;
}
.isWhiteVersion .header.fixed {
  background: rgba(192,205,218,0.8);
  backdrop-filter: blur(10.8731px);
}
.isWhiteVersion .header-language__main:hover .arrow path {
  stroke: #050925;
}
.isWhiteVersion .header-menu__button .line {
  background: #d39f56;
}
.isWhiteVersion .contactUs {
  border-color: rgba(5,9,37,0.4);
}
.isWhiteVersion .contactUs-block {
  background: transparent;
}
.isWhiteVersion .contactUs-line {
  border: 0.5px solid #050925;
}
.isWhiteVersion .contactUs-icon path {
  stroke: #050925;
}
.isWhiteVersion .contactUs-title {
  color: #050925;
}
.isWhiteVersion .contactUs:hover {
  background: rgba(5,9,37,0.1);
}
.isWhiteVersion .contactUs:hover .contactUs-title {
  color: #050925;
}
.isWhiteVersion .mainSection-video:before {
  display: none;
}
.isWhiteVersion .mainSection-slide__1 .mainSection-item__title {
  color: #050925;
}
.isWhiteVersion .mainSection-slide__1 .mainSection-item__text {
  color: rgba(5,9,37,0.6);
}
.isWhiteVersion .mainSection-slide:after {
  background: linear-gradient(0deg, #d3dae5 0%, rgba(9,40,120,0) 100%);
}
.isWhiteVersion .partners:after {
  background: linear-gradient(180deg, #d3dae5 0%, rgba(9,40,120,0) 100%);
}
.isWhiteVersion .intagrate-item {
  background: #fff;
  border-color: transparent;
}
.isWhiteVersion .intagrate-item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
}
.isWhiteVersion .intagrate-item.active .intagrate-item__title {
  color: #fff;
}
.isWhiteVersion .intagrate-item__title {
  color: #050925;
  transition: all 0.3s ease-in;
}
.isWhiteVersion .intagrate-item__icon path {
  fill: #d39f56;
}
.isWhiteVersion .intagrate-item:hover .intagrate-item__title {
  color: #d39f56;
}
.isWhiteVersion .how-subtitle {
  color: #050925;
}
.isWhiteVersion .how-text {
  color: rgba(5,9,37,0.6);
}
.isWhiteVersion .how-item__title {
  color: #050925;
}
.isWhiteVersion .how-item__text {
  color: rgba(5,9,37,0.6);
}
.isWhiteVersion .how-item__icon path {
  fill: #d39f56;
}
.isWhiteVersion .how-item__num {
  background: #fff;
  color: #050925;
}
.isWhiteVersion .how-item .line path {
  stroke: #050925;
}
.isWhiteVersion .faq .title {
  color: #050925;
}
.isWhiteVersion .faq-item__title {
  color: #050925;
}
.isWhiteVersion .faq-item__bottom p {
  color: #050925;
}
.isWhiteVersion .faq-item__button path {
  fill: #050925;
}
.isWhiteVersion .faq-item__top:hover .faq-item__title {
  color: #d39f56;
}
.isWhiteVersion .footer-top .footer-item {
  color: #050925;
}
.isWhiteVersion .footer-top .footer-item:hover {
  color: #d39f56;
}
.isWhiteVersion .footer-adress {
  color: #050925;
}
.isWhiteVersion .footer-adress .description {
  color: rgba(5,9,37,0.5);
}
.isWhiteVersion .footer-adress .adress {
  color: #050925;
}
.isWhiteVersion .footer-date {
  color: rgba(5,9,37,0.5);
}
.isWhiteVersion .footer-social path,
.isWhiteVersion .footer-social rect,
.isWhiteVersion .footer-social circle {
  stroke: #050925;
}
.isWhiteVersion .footer-social:hover path,
.isWhiteVersion .footer-social:hover rect,
.isWhiteVersion .footer-social:hover circle {
  stroke: #d39f56;
}
.isWhiteVersion .fintechSection .right-el path {
  stroke: #050925;
}
.isWhiteVersion .fintechSection .blocks.right .right-lines path {
  stroke: #7d8899;
}
.isWhiteVersion .fintechSection .center-item.active .center-item__content {
  border-color: rgba(162,125,115,0.3);
}
.isWhiteVersion .fintechSection .center-item__title {
  color: #050925;
}
.isWhiteVersion .fintechSection .center-item__block:after {
  background-image: url("../img/main/fintech/center/link_gor-whiteVersion.svg");
}
.isWhiteVersion .fintechSection .block-lines-1 path {
  stroke: url("#linear-whiteVersion");
}
.isWhiteVersion .fintechSection .block-lines-2 path {
  stroke: url("#linear-whiteVersion");
}
.isWhiteVersion .fintechSection .block-line__opacity-1 g path {
  stroke: #050925;
}
.isWhiteVersion .fintechSection .center-block__line {
  border-color: rgba(156,119,111,0.3);
}
.isWhiteVersion .fintechSection .block-round__content .center-block__line {
  border-color: transparent;
}
.isWhiteVersion .fintechSection .block-round__content.active .center-block__line {
  border-color: rgba(156,119,111,0.3);
}
.isWhiteVersion .fintechSection .block-round__lines path {
  stroke: #7d8899;
}
.isWhiteVersion #center-line1 {
  stroke: rgba(5,9,37,0.5);
}
.isWhiteVersion #center-el-1 path,
.isWhiteVersion #center-el-2 path {
  stroke: #050925;
}
.isWhiteVersion #lines-el-6 path,
.isWhiteVersion #lines-el-8 path {
  stroke: #050925;
}
.isWhiteVersion #lines-el-9 polygon {
  fill: #050925;
}
.isWhiteVersion #lines-el-9 path {
  fill: #050925;
}
.isWhiteVersion #lines-el-9 path:last-child {
  fill: #faa61a;
}
.isWhiteVersion #centerRight_link {
  stroke: #7d8899;
}
.isWhiteVersion #centerLeft_link {
  fill: #7d8899;
}
.isWhiteVersion #round-el-1 path {
  stroke: #050925;
}
.isWhiteVersion #centerLeft_element path {
  stroke: #050925;
}
.isWhiteVersion #centerRight_element path {
  stroke: #050925;
}
@media (max-width: 600px) {
  .isWhiteVersion .header-menu__block {
    background: #d0d7e2;
  }
}
.isWhiteVersion .integratePage:after {
  background-image: url("../img/integrate/back-whiteVersion.png");
  background-color: #d3dae5;
}
.isWhiteVersion .integratePage .integrate .title {
  color: #050925;
}
.isWhiteVersion .integratePage .digital .text {
  color: #050925;
}
.isWhiteVersion .integratePage .digital .item {
  color: #050925;
}
.isWhiteVersion .integratePage .digital .item:after {
  background-image: url("../img/integrate/check-whiteVersion.svg");
}
.isWhiteVersion .integratePage .features .block-title,
.isWhiteVersion .integratePage .features .block-text {
  color: #050925;
}
.isWhiteVersion .integratePage .baas .subtitle {
  color: #050925;
}
.isWhiteVersion .integratePage .baas .block-title {
  color: #050925;
}
.isWhiteVersion .integratePage .baas .block-item {
  color: #050925;
}
.isWhiteVersion .integratePage .baas .block-item:after {
  background-image: url("../img/integrate/check-whiteVersion.svg");
}
.isWhiteVersion .integratePage .baas .item {
  background: #fff;
  border: none;
  padding: 25px;
}
.isWhiteVersion .integratePage .baas .item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
}
.isWhiteVersion .integratePage .baas .item.active .item-title {
  color: #fff;
}
.isWhiteVersion .integratePage .baas .item-title {
  color: #050925;
  transition: all 0.3s ease-in;
  font-weight: 500;
}
.isWhiteVersion .integratePage .baas .item-icon path {
  fill: #d39f56;
}
.isWhiteVersion .integratePage .baas .item:hover .item-title {
  color: #d39f56;
}
.isWhiteVersion .productsPage:after {
  background-image: url("../img/products/back-whiteVersion.png");
  background-color: #d3dae5;
}
.isWhiteVersion .productsPage .whiteLabel .block-text,
.isWhiteVersion .productsPage .whiteLabel .text,
.isWhiteVersion .productsPage .whiteLabel .block-title {
  color: #050925;
}
.isWhiteVersion .productsPage .whiteLabel .block-icon path,
.isWhiteVersion .productsPage .whiteLabel .block-icon circle {
  stroke: #d39f56;
}
.isWhiteVersion .productsPage .products .title {
  color: #050925;
}
.isWhiteVersion .productsPage .products .info p {
  color: #050925;
}
.isWhiteVersion .productsPage .rocketSection .rocket .rocket-blackVersion {
  filter: url("#filterRocket-whiteVersion");
}
.isWhiteVersion .productsPage .rocketSection .block-title,
.isWhiteVersion .productsPage .rocketSection .block-text {
  color: #050925;
}
.isWhiteVersion .productsPage .useCases .subtitle {
  color: #050925;
}
.isWhiteVersion .productsPage .useCases .block-title {
  color: #050925;
}
.isWhiteVersion .productsPage .useCases .block-item {
  color: #050925;
}
.isWhiteVersion .productsPage .useCases .block-item:after {
  background-image: url("../img/integrate/check-whiteVersion.svg");
}
.isWhiteVersion .productsPage .useCases .item {
  background: #fff;
  border: none;
  padding: 25px;
}
.isWhiteVersion .productsPage .useCases .item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
}
.isWhiteVersion .productsPage .useCases .item.active .item-title {
  color: #fff;
}
.isWhiteVersion .productsPage .useCases .item-title {
  color: #050925;
  transition: all 0.3s ease-in;
  font-weight: 500;
}
.isWhiteVersion .productsPage .useCases .item-icon path {
  fill: #d39f56;
}
.isWhiteVersion .productsPage .useCases .item:hover .item-title {
  color: #d39f56;
}
.isWhiteVersion .cryptoPage:after {
  background-image: url("../img/products/back-whiteVersion.png");
  background-color: #d3dae5;
}
.isWhiteVersion .cryptoPage .crypto .title,
.isWhiteVersion .cryptoPage .crypto .subtitle {
  color: #050925;
}
.isWhiteVersion .cryptoPage .securelyBuy .text,
.isWhiteVersion .cryptoPage .securelyBuy .subtitle {
  color: #050925;
}
.isWhiteVersion .cryptoPage .securelyBuy .item {
  color: #050925;
}
.isWhiteVersion .cryptoPage .securelyBuy .item:after {
  background-image: url("../img/integrate/check-whiteVersion.svg");
}
.isWhiteVersion .cryptoPage .why .subtitle {
  color: #050925;
}
.isWhiteVersion .cryptoPage .why .block-title,
.isWhiteVersion .cryptoPage .why .block-text {
  color: #050925;
}
.isWhiteVersion .cryptoPage .useCases .subtitle {
  color: #050925;
}
.isWhiteVersion .cryptoPage .useCases .item {
  background: #fff;
  border: none;
  padding: 25px;
}
.isWhiteVersion .cryptoPage .useCases .item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
}
.isWhiteVersion .cryptoPage .useCases .item.active .item-title {
  color: #fff;
}
.isWhiteVersion .cryptoPage .useCases .item-title {
  color: #050925;
  transition: all 0.3s ease-in;
  font-weight: 500;
}
.isWhiteVersion .cryptoPage .useCases .item-icon path {
  fill: #d39f56;
}
.isWhiteVersion .cryptoPage .useCases .item:hover .item-title {
  color: #d39f56;
}
.isWhiteVersion .cryptoPage .useCases .block-text {
  color: #050925;
}
.isWhiteVersion .useCasePage {
  background: #d3dae5;
}
.isWhiteVersion .useCasePage:after {
  background-image: url("../img/integrate/back-whiteVersion.png");
  background-color: #d3dae5;
}
.isWhiteVersion .useCasePage .main-title {
  color: #050925;
}
.isWhiteVersion .useCasePage .main-subtitle {
  color: #d39f56;
}
.isWhiteVersion .useCasePage .useCase .subtitle {
  color: #050925;
}
.isWhiteVersion .useCasePage .useCase .block-title {
  color: #050925;
}
.isWhiteVersion .useCasePage .useCase .variant-1 .block-text {
  color: #050925;
}
.isWhiteVersion .useCasePage .useCase .variant-1 .block-item {
  color: #050925;
}
.isWhiteVersion .useCasePage .useCase .variant-1 .block-item:after {
  background-image: url("../img/integrate/check-whiteVersion.svg");
}
@media (max-width: 768px) {
  .isWhiteVersion .integratePage .baas .items-subtitle {
    color: #050925;
  }
}
@media (max-width: 768px) {
  .isWhiteVersion .productsPage .useCases .items-subtitle {
    color: #050925;
  }
  .isWhiteVersion .cryptoPage .useCases .items-subtitle {
    color: #050925;
  }
}
@media (max-width: 998px) and (max-width: 480px) {
  .isWhiteVersion .intagrate-items__subtitle {
    color: #050925;
  }
}
.isWhiteVersion .cardServicePage {
  background: #d3dae5;
}
.isWhiteVersion .cardServicePage:after {
  background-image: url("../img/integrate/back-whiteVersion.png");
  background-color: #d3dae5;
}
.isWhiteVersion .cardServicePage .main-title {
  color: #050925;
}
.isWhiteVersion .cardServicePage .main-subtitle {
  color: #d39f56;
}
.isWhiteVersion .cardServicePage .cardService .item-title {
  color: #050925;
}
.isWhiteVersion .cardServicePage .cardService .item-text {
  color: #050925;
}
.isWhiteVersion .cardServicePage .useCase .subtitle {
  color: #050925;
}
.isWhiteVersion .cardServicePage .useCase .block-title {
  color: #050925;
}
.isWhiteVersion .cardServicePage .useCase .block-item {
  color: #050925;
}
.isWhiteVersion .cardServicePage .useCase .block-item:after {
  background-image: url("../img/integrate/check-whiteVersion.svg");
}
.isWhiteVersion .cardServicePage .useCase .block-text {
  color: #050925;
}
.isWhiteVersion .cardServicePage .useCase .item {
  background: #fff;
  border: none;
  padding: 25px;
}
.isWhiteVersion .cardServicePage .useCase .item.active {
  background: linear-gradient(177.76deg, #2e44aa 1.88%, #020e47 109.92%);
}
.isWhiteVersion .cardServicePage .useCase .item.active .item-title {
  color: #fff;
}
.isWhiteVersion .cardServicePage .useCase .item.active .blackVersion {
  display: flex;
}
.isWhiteVersion .cardServicePage .useCase .item.active .whiteVersion {
  display: none;
}
.isWhiteVersion .cardServicePage .useCase .item-title {
  color: #050925;
  transition: all 0.3s ease-in;
  font-weight: 500;
}
.isWhiteVersion .cardServicePage .useCase .item-icon path {
  fill: #d39f56;
}
.isWhiteVersion .cardServicePage .useCase .item:hover .item-title {
  color: #d39f56;
}
@media (max-width: 768px) {
  .isWhiteVersion .cardServicePage .useCase .item {
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .isWhiteVersion .cardServicePage .useCase .items-subtitle {
    color: #050925;
  }
}

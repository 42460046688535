.integratePage
	overflow hidden
	position relative
	background #050b29
	&:after 
		content ""
		position absolute
		bottom 0
		top 0
		width 100%
		min-height: 5000px
		background-image url(../img/integrate/back.png)
		background-color: #050B29
		background-repeat: no-repeat;
		// background-attachment: fixed;
		background-size: cover
		background-position-y: top
		transition all .3s ease-in
	
	main 
		position relative
		z-index 2
	
	.title 
		font-size: 55px;
		text-align center
		line-height: 65px;





	.integrate
		padding 200px 0 68px
		.wrapper 
			max-width: 1150px


		.content 
			display flex
			justify-content space-between
		
		.title 
			max-width: 567px
			width 59%
			color: $white
			text-align left
		
		.image 
			width 49% 
			position relative
			&-block 
				position absolute
				width: 116.7%;
				left: 5%;
				top: -1%;
				img 
					width 100%


	.digital
		padding 60px 0
		.text 
			font-weight: 400;
			font-size: 14px;
			line-height: 28px;
			color: #F9D4A1;
			text-align center
			max-width: 800px
			width 100% 
			margin 43px auto 0
		
		.list 
			column-count: 3;
			column-gap: 5%;
			max-width: 954px
			width 100% 
			margin 60px auto 0

		.item 
			font-weight: 400;
			font-size: 16px;
			line-height: 18px;
			letter-spacing: -0.5px;
			color: #FFFFFF
			margin-bottom 25px 
			padding-left 30px 
			position relative
			&:after 
				content ""
				position absolute 
				left 0
				top 0
				width 18px
				height 18px
				background-image url(../img/integrate/check.svg)
	
	

	.dashboard 
		padding 127px 0
		.blocks 
			display flex
			justify-content space-between
			padding-left 6%
		.block 
			position relative
			&-text 
				position absolute
				background: linear-gradient(2.49deg, #56424D 23.49%, #4E6CC3 149.61%);
				border-radius: 25px;
				padding 30px
				width max-content
				p
					font-weight: 400;
					font-size: 16px;
					line-height: 18px;
					letter-spacing: -0.5px;
					color: #F9D4A1;
				&.mobile 
					display none
			&-1
				width 20%
				margin-top 10%
				.block
					&-image 
						max-width: 260px
						width 112%
						overflow hidden
					&-text 
						left: -24%;
						top: -31%;
			&-2
				width 40%
				left -2.8%
				z-index 2
				.block
					&-image 
						max-width: 565px
						width 122%
						box-shadow: 57px 77px 88px rgba(5, 5, 13, 0.370758);
						border-radius: 25px 
					&-text 
						bottom: -7%;
						left: -2%;
			&-3
				width 40%
				left: -10.2%;
				margin-top 10.1%
				.block
					&-image 
						max-width: 565px
						width 122%
					&-text 
						top: -32%;
						right: 15%;

			
	.features 
		padding 72px 0
		.wrapper 
			max-width: 1070px
		.title 
			max-width: 716px 
			width 100% 
			margin 0 auto
		.blocks 
			display flex
			justify-content space-between
			flex-wrap wrap
			margin 81px -20px 0
		.block 
			width calc(33.333% - 40px)
			margin 0 20px 60px
			&-icon 
				display flex
				justify-content center
				align-items center 
				margin-bottom 25px
			&-title 
				font-weight: 400;
				font-size: 20px;
				line-height: 24px;
				text-align: center;
				color: #FFFFFF;
				margin-bottom 20px
			&-text 
				font-size: 14px;
				line-height: 22px;
				text-align: center;
				color: #F9D4A0;

	
	.baas 
		padding 25px 0
		min-height: 713px
		.el 
			position absolute 
			&1
				right: 18.5%;
				top: 104px;
			&2
				right: 4.5%;
				top: 278px;
			&3
				right: 21.4%;
				bottom: -87px
		.content 
			display flex
			justify-content space-between
			margin-top 80px
			padding-right 140px
		.subtitle 
			font-weight: 400;
			font-size: 14px;
			line-height: 28px;
			text-align: center;
			color: #F9D4A1;
			margin-top 26px
		.items 
			max-width: 300px
			width 100% 
			margin-right 60px
			&-subtitle 
				display none
		.item 
			border: 1px solid rgba(#F9D4A0, .4);
			border-radius: 20px;
			display flex
			justify-content flex-start
			align-items center
			padding 24px 25px
			margin-bottom 10px 
			cursor pointer
			transition: all .3s ease-in
			&:hover 
				border-color #F9D4A0
			&.active 
				background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
				border: 1px solid #F9D4A0;
				pointer-events: none
			&:last-child 
				margin-bottom 0
			&-icon 
				margin-right 20px
				height 40px 
				display flex
				justify-content center
				align-items center
			&-title 
				font-weight: 400;
				font-size: 16px;
				line-height: 18px;
				color: #FFFFFF;
		.blocks 
			max-width: 880px
			width 100%
		.block 
			display none
			&.active 
				display block
			&-title 
				font-weight 400
				font-size: 14px;
				line-height: 28px;
				color: #F9D4A1;
				max-width: 440px 
				width 100%
			&-list 
				margin-top 40px 
			&-item 
				font-weight: 400;
				font-size: 16px;
				line-height: 18px;
				letter-spacing: -0.5px;
				color: #FFFFFF
				margin-bottom 25px 
				padding-left 30px 
				position relative
				&:after 
					content ""
					position absolute 
					left 0
					top 0
					width 18px
					height 18px
					background-image url(../img/integrate/check.svg)
	

	
		


	@media (max-width: 1250px)
		.integrate 
			.image-block
				left 0
				width: 109.7%;

	@media (max-width: 998px)
		.integrate 
			padding-bottom 0
			.content 
				flex-direction: column
			.title 
				width 100%
			.image
				width 100% 
				max-width: 700px
				margin 40px auto 0
				&-block 
					position relative
					width 100%
					margin-bottom -10%
		

		.features
			.block 
				width calc(50% - 40px)
				&-title 
					br 
						display none
			
		
		.baas 
			.el 
				transform: scale(.7)
				&1 
					top 60px
					right 5%
				&2 
					display none
			.content 
				padding-right 0
			.items 
				margin-right 30px
		

		.dashboard 
			.blocks 
				padding-left 20px
				padding-right 25px
				flex-wrap: wrap
				max-width: 500px
				margin 0 auto
			.block
				&-text 
					&.desktop 
						display none
					&.mobile 
						display block
				&-1 
					order 2 
					width 40%
					z-index 2
					margin-top -22%
					left: -5%;
					.block-image 
						width: 104.2%;
					.block-text 
						top 85%
						left -8%
				&-2 
					width 100% 
					left 0
					padding-top 106px
					z-index 1
					.block-image 
						width 100%
					.block-text 
						top: 0
						bottom auto
						left -25px
						&.mobile 
							left auto 
							right -10%
							top auto 
							bottom -6%
							
				&-3 
					width 50%
					order 3
					margin-top -5%
					.block-image 
						width 160%





	@media (max-width: 768px)
		.title 
			font-size: 30px;
			line-height: 40px;
		.integrate 
			padding-top 100px
			.title
				max-width: 370px
			.image 
				max-width: 500px
		

		.digital 
			.list
				column-count: 2;
		
		.features
			padding-bottom 0
			.blocks 
				flex-direction: column
				margin 60px 0 0
			.block 
				width 100%
				margin 0 auto 60px
				max-width: 320px

	

		.baas 
			min-height 0
			padding 25px 0 80px
			.content 
				flex-direction: column
				margin-top 60px
			.items 
				display flex
				justify-content space-between
				align-items center
				max-width: 300px
				margin 0 auto 40px
				&-subtitle 
					font-weight: 400;
					font-size: 16px;
					line-height: 18px;
					text-align: center;
					color: #FFFFFF;
					display block
					font-weight 500
					margin-bottom 30px
			.item 
				width calc(33.333% - 2.5px)
				display flex
				justify-content center
				align-items center
				padding 24px 20px
				margin-bottom 0
				&-title 
					display none
				&-icon 
					margin-right 0
			.block 
				&-buttons 
					flex-direction: column
					align-items flex-start
				&-button 
					font-size: 16px;
					line-height: 18px;
					margin-bottom 30px 
					&:last-child 
						margin-bottom 0
			.el 
				display none
	
		.dashboard 
			padding 40px 0 80px
			.blocks 
				max-width: 345px

	@media (max-width: 480px)

		.digital 
			.list
				column-count: 1;
				column-gap: 0%;
	
.productsPage
    overflow hidden
    position relative
    background #050b29
    &:after 
        content ""
        position absolute
        bottom 0
        top 0
        width 100%
        background-image url(../img/products/back.png)
        background-color: #050B29
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-size: cover
        background-position-y: top
        transition all .3s ease-in

    main 
        position relative
        z-index 2

    .title 
        font-size: 55px;
        text-align center
        line-height: 65px;
    



    .products
        padding 200px 0 68px
        .wrapper 
            max-width: 1150px


        .content 
            display flex
            justify-content space-between
        
        .info 
            max-width: 480px
            width 59%
            p 
                font-weight: 400;
                font-size: 20px;
                line-height: 32px;
                color: #F0CC9B;
                text-align left
                margin-top 26px

        
        .title 
            color: $white
            text-align left

            
        .image 
            width 49% 
            position relative
            &-block 
                position absolute
                width: 101.7%;
                left: 1%;
                top: -1%;
                img 
                    width 100%
        
        .blocks 
            position relative
            display flex
            justify-content space-between
            margin-top 158px
        .block 
            &-1 
                position relative
                z-index 2
                img 
                    box-shadow: 66px 89px 101px rgba(5, 5, 13, 0.370758);
                    border-radius: 25px;
                    width: 108.5%;
                    max-width: none;
            &-2 
                margin-top 120px
                display: flex;
                justify-content: flex-end;
                img 
                    box-shadow: 57px 77px 88px rgba(5, 5, 13, 0.370758);
                    border-radius: 25px;
                    width 109%
                    max-width: none
                
    .whiteLabel
        padding 90px 0 0
        .wrapper 
            max-width: 1070px
        
        .title 
            .gold 
                display block
        .text 
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #F9D4A1;
            text-align center
            max-width: 750px
            letter-spacing: -0.5px;
            width 100% 
            margin 21px auto 0
        
        .blocks 
            display flex
            justify-content space-between
            flex-wrap wrap
            margin 79px 0px 0
        .block 
            width calc(50% - 40px)
            margin-bottom 60px
            &-icon 
                display flex
                justify-content center
                align-items center 
                margin-bottom 15px
            &-title 
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
                margin-bottom 20px
            &-text 
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #F9D4A0;
    
    

    .rocketSection 
        padding 60px 0 75px
        .wrapper 
            max-width: 1070px
            overflow hidden
        &.animeteOn
            opacity 1
        .rocket 
            display flex
            justify-content center
            transition: all 1.5s ease-in
            transition-delay: .2s
            max-width: 624px
            width 60%
            margin 0 auto
            svg 
                overflow visible
                width 100%
                transform: translateX(-114%);
            #rocket 
                g
                    transition: all .3s ease-in
                g:not(:last-child)
                    opacity 0 
                g:nth-child(1)
                    transition-delay: .6s
                g:nth-child(2)
                    transition-delay: .7s
                g:nth-child(3)
                    transition-delay: .8s
                g:nth-child(4)
                    transition-delay: .9s
                g:nth-child(5)
                    transition-delay: 1s
                g:nth-child(6)
                    transition-delay: 1.1s
                g:nth-child(7)
                    transition-delay: 1.2s
                g:nth-child(8)
                    transition-delay: 1.3s
                g:nth-child(9)
                    transition-delay: 1.4s
                g:nth-child(10)
                    transition-delay: 1.5s
                g:nth-child(11)
                    transition-delay: 1.6s
            &.animeteOn
                opacity 1
                #rocket 
                    g
                        opacity 1
                
           
                
            .rocket-blackVersion
                filter url(#filterRocket-blackVersion)
        
        .rocket-block
            max-width: 624px
            width 60%
            margin 0 auto
            transition: all .5s linear
            transition-delay: 2s
            &.animeteOn
                opacity 1
                #rocket 
                    g
                        opacity 1
        .blocks 
            display flex
            justify-content space-between
            flex-wrap wrap
            margin -115px 0 0
        .block 
            width calc(50% - 40px)
            margin-bottom 60px
            &-icon 
                display flex
                justify-content center
                align-items center 
                margin-bottom 25px
            &-title 
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
                margin-bottom 20px
            &-text 
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #F9D4A0;


	.useCases 
        padding 25px 0
        min-height: 713px
        .el 
            position absolute 
            &1
                right: 18.5%;
                top: 50px;
            &2
                right: 4.5%;
                top: 226px;
            &3
                right: 21.4%;
                bottom: -87px
        .content 
            display flex
            justify-content space-between
            margin-top 80px
            padding-right 140px
        .subtitle 
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            color: #F9D4A1;
            margin-top 26px
        .items 
            max-width: 300px
            width 100% 
            margin-right 60px
            &-subtitle 
                display none
        .item 
            border: 1px solid rgba(#F9D4A0, .4);
            border-radius: 20px;
            display flex
            justify-content flex-start
            align-items center
            padding 24px 25px
            margin-bottom 10px 
            cursor pointer
            transition: all .3s ease-in
            &:hover 
                border-color #F9D4A0
            &.active 
                background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
                border: 1px solid #F9D4A0;
                pointer-events: none
            &:last-child 
                margin-bottom 0
            &-icon 
                margin-right 20px
                height 40px 
                display flex
                justify-content center
                align-items center
            &-title 
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #FFFFFF;
        .blocks 
            max-width: 880px
            width 100%
        .block 
            display none
            &.active 
                display block
            &-title 
                font-weight 400
                font-size: 14px;
                line-height: 28px;
                color: #F9D4A1;
                max-width: 440px 
                width 100%
            &-list 
                margin-top 40px 
            &-item 
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                letter-spacing: -0.5px;
                color: #FFFFFF
                margin-bottom 25px 
                padding-left 30px 
                position relative
                &:after 
                    content ""
                    position absolute 
                    left 0
                    top 0
                    width 18px
                    height 18px
                    background-image url(../img/integrate/check.svg)
.productsPage
    
    @media (max-width: 998px)
        .products 
            padding-bottom 0
            .content 
                flex-direction: column
            .info 
                width 100%
            .title 
                width 100%
            .image
                width 100% 
                max-width: 700px
                margin 40px auto 0
                &-block 
                    position relative
                    width 100%
                    margin-bottom -10%
        
        
        .useCases  
            .el 
                transform: scale(.7)
                &1 
                    top 60px
                    right 5%
                &2 
                    display none
            .content 
                padding-right 0
            .items 
                margin-right 30px
        



    @media (max-width: 768px)
        .title 
            font-size: 30px;
            line-height: 40px;

        .products 
            padding-top 100px
            .title
                max-width: 370px
            .image 
                max-width: 500px
            .blocks 
                margin-top 70px
            .block 
                img 
                    border-radius: 10px
        
        .whiteLabel 
            .block 
                width 100%
                margin 0 auto 60px
                max-width: 320px
            

        .rocketSection 
            padding 0
            .block 
                width 100%
                margin 0 auto 60px
                max-width: 320px
            
            .blocks 
                margin-top 50px
            
            .rocket 
                width 80%
                svg 
                    height auto
                    transform: translateX(-140%);
        

        

        .useCases 
            min-height 0
            padding 40px 0 80px
            .content 
                flex-direction: column
                margin-top 60px
            .items 
                display flex
                justify-content space-between
                align-items center
                max-width: 300px
                margin 0 auto 40px
                &-subtitle 
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                    text-align: center;
                    color: #FFFFFF;
                    display block
                    font-weight 500
                    margin-bottom 30px
            .item 
                width calc(33.333% - 2.5px)
                display flex
                justify-content center
                align-items center
                padding 24px 20px
                margin-bottom 0
                &-title 
                    display none
                &-icon 
                    margin-right 0
            .block 
                &-buttons 
                    flex-direction: column
                    align-items flex-start
                &-button 
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom 30px 
                    &:last-child 
                        margin-bottom 0
            .el 
                display none
    
    @media (max-width: 480px)
        .products
            .blocks 
                flex-direction column
            .block 
                &-1
                    width calc(100% - 20px)
                    margin-right auto 
                    img
                        max-width: 100%
                &-2 
                    margin-top -20px
                    width calc(100% - 20px)
                    margin-left auto 
                    img
                        max-width: 100%

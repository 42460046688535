.modal
  display none
  position fixed 
  width 100vw
  height 100vh
  top 0 
  left 0
  z-index 100
  background rgba(#000000, .5)
  padding 10px 
  overflow-y auto
  overflow-x hidden
  &.active
    display flex
  &-block 
    max-width: 600px
    width 100%
    margin auto
    position relative
  &-close 
    cursor pointer
    position absolute 
    top: -0
    right -34px
  
  @media (max-width : 768px)
    .modal
      &-close 
        right 0
        top -34px

.modalvideo 
  &.active
    display none
  .video
    border-radius: 40px
    overflow hidden
    video
      width: 100%;
      height: 100%;
      object-fit: cover;
  @media (max-width : 1100px)
    &.active 
      display flex
  @media (max-width : 768px)
    .video 
      border-radius: 25px



.fintechSection
  padding 90px 0 50px
  position relative
  .wrapper 
    z-index 2
  // &:after 
  //   content ""
  //   position absolute 
  //   width 100% 
  //   height 83% 
  //   top 50% 
  //   left 0
  //   transform translateY(-50%)
  //   background-image: url('../img/main/fintech/background.png')
  //   background-size: cover
  //   pointer-events: none
  //   max-height: 716px
  .title 
    text-align center
    position relative
    z-index 10
    br 
      display none
  .content  
    display flex
    justify-content space-between
    position relative
    margin-top 19px
  .blocks 
    width 100%
  .blocks.left
    position relative
    max-width: 215px
    .block 
      &-wrapper
        -webkit-clip-path: url(#border-1);
        clip-path: url(#border-1);
        overflow: hidden;
      &-content  
        justify-content flex-start
        -webkit-clip-path: url(#border-1);
        clip-path: url(#border-1);
        overflow: hidden;
      &-icon 
        margin-right 14px
      &-title 
        text-align left
  .blocks.right
    max-width: 214px
    position relative
    .right-lines 
      position relative
      position absolute
      top: 32px;
      right: calc(100% - 41px);
      pointer-events: none
    .right-circles 
      position absolute
      top: 32px;
      right: calc(100% - 45px);
      z-index 3
      pointer-events: none
    .block 
      &-wrapper
        -webkit-clip-path: url(#border-2);
        clip-path: url(#border-2);
        background-size: cover;
        overflow: hidden;
        border-radius: 0 20px 20px 0
      &-content 
        justify-content flex-end
        background-size: cover;
        -webkit-clip-path: url(#border-2);
        clip-path: url(#border-2);
        overflow: hidden;
        border-radius: 0 20px 20px 0
        padding 12px 20px 12px 14px
      &-icon 
        margin-left 15px 
      &-title 
        text-align right
  .block 
    position relative
    z-index 2
    &-wrapper
      background #F9D4A0;
      padding 1px
      margin-bottom 10px
    &-content 
      display flex
      align-items center 
      width 100%
      padding 12px 10px 12px 20px 
      min-height: 64px
      height 64px
      background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
      display flex
    &-icon
      min-width: 40px 
    &-title 
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.5px;
      color: $white;
    &-lines 
      position absolute 
      &-1 
        top: 28px
        left calc(100% - 39px)
        width 148px
      &-2 
        top: 306px
        left: calc(100% - 45px);
        width 148px
    &-line
      position absolute 
      &__opacity
        position absolute 
        z-index 2
        &-1
          top: 3%;
          left: calc(100% - 53px);
        &-2
          top: 47.5%;
          left: calc(100% - 50px);
          g 
            opacity 0
            &.show 
              opacity 1
    &-round 
      z-index 2
      position absolute
      left: calc(100% + 90px);
      top: 23.2%;
      &__content
        width 126px
        height 126px
        border-radius: 50%
        background-image url(../img/main/fintech/rounds.svg)
        background-size: cover
        display flex
        flex-direction: column
        align-items center
        overflow hidden 
        &.active 
          .center-block__line
            animation-name: centerBlockLine;
        img 
          position relative
          z-index 2
      &__title 
        position relative
        min-height: 18px
        margin-top 5px
        display flex
        flex-direction: column
        span 
          position absolute 
          left 50%
          transform: translateX(-50%)
          opacity 0
          transition all .5s ease-in
          font-weight: 500;
          font-size: 10px;
          line-height: 18px;
          text-align: center;
          letter-spacing: -0.3125px;
          color: rgba(#F9D4A0, .3);
          &.title-1
            font-size: 18px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.5625px;
            color: $white;
            top 0
            opacity 1
          &.title-2
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.4375px;
            color: rgba(#F9D4A0, .5);
            top 18px
            opacity 1
          &.title-3
            top 36px
            opacity 1
          &.title-4
            top 54px
          &.title-5
            top 54px
          &.title-6
            top -18px
      &__lines 
        position absolute 
        right: calc(100% - 9px);
        top: 52px;
        #round-el-1
          opacity 0
          transition: all .3s ease-in
          transform: translate(-7px, -8px);
        &.active 
          #round-el-1
            animation-duration: 2s;
            animation-name: roundElement;
  // .card 
  //   display none
  //   &.active
  //     display block
    
          

  .center
    max-width: 500px
    width 100%
    margin 0 auto
    position relative
    z-index 2
    &-block 
      max-width: 252px
      width 100%
      margin 148px auto 0
      position relative
      &.active 
        .center-block__line
          animation-name: centerBlockLine;
      &__content 
        background: linear-gradient(180deg, #F9D4A0 0%, #99746E 100%);
        backdrop-filter: blur(73.3936px);
        border-radius: 60px;
        box-shadow 0px 42px 70px rgba(3, 18, 54, 0.534204)
        padding 35px 20px 49px
        width 100%
        z-index 2
      &__logo 
        max-width: 74px
        width 100%
        margin 0 auto 19px
      &__title 
        font-weight: 500;
        font-size: 22px;
        line-height: 22px;
        text-align: center;
        letter-spacing: -0.5px;
        color: $white;
      &__line 
        position absolute
        border: 0.5px solid rgba(#F9D4A0, .3);
        border-radius: 60px;
        width 100% 
        height 100%
        top: 0
        left 0
        animation-duration: 1s;
        &2
          animation-delay: .2s;
        &3
          animation-delay: .4s;
        &4
          animation-delay: .6s;
        &5
          animation-delay: 1.2s;
      &__lines
        position: absolute
        bottom: calc(100% - 14px);
        left: 50%; 
        transform: translateX(-50%)
        z-index 2
        #center-el-1
          opacity 0
          transition: all .3s ease-in
          transform: translate(-8.5px, -7px)
        &.active 
          #center-el-1
            animation-duration: 1.2s;
            animation-name: roundElement;
        #center-el-2
          opacity 0
          transition: all .3s ease-in
          transform: translate(8.5px, 7px) rotate(180deg)
        &.active2 
          #center-el-2
            animation-duration: 1.2s;
            animation-name: roundElement;
      &__round
        position absolute
        right -6px
        top calc(50% - 8px)
        width 15px
        height 15px 
        background #EC440F
        border 4px solid #F9D4A0
        border-radius: 50%
        &:after 
          content ""
          position absolute 
          top calc(50% - 10px)
          left calc(50% - 10px)
          border-radius: 50%
          width 20px
          height 20px
          border: 0.5px solid transparent
          transition all .25s ease-in
        &.active 
          &:after 
            border-color #F9D4A0;
      &__right 
        position absolute
        left 100% 
        top -121px
        .right-el
          transform: translate(-6px, -8px);
        g 
          opacity 0
        g.active
          animation-duration: 2s;
          animation-name: rightEl;
    &-items 
      display flex
      flex-wrap wrap 
      justify-content center
      &__block 
        padding 34px 15px 0
    &-item 
      margin 0 3.5px -1px
      max-width: 86px
      width 100%
      position relative
      &:first-child 
        .center-item__block
          &:after 
            display none
      &.active 
        .center-item__content
          border-color rgba(#F9D4A0, .4);
      &__content 
        width 86px
        height 86px
        padding 6px
        border-radius: 50%
        border: 1px solid transparent
        transition all .3s ease-in
        position relative
      &__block 
        background: linear-gradient(174.38deg, #2E44AA 4.48%, #020E47 116.06%);
        border-radius: 50%
        width 100%
        height 100% 
        display flex
        justify-content center
        align-items center
        position relative
        &:after 
          content ""
          position absolute
          top calc(50% - 3.5px)
          right 95%
          width 27px
          height 7px
          background-image url(../img/main/fintech/center/link_gor.svg)
        &--leftLine
          position absolute
          right: 94%;
          top: 44px;
          z-index 2
          &.show
            #centerLeft_element
              opacity 1
        &--rightLine
          position absolute
          left: 83%;
          top: 48px;
          z-index 2
          .opacity 
            transform: translateX(10px)
          &.show
            #centerRight_element
              opacity 1
      &__title 
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #F9D4A0;
        margin-top 3px

.rightItems-line
  position absolute 
  bottom: -34px;
  left: 30px;
  .right-el
    transform: translate(-10px, -10px)
  g 
    opacity 0
  g.active
    animation-duration: 2s;
    animation-name: rightEl;


#lines-el-6
  transform: translate(-9px, -14px);
#lines-el-7
  transform: translate(-19px, -6px)
  position relative
  z-index 2
#lines-el-9
  transform: translate(-19px, -6px)
  position relative
  z-index 2
#lines-el-10
  transform: translate(-19px, -13px)
  position relative
  z-index 2
#lines-el-8
  // transform: translate(-5px, -15px);
  transform: translate(-12px, -9px);
#centerLeft_element
  opacity: 0
  transform: translate(-9px, -12px)
  transition all .3s ease-in
#centerRight_element
  opacity: 0
  transform: translate(9px, 9px) rotate(-180deg)
  transition: all .3s ease-in;



@keyframes linesEl {
  0% {
    transform: scale(1) translate(-1px, -6px);
  }
  80% {
    transform: scale(1) translate(-1px, -6px);
  }
  100% {
    transform: scale(.6) translate(-1px, -6px);
  }
}
@keyframes linesEl2 {
  0% {
    transform: scale(1) translate(-1px, -18px);
  }
  80% {
    transform: scale(1) translate(-1px, -18px);
  }
  100% {
    transform: scale(.6) translate(-1px, -18px);
  }
}
   
@keyframes linesEl3 {
  0% {
    transform: scale(1) translate(-1px, -30px);
  }
  80% {
    transform: scale(1) translate(-1px, -30px);
  }
  100% {
    transform: scale(.6) translate(-1px, -30px);
  }
}
   
@keyframes centerBlockLine {
  0% {
    transform: scale(0);
    opacity 1
  }
  60% {
    opacity 1
  }
  100% {
    transform: scale(1.5)
    opacity 0
  }
}
   
   
@keyframes roundBlockTitleNoActive {
  0% {
    opacity: 1
    transform: translate(-50%, 0%) 
  }
  100% {
    opacity: 0
    transform: translate(-50%, 200%) 
  }
}

@keyframes roundElement {
  0% {
    opacity: 1
  }
  90% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

@keyframes rightEl {
  0% {
    opacity: 0
    transform: translate(-10px, -10px) scale(.4)
  }
  30% {
    opacity: 1
  }
  100% {
    transform: translate(-10px, -10px) scale(1)
    opacity: 1
  }
}

.center-adaptive__left
  display none

.center-adaptive__right
  display none

.center-adaptive__center
  display none

.preview
  max-width: 332px
  width 100%
  position relative
  border-radius: 34px
  overflow hidden
  margin 0 auto
  cursor pointer
  &-btn 
    position absolute
    top 50% 
    left 50%
    transform: translate(-50%, -50%)
    display flex
    flex-direction: column
    align-items center
    cursor pointer
    &:hover 
      .preview-icon 
        transform: scare(.7s)
  &-icon 
    display flex
    justify-content center
    align-items center 
    margin-bottom 5px
    transition: all .3s ease-in
  &-text 
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.5px;
    color: #F9D4A0;



.fintechSection
  @media (max-width : 1270px)
    .content 
      top 0
      max-width: 1070px
      margin 0 auto
    .blocks 
      &.left
        max-width: 170px
        .block-content 
          padding 5px 14px
      &.right
        max-width: 169px
        .block-content 
          padding 5px 14px 5px 14px
        .right-lines
          top: 25px;
          right: calc(100% - 50px);
        .right-circles 
          right: calc(100% - 41px);
          top 28px 
          svg 
            height 536px
    .block 
      &-title 
        font-size 14px
        line-height 18px
      &-icon 
        min-width 25px
        max-width: 25px
      &-lines-1
        left: calc(100% - 65px);
      &-lines-2
        left: calc(100% - 70px);
        top: 291px;
      &-line 
        &__opacity-1
          left: calc(100% - 76px);
        &__opacity-2
          top: 46.5%;
          left: calc(100% - 82px);
      &-round 
        left: calc(100% + 77px);
    .center 
      &-block 
        max-width: 215px
        &__content 
          padding 25px 20px 35px
          border-radius: 30px
        &__round 
          top: calc(50% + 4px);
        &__right 
          top -125px
    .rightItems-line 
      width 150px
      overflow hidden
  
  #right-el7
    transform translate(-10px, 15px) 

  @media (max-width : 1100px)
    padding: 83px 0 0
    .content
      margin-top: -78px;
    .blocks 
      display none
    .block-round__lines
      display none
    .center-items__block
      display none
    .center-block__lines
      display none
    .center-block__round
      display none
    
    .center
      width 100%
      max-width: none
      &-container
        display flex
        justify-content space-between
        align-items center
      &-block
        margin 0 auto
        max-width: 332px
        width 100%
        flex-grow: 1
        &__content
          border-radius: 60px;
          padding 76px 30px 82px
        &__logo 
          margin-bottom 25px
        .center-block__line
          animation-name: centerBlockLine2;
          animation-iteration-count: infinite;
    .wrapper 
      padding 0
    .center-adaptive__left
      width 100% 
      overflow hidden
      display flex
      justify-content flex-end
      img 
        width 100%
        min-width: 333px
    .center-adaptive__right
      width 100%
      overflow hidden
      display flex
      justify-content flex-start
     
      img 
        width 100%
        min-width: 333px
    .center-adaptive__center
      display block
      margin-top: -93px;
    
    .title 
      padding 0 30px
  
  @media (max-width : 768px)
    padding 46px 0
    .content 
      margin-top: -75px;

    .title 
      font-size: 35px;
      line-height: 35px;
      br
        display block
    .center-block 
      max-width: 252px
      min-width: 252px
    .preview
      max-width: 252px
      border-radius: 18px
    .center-adaptive 
      &__left 
        img
          position relative
          top: -51px;
          right: -22px;
      &__center 

        margin-top -83px
      &__right
        img
          position relative
          top: -51px;
          left: -22px;

    
  
@keyframes centerBlockLine2 {
  0% {
    transform: scale(0);
    opacity 1
  }
  60% {
    opacity 1
  }
  100% {
    transform: scale(1.3)
    opacity 0
  }
}
  
  
        


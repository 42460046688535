.useCasePage
  overflow hidden
  position relative
  background #050b29
  // height 100%

  &:after 
    content ""
    position absolute
    bottom 0
    left 0
    width 100%
    height 100%
    background-image url(../img/integrate/back.png)
    background-color: #050B29
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-size: cover
    background-position-y: top
    transition all .3s ease-in
    
  main 
    position relative
    z-index 2
    // height 100%


  .title 
    font-size: 55px;
    text-align center
    line-height: 65px;
  

  .useCase
    height 100vh
    &-swiper 
      width 100% 
      height 100%
   
    .subtitle 
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      color: #F9D4A0;
      margin-top 20px
    
    .blocks 
      margin-top 100px
    
    .swiper-slide 
      pointer-events: none
      .wrapper 
        opacity 0 !important
        transition: all 1s linear 
        height 100%
      &.variant-1 
        .block-withoutWhite 
          opacity 0
          transition: all 1s linear 

      &.swiper-slide-prev
        .wrapper 
          transform: translateY(-100%)
          opacity 0 !important
        &.variant-1
          .wrapper 
            transform: translateY(0%)
            opacity 1 !important
          .block-info 
            max-width: 640px 
        &.variant-2
          .block-info 
            max-width: 640px 
          
      &.swiper-slide-next
        .wrapper 
          transform: translateY(100%)
          opacity 0 !important
        &.variant-2
          .wrapper 
            transform: translateY(0%)
      &.swiper-slide-active 
        pointer-events: all
        .wrapper 
          transform: translateY(0%)
          opacity 1 !important
        &.variant-1 
          .block-withoutWhite 
            opacity 1
        &.variant-2
          .block-info 
            max-width: 640px 

      &.go 
        .wrapper 
          transform: translateY(-100%)
          opacity 0 !important
        .block-info 
          max-width: 640px 

    &.withTitle
      .block 
        .title,
        .subtitle 
          display block

    .block 
      .wrapper 
        padding-top 17vh
        padding-top 20vh
        max-width: 1230px;
        display flex
        flex-direction: column
      .title,
      .subtitle 
        display none
      &-mobile 
        .wrapper
          display flex
          justify-content center
          align-items center 
          flex-direction: column
          
      &-title 
        font-weight: 400;
        font-size: 35px;
        line-height: 35px;
        color: #FFFFFF;
        margin-bottom 39px
        &.mobile 
          display none
      &-content 
        display flex
        justify-content space-between
        max-width: 1080px
        width 100%
        padding-bottom 30px
        margin-top 10vh
      &-info 
        max-width: 560px
        width 100%
        transition: all .5s ease-in
      &-img 
        width 300px
        min-width: 300px
        height 300px
      &-text 
        width 100%
      &-withoutWhite
        max-width: 560px
        width 100%
      &.right 
        margin-right auto
        .block-info 
          margin-right 20px
      &.left  
        margin-left auto
        .block-info 
          margin-left 20px
      &-white 
        background: #FFFFFF;
        border-radius: 20px;
        padding 40px 40px 50px
        display flex
        justify-content flex-start
        margin-top 44px
        &__icon 
          max-width: 84px 
          width 100%
          margin-right 40px
          min-width: 84px
        &__title 
          font-size: 20px;
          line-height: 34px;
          color: #D39F56; 
          margin-top: 9px;
          margin-bottom 19px
        &__subtitle 
          font-weight 400
          font-size: 30px;
          line-height: 30px;
          color: #050925;
          margin-bottom 21px
        &__text 
          font-size: 14px;
          line-height: 28px;
          color: rgba(#050925, .7)
        &__info 
          max-width: 396px
          width 100%
        &__slide 
          flex-shrink: 0;
          width: 100%;
          height: 100%;
          position: relative;
          transition-property: transform;
        .swiper-pagination
          position static
          display flex
          margin-top 31px
        .swiper
          margin-left: auto;
          margin-right: auto;
          position: relative;
          overflow: hidden;
          padding: 0;
          z-index: 1;
        .swiper-wrapper 
          z-index: 1;
          display: flex;
          box-sizing: content-box;

    .variant-1 
      .block
        &-text 
          font-weight: 400;
          font-size: 14px;
          line-height: 28px;
          color: #F9D4A1;
        &-list 
          max-width: 418px 
          width 100%
          column-count: 2;
          column-gap: 5%; 
          margin-top 40px
        &-item 
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: -0.5px;
          color: #FFFFFF
          margin-bottom 30px 
          padding-left 28px 
          position relative
          transition: all .3s ease-in
          &:after 
            content ""
            position absolute 
            left 0
            top 0
            width 18px
            height 18px
            background-image url(../img/integrate/check.svg)
            transition: all .3s ease-in
        &-img
          background: linear-gradient(0.56deg, #1B1317 -26.8%, #283F81 97.42%);
          border-radius: 50%
          display flex
          justify-content center
          align-items center
          position relative
          &:after 
            content ""
            position absolute 
            width 54%
            height 54%
            border-radius: 50%
            background: linear-gradient(0deg, #1B1317 -6.06%, #071848 109.73%);
            mix-blend-mode: normal;
            opacity: 0.2;
          img 
            position relative
            z-index 2
    .variant-2 
      .block-title 
        opacity 0
      
          
  .main
    padding 0px 0 68px
   
    .wrapper 
      max-width: 1165px;
      padding-top 20vh
    &-content 
      display flex
      justify-content space-between 
      padding-right 20px 
    &-image 
      width 49% 
      position relative
      &__block 
        position absolute
        width: 130.7%;
        right: -3%;
        top: 0%;
       
        img 
          width 100%
    &-info 
      max-width: 550px
      width 59%
    &-title 
      color: $white
      text-align left
      margin 0
    &-subtitle 
      font-weight 400
      font-size: 26px;
      line-height: 34px;
      color: #F9D4A1;
      margin-top 35px
  
  .swiper-pagination-bullet
    border: 1px solid rgba(#050925, .3)
    background: none
    opacity 1
    transition: all .3s ease-in
    width 5px
    height 5px
    margin-right 15px !important
    margin-left 0 !important
    border-radius 50%
    &:hover 
      border-color #050925
  .swiper-pagination-bullet-active
    background #050925

  
  @media (max-height : 800px) 
    .useCase 
      .block
       &-content 
         margin-top 50px

  @media (max-height : 760px) and (min-width: 999px)

    .main 
      .wrapper
        padding-top: 18.5vh
        display flex
        align-items center
      &-content 
        width 100%
      &-image 
        &__block 
          top 70% 
          transform: translateY(-50%)
    
    .useCase 
      .title  
        font-size 40px
        line-height 45px
      .subtitle 
        margin-top 10px
      .block 
        &-content 
          margin-top 50px
        &-withoutWhite
          max-width: 700px
        .wrapper
          padding-top: 18.5vh
        &-title 
          font-size 25px
          line-height 25px
        &-img 
          width 200px
          min-width: 200px
          height 200px
          img 
            width 30%
        &-content 
          margin-top 30px 
        &-info 
          max-width: 700px
        &-title 
          margin-bottom 20px
        &-white 
          margin-top 20px
          padding 15px
          &__icon 
            margin 20px 20px 0 5px
            min-width: 60px
            max-width: 60px
          &__info 
            max-width: calc(100% - 104px)
          &__title 
            font-size 16px
            line-height 25px
            margin-bottom 10px
          &__subtitle 
            font-size 20px
            line-height 25px
      .swiper-slide.swiper-slide-prev.variant-2 .block-info
        max-width: 780px
      .swiper-slide.swiper-slide-prev.variant-1 
        .block-info
          max-width: 780px
      .swiper-slide.swiper-slide-active.variant-2 
        .block-info
          max-width: 780px
      .swiper-slide.go 
        .block-info 
          max-width: 780px 
  
  @media (max-height : 800px) and (max-width: 1100px)
    .main .wrapper
      padding-top: 18.5vh
    .useCase 
      .subtitle 
        margin-top 10px
      .block 
        &-content 
          margin-top 50px
          max-width: 900px
        &-img 
          width 200px
          min-width: 200px
          height 200px
          img 
            width 30%
        .wrapper
          padding-top: 18.5vh
  
  @media (max-height : 700px) and (max-width: 1100px)
    .useCase 
      .title  
        font-size 40px
        line-height 45px
      .subtitle 
        margin-top 10px
      
      .block 
        &-img
          transition: all .5s ease-in
        &-white 
          margin-top 20px
          padding 15px
          &__icon 
            margin 20px 20px 0 5px
            min-width: 60px
            max-width: 60px
          &__info 
            max-width: calc(100% - 104px)
          &__title 
            font-size 16px
            line-height 25px
            margin-bottom 10px
          &__subtitle 
            font-size 20px
            line-height 25px
        // &.right 
        //   .block-info 
        //     margin-right auto 
            
        // &.left 
        //   .block-info 
        //     margin-left auto 


      .swiper-slide.swiper-slide-prev.variant-1, 
      .swiper-slide.swiper-slide-prev.variant-2,
      .swiper-slide.swiper-slide-active.variant-2,
      .swiper-slide.go 
        .block-info
          max-width: 760px
        .block-img
          opacity 0
        &.right 
          .block-img
            margin-right -200px
        &.left 
          .block-img
            margin-left -200px


  @media (max-width : 1100px)
    .main
      &-image
        max-width: 600px
        width 100%
        margin 0 auto
        &__block
          width 100%
          right 0
          top 50% 
          transform: translateY(-50%)

  @media (max-width : 998px)
    .useCase 
      .main
        &-content
          flex-direction: column
          padding-right 0
        &-image 
          display flex
          justify-content center
          margin 30px auto 0
          order 2
          max-height: 40vh
          .main-image__block
            height 100%
            width auto
          &__block 
            position static
            transform: none
            display flex
            justify-content center
      .title  
        font-size 40px
        line-height 45px
      .subtitle 
        margin-top 10px
      
      .block 
        &-img
          transition: all .5s ease-in
          min-width 200px
          width 200px
          height 200px
          img 
            width 30%
        &-content 
          max-width: 900px 
        &-white 
          margin-top 20px
          padding 15px
          &__icon 
            margin 20px 20px 0 5px
            min-width: 60px
            max-width: 60px
          &__info 
            max-width: calc(100% - 104px)
          &__title 
            font-size 16px
            line-height 25px
            margin-bottom 10px
          &__subtitle 
            font-size 20px
            line-height 25px
       
     


  @media (max-width : 998px) and (min-width: 769px)
    .useCase
      .swiper-slide.swiper-slide-prev.variant-1, 
      .swiper-slide.swiper-slide-prev.variant-2,
      .swiper-slide.swiper-slide-active.variant-2,
      .swiper-slide.go 
        .block-info
          max-width: 760px
        .block-img
          opacity 0
        &.right 
          .block-img
            margin-right -200px
        &.left 
          .block-img
            margin-left -200px
      
      .block
        &.right 
          &.variant-1 
            .block-info
              padding-right 20px

            
        &.left 
          &.variant-1 
            .block-info
              padding-left 20px
        
      .swiper-slide.swiper-slide-prev.variant-1
        &.left
          .block-info
            padding-left 0
        &.right 
          .block-info
            padding-right 0

   

  @media (max-width : 768px) and (min-height: 601px)
    .useCase 
      .block 
        .wrapper 
          padding-top 100px
          text-align center
        &-content 
          flex-direction column
          max-width: 560px 
          width 100% 
          margin 0px auto 0
        &-info 
          max-width: none
          order 2
        &-img 
          order 1 
          margin 0 auto
        &-title 
          display none 
          font-size 25px
          line-height 30px
          max-width: 560px 
          width 100% 
          margin 0px auto 20px
          &.mobile 
            display block
        &-withoutWhite
          margin 20px auto 0
        &.variant-1 
          .block-list 
            margin 20px auto 0
        &.variant-2 
          .block-img 
            display none
        &.left 
          .block-info 
            padding-left 0
            margin-left auto 

        &.right 
          .block-info 
            padding-right 0
            margin-right auto 

        &-white 
          flex-direction: column 
          padding 25px
          margin-top 0
          &__icon 
            margin 0 auto
          &__info 
            max-width: 100%
          .swiper-pagination
            display flex
            justify-content center
            align-items center
  
      .swiper-slide.swiper-slide-prev.variant-1,
      .swiper-slide.go 
        .block-img 
          opacity 0
      

      .swiper-slide.swiper-slide-prev.variant-1, 
      .swiper-slide.swiper-slide-prev.variant-2,
      .swiper-slide.swiper-slide-active.variant-2,
      .swiper-slide.go 
        &.right 
          .block-img
            margin-right auto
        &.left 
          .block-img
            margin-left auto
    
      
      
      


  @media (max-width : 768px) and (min-height : 701px)
    .main
      padding-bottom 20px
      &-info
        width 100%
        max-width: 100%

  @media (max-height : 700px)
    .main
      .wrapper 
        display flex
      &-info
        width 100%
        max-width: 600px
      &-content 
        width 100%
        align-items center
      &-image
        max-height: 60vh;
        &__block 
          transform: translateY(-44%);
       

  @media (max-width : 998px)
    .main-info 
      max-width: 700px

  @media (max-width : 768px)
    &:after
      width 200%
    .block 
      .title, 
      .subtitle 
        display none
      &-content 
        transition: all 1s ease-in
      &.variant-2 
        .block-img 
          display none
        &.left
          .block-info 
            margin-left 0
   
    

    .useCase .swiper-slide.swiper-slide-prev.variant-1 
      .block-content
        transform: translateX(-100%)

    .useCase .swiper-slide.swiper-slide-next.variant-2 
      .block-content
        transform: translateX(100%)
  
    .main 
      .wrapper 
        padding-top 100px
    
    .useCase .swiper-slide.swiper-slide-prev.variant-1.right .block-img, 
    .useCase .swiper-slide.swiper-slide-prev.variant-2.right .block-img, 
    .useCase .swiper-slide.swiper-slide-active.variant-2.right .block-img,
    .useCase .swiper-slide.go.right .block-img
      opacity 0
    
    .useCase
      .swiper-slide.swiper-slide-prev.variant-1, 
      .swiper-slide.swiper-slide-prev.variant-2,
      .swiper-slide.swiper-slide-active.variant-2,
      .swiper-slide.go 
        &.right 
          .block-img
            margin-right auto
        &.left 
          .block-img
            margin-left auto



  @media (max-width : 480px)
    .useCase 
      .variant-1
        .block-list 
          column-count: 1;
          column-gap: 0%;
        
  
  @media (max-width : 480px) and (min-height: 601px)
    .useCase 
      .variant-1
        .block-list 
          display flex
          flex-direction: column
        .block-item 
          display inline
          margin 0 auto 20px
          padding-right 20px


      
  @media (max-height : 768px) and (max-width: 768px)

    .block.variant-1
      .title,
      .subtitle 
        display none

    .useCase 
      .block 
        &-img 
          min-width: 150px
          width 150px
          height 150px
      .variant-1 
        .block-list
          margin-top 20px
        .block-item 
          margin-bottom 20px
    
      .title 
        font-size 30px
        line-height 35px
      
      .main-subtitle 
        font-size 20px
        line-height 25px
    


  

   @media (max-height : 600px)
   
    .main 
      &-content 
        padding-right 0
      .wrapper 
        padding-top: 100px;
      &-image__block
        max-height: 66vh;
        display flex
        justify-content center

    .useCase 
      .title
        font-size 30px
        line-height 35px
      .main-subtitle 
        margin-top 20px
        font-size 20px
        line-height 25px
    
      .block 
        .wrapper 
          padding-top 100px
        
        &-white 
          margin-top 0
          padding 25px
        &-content 

          margin-top 0
        

        
  @media (max-width: 768px) and (min-height: 501px)
    .useCase
      .block
        &-title 
          display none 
          font-size 20px
          line-height 25px
          margin-bottom 20px
          &.mobile 
            display block
            width 100%

  @media (max-height: 500px)
    .useCase
      .block 
        &-title 
          display none 
          font-size 20px
          line-height 25px
          margin-bottom 10px
          &.mobile 
            display block
            width 100%
        .title 
          display none
        .subtitle 
          display none
        .wrapper 
          padding-top 100px
        &-content 
          max-width: none
          width 100% 
          margin 0px auto 0
        &-info 
          max-width: 100%
          width 100%
        &-withoutWhite
          margin 10px auto 0
          max-width: none
        &.variant-2 
          .block-img 
            display none
            opacity 0 !important
        &-white 
          &__icon 
            margin 0 10px 0 -10px
          &__info 
            max-width: calc(100% - 60px)
          &__title 
            margin-top 0 
          &__text 
            font-size 12px
            line-height 20px
          &__subtitle 
            margin-bottom 10px
            font-size 18px
            line-height 22px
          .swiper-pagination
            margin-top 15px
      
        &.right 
          &.variant-1 
            .block-info
              padding-right 20px

            
        &.left 
          &.variant-1 
            .block-info
              padding-left 20px
        
      .swiper-slide.swiper-slide-prev.variant-1
        &.left
          .block-info
            padding-left 0
        &.right 
          .block-info
            padding-right 0
      


    .swiper-slide.swiper-slide-prev.variant-1 .block-info, 
    .swiper-slide.swiper-slide-prev.variant-2 .block-info,
    .swiper-slide.swiper-slide-active.variant-2 .block-info,
    .swiper-slide.go .block-info
      max-width: 100%
      width 100%


    .swiper-slide.swiper-slide-prev.variant-1,
    .swiper-slide.go 
      .block-img 
        opacity 0
    

    .swiper-slide.swiper-slide-prev.variant-2,
    .swiper-slide.swiper-slide-active.variant-2,
      &.right 
        .block-img
          margin-right 0
      &.left 
        .block-img
          margin-left 0


 @media (max-height: 400px)

    .useCase 
      .block 
        &-img 
          width 150px
          min-width: 150px
          height 150px

    
.cardServicePage
    overflow hidden
    position relative
    background #050b29
    &:after 
        content ""
        position absolute
        bottom 0
        top 0
        width 100%
        min-height: 5000px
        background-image url(../img/integrate/back.png)
        background-color: #050B29
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-size: cover
        background-position-y: top
        transition all .3s ease-in

    main 
        position relative
        z-index 2

    .title 
        font-size: 55px;
        text-align center
        line-height: 65px;



    .cardService
        padding 195px 0 68px
        .wrapper 
            max-width: 1150px
        .content 
            display flex
            justify-content space-between
        
        .title 
            max-width: 567px
            width 59%
            text-align left
        
        .image 
            width 49% 
            max-width: 516px
            position relative
            &-block 
                position absolute 
                top: -3%;
                right: -8%;
        .items 
            display flex
            flex-wrap: wrap 
            justify-content space-between
            position relative
            z-index 2
            margin 186px 0px 0
        .item 
            width calc(50% - 80px)
            margin 0 40px 62px
            text-align center
            &-icon 
                display flex
                justify-content center
                align-items center
                margin-bottom 23px
            &-title 
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color $white
                margin-bottom 20px
            &-text 
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #F9D4A0;
                max-width: 480px 
                width 100% 
                margin 0 auto
    .dashboard
        padding 31px 0
        .blocks 
            max-width: 1035px 
            width 100% 
            margin 0 auto
            position relative
            padding-top: 8.2%;
            padding-bottom 17%
        .block 
            overflow hidden
            &-1
                max-width: 565px
                width 55%
                margin 0 auto
                box-shadow: 57px 77px 88px rgba(5, 5, 13, 0.370758);
                border-radius: 25px;
            &-2
                max-width: 208px
                width: 21%;
                top: 11%;
                left: 12.5%;
                position absolute
                box-shadow: 12px 18px 40px rgba(43, 8, 60, 0.617488);
                border-radius: 20px
            &-3
                max-width: 208px
                width: 21%;
                top: 46.2%;
                left: 64.9%;
                position absolute
                box-shadow: 12px 18px 40px rgba(43, 8, 60, 0.617488);
                border-radius: 20px;
            &-4
                max-width: 234px
                width: 23%;
                top: 2.2%;
                left: 0;
                position absolute
                box-shadow: 0px 18px 40px rgba(49, 18, 64, 0.958277);
                border-radius: 10.4px;
            &-5
                max-width: 234px
                width 23%
                top: 3.5%;
                left: 67.3%;
                position absolute
                box-shadow: 20px 18px 40px rgba(49, 18, 64, 0.681982)
                border-radius: 10.4px;
            &-6
                max-width: 234px
                width 23%
                position absolute
                top: 0;
                left: 65.7%;
                box-shadow: 20px 18px 40px rgba(49, 18, 64, 0.958277);
                border-radius: 10.4px;
            &-7
                position absolute
                top: 26.2%;
                left: -10%;
            &-8
                position absolute
                left: 42.3%;
                top: 75.6%;
            &-9
                position absolute
                right: 0%;
                top: 27.5%;
            &-text 
                background: linear-gradient(2.49deg, #56424D 23.49%, #4E6CC3 149.61%);
                border-radius: 25px;
                padding 30px
                max-width: 204px
                width 100%
                p 
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                    letter-spacing: -0.5px;
                    color: #F9D4A1;

    .solution
        padding 92px 0 
        .wrapper 
            max-width: 1070px
        .title 
            max-width: 716px
            width 100%
            margin  0 auto
        
        .blocks 
            display flex
            flex-wrap wrap 
            justify-content flex-start
            margin-right -40px
            margin-top 84px

        .block 
            width calc(33.333% - 40px)
            margin-right 40px
            background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
            border-radius: 30px;
            padding 35px 30px 40px
            margin-bottom 40px
            &-icon
                display flex
                justify-content center
                align-items center
                margin-bottom 15px 
            &-title 
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: #FFFFFF;
                margin-bottom 10px
                &.height 
                    margin-bottom 20px
            &-text 
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #F9D4A0;

    .discover
        padding 25px 0
        .title 
            max-width: 716px 
            width 100% 
            margin 0 auto
        .wrapper 
            max-width: 1070px
        .blocks
            display flex
            flex-wrap: wrap
            margin-right -40px 
            margin-top 84px
        .block 
            width calc(33.333% - 40px)
            margin-right 40px
            background: #FFFFFF;
            border-radius: 30px;    
            padding 30px 30px 40px     
            margin-bottom 40px
            &-icon 
                display flex
                justify-content center
                align-items center 
                margin-bottom 25px 
            &-title 
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                text-align: center;
                color: #D39F56; 
                margin-bottom 20px
            &-text 
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #050925;  
    
    .useCase 
        padding 14px 0
        min-height: 722px
        .title 
            text-align left
            margin-bottom 63px
            margin-top -4px
        .mobile 
            display none
        .el 
            position absolute 
            &1
                right: 8.5%;
                top: 85px;
            &2
                right: 42.5%;
                bottom: 73px;
            &3
                right: 18.4%;
                bottom: -28px;
        .content 
            display flex
            justify-content space-between
            margin-top 80px
            padding-right 140px
        .subtitle 
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            text-align: center;
            color: #F9D4A1;
            margin-top 26px
        .items 
            max-width: 300px
            width 100% 
            margin-right 60px
            &-subtitle 
                display none
        .item 
            border: 1px solid rgba(#F9D4A0, .4);
            border-radius: 20px;
            display flex
            justify-content flex-start
            align-items center
            padding 24px 25px
            margin-bottom 10px 
            cursor pointer
            transition: all .3s ease-in
            &:hover 
                border-color #F9D4A0
            &.active 
                background: linear-gradient(177.76deg, #2E44AA 1.88%, #020E47 109.92%);
                border: 1px solid #F9D4A0;
                pointer-events: none
            &:last-child 
                margin-bottom 0
            &-icon 
                margin-right 20px
                height 40px 
                display flex
                justify-content center
                align-items center
            &-title 
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                color: #FFFFFF;
        .blocks 
            max-width: 630px
            width 100%
            margin-right auto
        .block 
            display none
            &.active 
                display block
            &-title 
                font-weight 400
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: #FFFFFF;
                margin-bottom 25px
            &-list 
                margin-top 40px 
            &-item 
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                letter-spacing: -0.5px;
                color: #FFFFFF
                margin-bottom 25px 
                padding-left 30px 
                position relative
                &:after 
                    content ""
                    position absolute 
                    left 0
                    top 0
                    width 18px
                    height 18px
                    background-image url(../img/integrate/check.svg)
            &-text 
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                letter-spacing: -0.5px;
                color: #F0CC9B;

    @media(max-width: 1260px)
        .dashboard .block-7
            left 0
            top 35%

    @media(max-width: 998px)
        .dashboard .block-text
            transform: scale(.8)
    
        .cardService 
            .content 
                flex-direction: column
            .title 
                width 100%
            .image
                width 100% 
                max-width: 700px
                margin 40px auto 0
                &-block 
                    position relative
                    width 100%
                    margin-bottom -10%
                    right 0
                    top 0
        
        .solution 
            .blocks 
                margin-right -30px
            .block
                width calc(33.333% - 30px)
                margin-right 30px
                margin-bottom 30px
                padding 25px 20px 30px
        .discover  
            .blocks 
                margin-right -30px
            .block
                width calc(33.333% - 30px)
                margin-right 30px
                margin-bottom 30px
                padding 25px 20px 30px
        
        .useCase 
            .el 
                transform: scale(.7)
                &1 
                    top 60px
                    right 5%
                &2 
                    display none
            .content 
                padding-right 0
            .items 
                margin-right 30px
    
    @media (max-width: 768px)

        .title 
            font-size: 30px;
            line-height: 40px;
        .cardService 
            padding-top 100px
            .title
                max-width: 370px
            .image 
                max-width: 500px
        
            .items 
                margin 130px -10px 0
            .item 
                width calc(50% - 20px)
                margin 0 10px 40px
    
        .dashboard 
            .wrapper 
                padding 0 10px
            .blocks 
                max-width: 355px
                padding-top 226px
                padding-bottom 172px
             .block 
                &-1
                    max-width: 300px
                    width 87%
                    margin-right 5px
                    border-radius: 8px;
                &-2
                    max-width: 125px
                    width: 37%;
                    top: 16.7%;
                    left: 8px;
                    border-radius: 12px
                &-3
                    max-width: 125px
                    width: 37%;
                    top auto
                    bottom: 0;
                    left: 50.9%;
                    border-radius: 12px
                &-4
                    max-width: 150px
                    width: 44%;
                    top: 43.2%;
                    left: -5px;
                    border-radius: 6.6px
                &-5
                    max-width: 150px
                    width 44%
                    top: 27%;
                    left: 58.3%;
                    border-radius: 6.6px
                &-6
                    max-width: 150px
                    width 44%
                    top: 24.5%;
                    left: 55.3%;
                    border-radius: 6.6px
                &-7
                    top: 0;
                    left: 1.3%;
                &-8
                    left: 4.5%;
                    top: 74%;
                &-9
                    right: 1%;
                    top: 7.6%;
                &-text 
                    transform: scale(1)
                    max-width: 149px
                    padding 30px 17px 30px 25px
        
        .solution 
            padding 60px 0
            .block 
                width calc(50% - 30px)
        .discover 
            .block 
                width calc(50% - 30px)
    
        .useCase
            min-height 0
            padding 40px 0 80px
            .mobile 
                display block
            .desktop 
                display none
            .content 
                flex-direction: column
                margin-top 60px
            .title 
                text-align center
                margin-bottom 0
            .items 
                display flex
                justify-content space-between
                align-items center
                max-width: none
                margin 0 auto 40px
                max-width: 345px
                &-subtitle 
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 18px;
                    text-align: center;
                    color: #FFFFFF;
                    display block
                    font-weight 500
                    margin-bottom 30px
            .item 
                width calc(20% - 2.5px)
                max-width: 65px
                height 65px
                border-radius: 15px
                display flex
                justify-content center
                align-items center
                margin-bottom 0
                padding 10px
                &-title 
                    display none
                &-icon 
                    margin-right 0
            .block 
                &-buttons 
                    flex-direction: column
                    align-items flex-start
                &-button 
                    font-size: 16px;
                    line-height: 18px;
                    margin-bottom 30px 
                    &:last-child 
                        margin-bottom 0
            .el 
                display none
	

    @media (max-width: 480px)

        .cardService 
            .items 
                margin 130px 0px 0
            .item 
                width 100%
                margin 0 0px 40px
        
        .solution 
            .blocks 
                margin-top 50px
            .block 
                width calc(100% - 30px)
        .discover 
            .blocks 
                margin-top 50px
            .block 
                width calc(100% - 30px)
    
    
